export const getUrlParam = key => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};
export default function objectToURLParams(object) {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);

  return params.length > 0 ? `?${params.join('&')}` : '';
}

export const deepCopy = obj => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  let value;
  const outObject = Array.isArray(obj) ? [] : {};

  Object.keys(obj).forEach(key => {
    value = obj[key];
    outObject[key] = deepCopy(value);
  });

  return outObject;
};

export const round = (number, decimalPlaces = 1) => {
  const factorOfTen = 10 ** decimalPlaces;
  return Math.round(number * factorOfTen) / factorOfTen;
};

export const transformToDisplayNumber = number => {
  const parsedNumber = parseInt(number, 10);

  const numericValue = !Number.isNaN(parsedNumber) ? parsedNumber : 0;

  if (numericValue >= 1000000) return `${round(numericValue / 1000000)}M`;
  if (numericValue >= 1000) return `${round(numericValue / 1000)}K`;

  return numericValue.toString();
};

export function getInitials(string) {
  const names = string.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}

export const arrayMoveMutate = (array, from, to) => {
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;

    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }
};

export const arrayMove = (array, from, to) => {
  const newArray = [...array];
  arrayMoveMutate(newArray, from, to);
  return newArray;
};

export const capitalizeFirstLetter = ([first, ...rest], locale = navigator.language) => {
  return [first.toLocaleUpperCase(locale), ...rest].join('');
};

export const isGoogleImage = imageUrl => Boolean(imageUrl?.includes('.googleusercontent.com'));
