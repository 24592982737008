import React from 'react';
import { Redirect } from 'wouter';

const PrivacyPolicyScreen = React.lazy(() => import('./screens/privacy/privacyPolicy'));

const pathsBlacklist = ['/signout', '/auth/signin/'];

export default [
  {
    name: 'Supermajority - Privacy Policy',
    path: '/privacy-policy',
    component: PrivacyPolicyScreen
  },
  {
    path: '/:rest*',
    // fix for url params not being passed after redirect
    component: () => {
      const urlParams = new URLSearchParams(window.location.search);
      const path = window.location.pathname;

      if (path && path.length > 1 && !pathsBlacklist.includes(path) && !path.includes('auth')) {
        urlParams.set('redirectAfterLogin', path);
      }

      const urlParamsString = urlParams.toString();
      const queryString = urlParamsString.length > 1 ? `?${urlParamsString}` : '';

      return <Redirect to={`/auth/signin/${queryString}`} />;
    }
  }
];
