import React, { useState, useRef, useEffect, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { Slider } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDialogState } from 'reakit/Dialog';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import { Image } from 'react-feather';
import { useToasts, toastPosition } from 'modules/shared/components/toast';
import { theme } from 'modules/ui/theme';
import { Box, Flex, Text } from 'modules/ui/primitives';
import { Heading } from 'modules/ui/primitives/heading';
import { Button } from 'modules/ui/primitives/button';
import { Dialog, DialogTrigger } from 'modules/ui/components/dialog';
import { Label } from 'modules/ui/primitives/label';
import { useProfile } from 'modules/profile';
import ProfileImage from 'modules/profile/components/profileImage';
import * as api from '../api';

const StyledSlider = styled(Slider)`
  .ant-slider-rail,
  .ant-slider-rail:focus,
  .ant-slider-track,
  .ant-slider-track:focus {
    color: ${theme.colors.ui.line} !important;
    background-color: ${theme.colors.ui.line} !important;
  }
  .ant-slider-handle,
  .ant-slider-handle:focus {
    color: ${theme.colors.ui.line} !important;
    border: solid 2px ${theme.colors.action.pink} !important;
    box-shadow: none;
  }
`;

const DashedBox = styled(Flex)`
  justify-content: center;
  border: 2px dashed ${theme.colors.ui.line};
  box-sizing: border-box;
  border-radius: 8px;
`;

const IconWrap = styled(Flex)`
  padding: 10px;
  border-radius: 2px;
  background-color: ${theme.colors.base.grey200};
  position: relative;
  flex: none;
`;

export const ImageEditor = ({ onboarding }) => {
  const { t } = useTranslation();
  const dialog = useDialogState({ animated: true });
  const { showError } = useToasts();
  const [{ data: profileData, isLoading: profileLoading }, { submit }] = useProfile();

  const { firstName, lastName, user } = profileData;
  let { profileImageUrl } = profileData;

  const [{ image, scale, border, borderRadius, width, height }, setState] = useState({
    image: null,
    scale: 2.5,
    border: [95, 38],
    borderRadius: 72,
    width: 144,
    height: 144
  });

  const handleDrop = dropped => {
    setState(prevState => {
      return { ...prevState, image: dropped[0] };
    });
  };

  const handleScale = value => {
    const scaled = parseFloat(value / 10 / 2);
    setState(prevState => {
      return { ...prevState, scale: scaled };
    });
  };

  let editorInstance = null;
  const setEditorRef = editor => {
    if (editor) {
      editorInstance = editor;
    }
  };
  const isLoading = useRef(false);

  const getPhoto = useCallback(async () => {
    const response = await api.getPhoto(profileImageUrl);
    return response.text();
  }, [profileImageUrl]);

  useEffect(() => {
    if (!image && profileImageUrl) {
      getPhoto().then(resp =>
        setState(prevState => {
          return { ...prevState, image: resp };
        })
      );
    }
  }, [getPhoto, image, profileImageUrl]);

  const [generateUrl] = api.useGenerateUrl();

  const onSubmit = async () => {
    const photo = editorInstance.getImageScaledToCanvas().toDataURL();
    try {
      isLoading.current = true;
      // get upload location
      const { data: uploadLocation } = await generateUrl({
        assetClass: 'profileImage',
        assetPath: `users/${user?.id}/profileImages`,
        context: { user: { id: user?.id } },
        _type: 'asset-actions'
      });

      // upload image to AWS S3
      const uploadFileName = `${uploadLocation.downloadUrl}/${firstName}${lastName}_${Date.now()}`;
      const resp = await api.uploadPhoto(uploadFileName, photo);

      // update profile with picture url
      if (resp?.ok) {
        const submitData = {
          profileImageUrl: uploadFileName,
          user: { id: user?.id }
        };
        await submit(submitData);
      }
      dialog.hide();
      isLoading.current = false;

      profileImageUrl = null;

      setState(prevState => {
        return { ...prevState, image: null };
      });
    } catch (error) {
      showError(t('profile.uploadPhotoError', 'An error has occured while uploading your photo.'), {
        position: toastPosition.center,
        hideAfter: 5
      });
    }
  };

  const removePhoto = () => {
    try {
      isLoading.current = true;
      const submitData = {
        profileImageUrl: null,
        user: { id: user?.id }
      };
      submit(submitData);

      api.deletePhoto(profileImageUrl);
    } catch (error) {
      showError(t('profile.deletePhotoError', 'An error has occured while deleting your photo.'), {
        position: toastPosition.center,
        hideAfter: 5
      });
    }
    profileImageUrl = null;
    setState(prevState => {
      return { ...prevState, image: null };
    });

    isLoading.current = false;
  };

  if (profileLoading) return null;

  return (
    <Box as="form">
      <Flex flexDirection={['column']} justifyContent="center">
        <ProfileImage width="96px" height="96px" margin="0 auto 16px" />
        {!onboarding && !profileImageUrl && (
          <DialogTrigger {...dialog} margin="0 auto">
            <Button variant="primary" width="247px">
              {t('profile.addProfilePhotoButton', 'ADD')}
            </Button>
          </DialogTrigger>
        )}

        {onboarding && !profileImageUrl && (
          <DialogTrigger {...dialog} margin="0 auto">
            <Button as="div" variant="hollow">
              {t('profile.uploadYourPhoto', 'Upload Your Photo')}
            </Button>
          </DialogTrigger>
        )}

        {profileImageUrl && (
          <Flex>
            <Button
              as="div"
              variant="hollow"
              type="submit"
              onClick={removePhoto}
              isLoading={isLoading.current}
              spinnerColor="action.pink"
              margin="0 auto"
            >
              {t('profile.removePhoto', 'Remove Photo')}
            </Button>
          </Flex>
        )}
      </Flex>
      <Dialog {...dialog} preventBodyScroll={false} title="Add profile photo.">
        <Dropzone
          onDrop={handleDrop}
          noClick
          noKeyboard
          multiple={false}
          accept="image/jpeg, image/png"
          style={{
            width,
            height
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <Flex {...getRootProps()} flexDirection="column">
              {!image && (
                <DashedBox
                  width={['247px', '384px']}
                  height={['224px', '280px']}
                  p={[0, 5]}
                  flexDirection="column"
                >
                  <Flex justifyContent="center" mb="3">
                    <Image size="36" color={theme.colors.ui.line} />
                  </Flex>
                  <Text textAlign="center" mb="2">
                    {t('profile.dragPhoto', 'Drag and drop photo')}
                  </Text>
                  {/* <em>(Only *.jpeg and *.png images will be accepted)</em> */}
                  <Label htmlFor="upload" width={['199px', '234px']} m="0 auto">
                    <input id="upload" {...getInputProps()} />
                    <Button as="div" variant="primary" m="0 auto" width="fit-content">
                      {t('profile.uploadImageButton', 'UPLOAD IMAGE')}
                    </Button>
                  </Label>
                </DashedBox>
              )}
              {!profileImageUrl && image && (
                <Flex pt="2" flexDirection="column">
                  <Heading variant="h3" mb="1" textAlign="center">
                    {t('profile.addProfilePhotoTitle', 'Add profile photo')}
                  </Heading>
                  <Text mb="2" textAlign="center">
                    {t('profile.dragPhotoSubtitle', 'Drag image to center it.')}
                  </Text>
                  <AvatarEditor
                    ref={setEditorRef}
                    width={width}
                    height={height}
                    image={image}
                    scale={parseFloat(scale)}
                    color={[196, 196, 196, 0.8]}
                    border={border}
                    borderRadius={borderRadius}
                  />
                  <Flex flexDirection="row" width="100%" mt="2" mb="2">
                    <IconWrap>
                      <Image size="12" />
                    </IconWrap>
                    <Box width="100%">
                      <StyledSlider
                        tooltipVisible={false}
                        onChange={handleScale}
                        defaultValue={50}
                        min={10}
                        max={100}
                        step={10}
                      />{' '}
                    </Box>
                    <IconWrap>
                      <Image size="18" />
                    </IconWrap>
                  </Flex>

                  <Button
                    variant="primary"
                    type="submit"
                    onClick={onSubmit}
                    isLoading={isLoading.current}
                  >
                    {t('profile.saveUploadedImage', 'SAVE')}
                  </Button>

                  <Label htmlFor="upload" width={['199px', '234px']} m="0 auto">
                    <input id="upload" {...getInputProps()} />
                    <Button as="div" variant="hollow">
                      {t('profile.uploadDifferentImage', 'Upload different image')}
                    </Button>
                  </Label>
                </Flex>
              )}
            </Flex>
          )}
        </Dropzone>
      </Dialog>
    </Box>
  );
};

ImageEditor.propTypes = {
  onboarding: PropTypes.any
};
