import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography } from 'styled-system';
import { string } from 'prop-types';
import { uuid } from './uuid';

const PlusSign = forwardRef(({ title, ...props }, svgRef) => {
  const [titleId] = useState(() => uuid());

  return (
    <svg
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}

      <path d="M12 5V19" />
      <path d="M5 12H19" />
    </svg>
  );
});

const PlusSignIcon = styled(PlusSign)(
  {
    flex: 'none',
    verticalAlign: 'middle'
  },
  compose(space, color, layout, typography)
);

PlusSign.propTypes = {
  title: string
};

PlusSign.defaultProps = {
  title: 'plus sign icon'
};

export default PlusSignIcon;
