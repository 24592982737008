import styled from 'styled-components';
import { MenuItem } from 'reakit/Menu';

export const MenuItemStyled = styled(MenuItem)`
  appeareance: none;
  outline: none;
  background: none;
  border: none;
  text-align: justify;
  word-break: break-word;
  font-size: ${props => props.theme.fontSizes[2]};
  line-height: ${props => props.theme.lineHeights[2]};
  margin-bottom: ${props => props.theme.space[4]}px;

  &:focus,
  &:active {
    outline: 1px dashed ${props => props.theme.colors.action.pink};
    outline-offset: 3px;
  }
`;

export const AdminMenuItemStyled = styled(MenuItem)`
  display: block;
  width: 100%;
  margin-bottom: ${props => props.theme.space[1]}px;
  padding: ${props => props.theme.space[2]}px ${props => props.theme.space[4]}px;
  appeareance: none;
  outline: none;
  background: none;
  border: none;
  text-align: left;
  word-break: break-word;
  font-size: ${props => props.theme.fontSizes[1]};
  line-height: ${props => props.theme.lineHeights[1]};
  border-radius: ${props => props.theme.space[1]}px;

  &:focus,
  &:active {
    outline: 1px dashed ${props => props.theme.colors.action.pink};
    outline-offset: 3px;
    background: ${props => props.theme.colors.background.hover};
  }

  &:hover,
  &[variant^='brand'] {
    color: ${props => props.theme.colors.action.pink};
    background: ${props => props.theme.colors.background.hover};
  }

  svg {
    vertical-align: bottom;
    margin-right: ${props => props.theme.space[1]}px;
  }
`;
