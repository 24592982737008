import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { getUrlParam } from 'modules/core/utils';
import { languageConstants } from 'modules/core/constants';
import Loader from 'modules/core/importTranslation';

const availableLanguages = [
  { key: languageConstants.DEFAULT_LANGUAGE, value: 'English', dateFormat: 'MM/DD/YYYY' },
  { key: 'es', value: 'Español', dateFormat: 'dd/mm/yyyy' }
];

let lang =
  window.localStorage.getItem(languageConstants.LANGUAGE_STORAGE_KEY) ||
  languageConstants.DEFAULT_LANGUAGE;

window.localStorage.setItem(languageConstants.LANGUAGE_STORAGE_KEY, lang);

let defaultOptions = {
  lng: lang,
  fallbackLng: languageConstants.DEFAULT_LANGUAGE,
  ns: languageConstants.DEFAULT_NAMESPACE,
  whitelist: availableLanguages.map(l => l.key)
};

const urlLang = getUrlParam('lng');
if (urlLang !== null && defaultOptions.whitelist.find(l => l === urlLang) !== undefined) {
  lang = urlLang;
  defaultOptions = { ...defaultOptions, lng: lang };
  window.localStorage.setItem(languageConstants.LANGUAGE_STORAGE_KEY, lang);
  window.location.replace(process.env.REACT_APP_WEB_BASE_URL + window.location.pathname);
}

const importResource = async language => {
  const module = await import(`assets/locales/${language}/translation.json`);
  return { ...module.default };
};

i18n
  .use(Loader)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    initImmediate: false,
    ...defaultOptions,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    backend: {},
    // our custom settings
    customSettings: {
      languages: availableLanguages,
      setLanguage: async lng => {
        const existingLanguage = i18n.store?.data[lng];
        if (!existingLanguage) {
          const langModule = await importResource(lng);
          i18n.addResourceBundle(lng, defaultOptions.ns, langModule);
        }
        i18n.changeLanguage(lng);
      }
    }
  });

export default i18n;
