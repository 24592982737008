import React from 'react';
import styled from 'styled-components';
import { DatePicker as AntDatePicker } from 'antd';

import 'antd/lib/date-picker/style/css';

import { datePickerStyle } from './style';

export const DatePicker = styled(props => (
  <AntDatePicker
    format={['MMM D, YYYY', 'M-D-YYYY', 'MM-DD-YYYY', 'M/D/YYYY', 'MM/DD/YYYY']}
    getPopupContainer={trigger => {
      return trigger;
    }}
    showToday={false}
    {...props}
  />
))`
  ${datePickerStyle}
`;
