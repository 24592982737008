import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalize } from 'modules/core/language';
import { Box, Flex, Text } from 'modules/ui/primitives';
import { Container } from 'modules/ui/primitives/container';
import { Button } from 'modules/ui/primitives/button';
import { FadeInTrigger } from 'modules/ui/primitives/transitions/fadeInTrigger';
import { HIDE_LANGUAGE_SELECTOR } from 'modules/shared/constants';
import { Select } from 'modules/ui/primitives/select';

export const LanguageSelector = () => {
  const { langState, setLanguage, setLangState } = useLocalize();
  const { t } = useTranslation();

  const secondLang =
    langState.availableLanguages.length === 2 &&
    langState.availableLanguages.find(item => item.key !== langState.currentLangKey);

  const handleSwitch = async e => {
    if (secondLang) {
      await setLanguage(secondLang.key);
    } else {
      await setLanguage(e.target.value);
    }
  };

  const handleDismiss = () => {
    window.localStorage.setItem(HIDE_LANGUAGE_SELECTOR, 'true');
    setLangState(prevState => {
      return { ...prevState, hideSelector: true };
    });
  };

  return (
    langState &&
    !langState.hideSelector && (
      <Box
        position="absolute"
        top={0}
        right="0"
        width="100%"
        zIndex="50"
        id="language-selector-popup"
      >
        <FadeInTrigger as={Flex} show delay={500} duration={500}>
          <Flex
            px={[4, 0, 4]}
            py={[2]}
            borderBottomWidth="1px"
            borderBottomStyle="solid"
            borderBottomColor="ui.line"
            bg="base.white"
            position="relative"
            ml="auto"
            width={['100%', '50%']}
          >
            <Container>
              <Flex alignItems="baseline" justifyContent={['flex-start', 'center']}>
                <Text mr="1" variant="caption">
                  {t('auth.multilingual', 'We are multilingual.')}
                </Text>
                {langState && langState.availableLanguages.length > 2 ? (
                  <Select
                    value={langState.currentLangKey}
                    name="language-choose"
                    bg="base.grey100"
                    color="text.secondary"
                    borderColor="transparent"
                    borderRadius="2"
                    pt="1"
                    pl="2"
                    pb="1"
                    pr="4"
                    onChange={handleSwitch}
                  >
                    {langState.availableLanguages.map(item => (
                      <option key={item.key} value={item.key}>
                        {t(`profile.${item.key}`)}
                      </option>
                    ))}
                  </Select>
                ) : (
                  <Button
                    p="0"
                    pt="0"
                    pb="0"
                    fontSize="0"
                    lineHeight="0"
                    type="button"
                    onClick={handleSwitch}
                    variant="hollow"
                    color="text.secondary"
                  >
                    {t('auth.multilingualSwitch', 'Switch to English')}
                  </Button>
                )}
                <Button
                  position="absolute"
                  right="2"
                  ml="auto"
                  py="0"
                  type="button"
                  onClick={handleDismiss}
                  variant="hollow"
                  color="active.pink"
                  fontSize="0"
                  lineHeight="0"
                >
                  {t('auth.hide', 'Hide')}
                </Button>
              </Flex>
            </Container>
          </Flex>
        </FadeInTrigger>
      </Box>
    )
  );
};
