import MemberProfileScreen from './screens/memberProfile';
import ProfileSetupScreen from './screens/profileSetup';
import InviteFriendScreen from './screens/inviteFriend';

export default [
  {
    name: 'Member Profile',
    path: '/profile',
    component: MemberProfileScreen
  },
  {
    name: 'Profile Setup',
    path: '/profile/setup',
    component: ProfileSetupScreen
  },
  {
    name: 'Invite A Friend',
    path: '/profile/invite-a-friend',
    component: InviteFriendScreen
  }
];
