export const HIDE_ONBOARDING_PROGRESS = '__sm__hide_onboarding_progress';
export const HIDE_BALLOT_GUIDE_BANNER = '__sm__hide_ballot_guide_banner';
export const HIDE_LANGUAGE_SELECTOR = '__sm__hide_language_selector';
export const INFO_EMAIL = 'info@supermajority.com';
export const HIDE_BALLOT_WELCOME = '__sm__hide_ballot_welcome';

export const RACES = [
  {
    key: 'african-american-or-black',
    label: 'African American or Black'
  },
  {
    key: 'american-indian-or-alaska-native',
    label: 'American Indian or Alaska Native'
  },
  {
    key: 'asian',
    label: 'Asian'
  },
  {
    key: 'middle-eastern-or-north-african',
    label: 'Middle Eastern or North African'
  },
  {
    key: 'hispanic-or-latinix',
    label: 'Hispanic or Latinx'
  },
  {
    key: 'native-hawaiian-or-other-pacific-islander',
    label: 'Native Hawaiian or Other Pacific Islander'
  },
  {
    key: 'white',
    label: 'White'
  },
  {
    key: 'multiracial',
    label: 'Multiracial'
  },
  {
    key: 'undisclosed',
    label: 'Undisclosed'
  }
];
