import * as apiClient from 'modules/core/apiClient';
import { schema } from 'modules/core/schema';
import { httpMethods } from 'modules/core/constants';
import { useQuery, useMutation } from 'jsonapi-react';

export const getHomeActionStatuses = async () => {
  const { data } = await apiClient.get(`${schema['home-action-statuses'].endpoint}`);
  return data;
};

export const getHomeActions = async () => {
  const { data } = await apiClient.get([
    `${schema['home-actions-base'].endpoint}/all`,
    {
      include: ['campaign'],
      filter: {
        eventType: ['campaign', 'basic']
      }
    }
  ]);
  return data;
};

export const updateOrCreateHomeActionStatus = async id => {
  const { data } = await apiClient.post(
    `${schema['home-action-complete-actions'].endpoint}`,
    { force: true },
    {
      _type: 'home-action-complete-actions',
      homeAction: { id, _type: 'home-actions' }
    },
    httpMethods.POST,
    schema['home-action-complete-actions'].type
  );
  return data;
};

export function useGetStatistics() {
  return useQuery(`${schema['user-statistics'].endpoint}`);
}
export const getElectionsData = async () => {
  const { data } = await apiClient.get(`${schema.elections.endpoint}`);
  return data;
};

export function useGetHomeFeaturedActions() {
  return useQuery([
    schema['home-actions-base'].endpoint,
    {
      filter: {
        active: true,
        homeFeatured: true
      },
      sort: 'position,-updatedAt'
    }
  ]);
}

export function useGetAllStatistics() {
  return useQuery(`${schema.statistics.endpoint}/all`, {
    cacheTime: 10 * 60
  });
}

export function useSaveUserAnswers() {
  return useMutation(schema['home-action-poll-answers'], {
    method: 'POST'
  });
}

export function useGetUserPollAnswers() {
  return useQuery([
    schema['home-action-poll-answers'].endpoint,
    {
      include: ['action']
    }
  ]);
}

export function useGetUserPollAnswersStatistics(actionId) {
  return useQuery([schema['poll-answers-statistics'].endpoint, actionId, 'statistics']);
}

export async function saveUserAnswers(values) {
  const { data } = await apiClient.post(
    schema['home-action-poll-answers'].endpoint,
    { invalidate: [] },
    values
  );
  return data;
}

export const getSupermajorityNews = async () => {
  const response = await fetch('https://supermajority.com/wp-json/wp/v2/posts?per_page=3&_embed', {
    method: httpMethods.GET
  });
  return response;
};

export const getSupermajorityOrganization = async id => {
  const response = await fetch(`https://supermajority.com/wp-json/wp/v2/organization?post=${id}`, {
    method: httpMethods.GET
  });
  return response;
};
