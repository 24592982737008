/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Dialog as BaseDialog, DialogBackdrop } from 'reakit/Dialog';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'modules/ui/primitives/box';
import { Icons } from 'modules/ui/assets';
import { Button } from 'modules/ui/primitives/button';
import { backdropStyles, dialogStyles } from './shared/styles';

const BackdropStyled = styled(DialogBackdrop)`
  ${backdropStyles}
`;

const DialogStyled = styled(BaseDialog)`
  ${dialogStyles}
`;

export const Dialog = ({
  title,
  hideCloseButton,
  onClose,
  preventBodyScroll,
  contentPadding,
  children,
  isModal,
  ...props
}) => {
  const handleCloseClick = () => {
    const { hide } = props;
    if (typeof onClose !== 'undefined') {
      onClose();
    }
    hide();
  };
  const WrapperElement = isModal ? BackdropStyled : Box;
  return (
    <>
      <WrapperElement {...props}>
        <Box
          as="section"
          position="relative"
          maxWidth={['90%', props.maxwidth || '57.6rem']}
          minHeight="20%"
          maxHeight="90%"
          overflowY={!isModal ? 'none' : 'auto'}
          mx="auto"
          borderRadius="2"
          bg="base.white"
        >
          <DialogStyled
            preventBodyScroll={preventBodyScroll}
            {...props}
            aria-label={title}
            hide={handleCloseClick}
            tabIndex={0}
          >
            {!hideCloseButton && (
              <Box position="absolute" right={[2, 3]} top={[2, 3]}>
                <Button variant="hollow" id="close-modal-icon" onClick={handleCloseClick}>
                  <Icons.Close fontSize="1" />
                </Button>
              </Box>
            )}
            <Box as="section" p={contentPadding}>
              {children}
            </Box>
          </DialogStyled>
        </Box>
      </WrapperElement>
    </>
  );
};

Dialog.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string.isRequired,
  hideCloseButton: PropTypes.bool,
  hide: PropTypes.func,
  isModal: PropTypes.bool,
  preventBodyScroll: PropTypes.bool,
  onClose: PropTypes.func,
  contentPadding: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  maxwidth: PropTypes.any
};

Dialog.defaultProps = {
  hideCloseButton: false,
  preventBodyScroll: true,
  contentPadding: [5, 7],
  isModal: true
};
