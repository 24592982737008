import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography } from 'styled-system';
import { string } from 'prop-types';
import { uuid } from './uuid';

const PolygonUp = forwardRef(({ title, ...props }, svgRef) => {
  const [titleId] = useState(() => uuid());

  return (
    <svg
      width="31"
      height="22"
      viewBox="0 0 31 22"
      fill="none"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M29.7893 20.8L15.4999 1L1.21045 20.8" stroke="#E6E6E6" />
    </svg>
  );
});

const PolygonUpIcon = styled(PolygonUp)(
  {
    flex: 'none',
    verticalAlign: 'middle'
  },
  compose(space, color, layout, typography)
);

PolygonUp.propTypes = {
  title: string
};

PolygonUp.defaultProps = {
  title: 'PolygonUp icon'
};

export default PolygonUpIcon;
