import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography } from 'styled-system';
import { string } from 'prop-types';
import { uuid } from './uuid';

const CheckboxChecked = forwardRef(({ title, ...props }, svgRef) => {
  const [titleId] = useState(() => uuid());
  return (
    <svg
      viewBox="0 0 24 24"
      width="24px"
      height="24px"
      fill="currentcolor"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M19 3H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
    </svg>
  );
});

const CheckboxCheckedIcon = styled(CheckboxChecked)(
  {
    flex: 'none',
    verticalAlign: 'middle'
  },
  compose(space, color, layout, typography)
);

CheckboxChecked.propTypes = {
  title: string
};

export default CheckboxCheckedIcon;
