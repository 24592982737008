import React from 'react';
import { useProfile } from 'modules/profile/profileProvider';
import AvatarImage from 'modules/shared/components/avatarImage';

const ProfileImage = ({ ...props }) => {
  const [
    {
      data: { firstName, lastName, profileImage }
    }
  ] = useProfile();

  return (
    <AvatarImage firstName={firstName} lastName={lastName} profileImage={profileImage} {...props} />
  );
};

export default ProfileImage;
