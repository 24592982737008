import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'modules/ui/primitives/box';
import { Flex } from 'modules/ui/primitives/flex';
import { BackgroundHD, Placeholder } from './shared/styles';

class Background extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    };

    this.hdImage = React.createRef();
  }

  componentDidMount() {
    const { src } = this.props;
    const newImage = document.createElement('img');
    const hdImageRef = this?.hdImage?.current;
    newImage.src = src;
    newImage.onload = () => {
      hdImageRef.setAttribute('style', `background-image: url('${src}')`);
      this.setState({ loaded: true });
    };
  }

  render() {
    const { loaded } = this.state;
    const { placeholder, children, ...rest } = this.props;
    return (
      <Box position="relative" width="100%" height="100%" overflow="hidden" {...rest}>
        <BackgroundHD ref={this.hdImage} loaded={loaded} />
        <Placeholder style={{ backgroundImage: `url('${placeholder}')` }} />
        <Flex
          alignItems="center"
          justifyContent="center"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          zIndex="3"
        >
          {children}
        </Flex>
      </Box>
    );
  }
}

Background.propTypes = {
  src: PropTypes.string,
  placeholder: PropTypes.string,
  children: PropTypes.any
};

export { Background };
