import React, { memo } from 'react';
import { Switch, Router, Route } from 'wouter';
import PropTypes from 'prop-types';

export const AuthLayout = memo(({ routes }) => {
  return (
    <Router>
      <Switch>
        {routes.map(publicRoute => {
          return (
            <Route
              key={`${publicRoute.path}-${publicRoute.name}`}
              path={publicRoute.path}
              component={publicRoute.component}
            />
          );
        })}
      </Switch>
    </Router>
  );
});

AuthLayout.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string
    })
  )
};
