import { useContext, useEffect, useMemo, useState } from 'react';
import { ThemeContext } from 'styled-components';

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export function useGetCurrentBreakpointValue(valuesForBreakpoints) {
  const themeContext = useContext(ThemeContext);
  const windowSize = useWindowSize();

  const currentBreakpointValue = useMemo(() => {
    if (!Array.isArray(valuesForBreakpoints || valuesForBreakpoints.length === 1))
      return valuesForBreakpoints;

    if (!valuesForBreakpoints.length) return undefined;
    const windowWidth = windowSize.width;
    if (!windowWidth) return undefined;
    const breakpointValues = themeContext.breakpoints.map(b => parseInt(b.replace('px', ''), 10));
    let currentBrekpointIndex = breakpointValues.findIndex(bp => windowWidth < bp);
    if (currentBrekpointIndex === -1) currentBrekpointIndex = breakpointValues.length;

    if (currentBrekpointIndex >= valuesForBreakpoints.length)
      return valuesForBreakpoints.slice(-1)[0];

    return valuesForBreakpoints[currentBrekpointIndex];
  }, [valuesForBreakpoints, themeContext.breakpoints, windowSize.width]);

  return currentBreakpointValue;
}
