import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSprings, animated } from 'react-spring';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Flex } from 'modules/ui/primitives/flex';
import { Box } from 'modules/ui/primitives/box';
import { Button } from 'modules/ui/primitives/button';
import { Bullet } from './components/bullet';

const StyledBulletList = styled.ul`
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
`;

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledSlide = styled.div`
  width: 100%;
  height: 100%;
  will-change: transform;
  user-select: none;
  pointer-events: none;
`;

export const Slider = ({
  activeIndex = 0,
  auto = 0,
  BulletComponent,
  bulletStyle = {},
  children = [],
  hasBullets = false,
  onSlideChange = () => undefined,
  setSlideCustom = undefined,
  hasBulletButton,
  bulletsCentered
}) => {
  const sliderRef = useRef(null);
  const [slide, setSlideOriginal] = useState(0);
  const setSlide = setSlideCustom
    ? index => setSlideOriginal(setSlideCustom(index))
    : setSlideOriginal;

  // Initialize slides with spring
  const [springProps, setSpringProps] = useSprings(children.length, index => ({
    offset: index
  }));

  const { t } = useTranslation();

  // Triggered on slide change
  useEffect(() => {
    // see:  https://github.com/react-spring/react-spring/issues/861
    setSpringProps(index => ({ offset: index - slide }));
    onSlideChange(slide);
  }, [slide, setSpringProps, onSlideChange]);

  // Effect for autosliding
  useEffect(() => {
    let interval;

    if (auto > 0) {
      interval = setInterval(() => {
        const targetIndex = (slide + 1) % children.length;
        setSlide(targetIndex);
      }, auto);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [auto, children.length, slide, setSlide]);

  // Jump to slide index when prop changes
  useEffect(() => {
    setSlide(activeIndex % children.length);
  }, [activeIndex, children.length, setSlide]);

  // Sets pointer events none to every child and preserves styles
  const childs = children.map((child, index) => (
    <StyledSlide key={index}>{child}</StyledSlide> // eslint-disable-line react/no-array-index-key
  ));

  const nextSlide = () => {
    if (slide === children.length - 1) {
      setSlide(0);
      return;
    }

    setSlide(slide + 1);
  };

  // const previousSlide = () => {
  //   if (slide === 0) {
  //     setSlide(children.length - 1);
  //     return;
  //   }

  //   setSlide(slide - 1);
  // };

  const isLastItem = slide === children.length - 1;

  return (
    <StyledWrapper ref={sliderRef}>
      <Box
        position="relative"
        overflow="hidden"
        width="100%"
        minHeight={['46rem', '40rem', '32rem', '29rem']}
      >
        {springProps.map(({ offset }, index) => (
          <animated.div
            key={index} // eslint-disable-line react/no-array-index-key
            style={{
              transform: offset.interpolate(offsetX => `translate3d(${offsetX * 100}%, 0, 0)`),
              position: 'absolute',
              width: '100%',
              height: '100%',
              willChange: 'transform'
            }}
          >
            {childs[index]}
          </animated.div>
        ))}
      </Box>
      {hasBullets && (
        <Flex
          justifyContent={bulletsCentered ? 'center' : 'flex-start'}
          alignItems="baseline"
          mt="1"
        >
          <StyledBulletList>
            {children.map((_, index) => (
              <Bullet
                key={index} // eslint-disable-line react/no-array-index-key
                index={index}
                BulletComponent={BulletComponent}
                setSlide={setSlide}
                activeIndex={slide}
                bulletStyle={bulletStyle}
              />
            ))}
          </StyledBulletList>
          {hasBulletButton && (
            <Button
              p="1"
              ml="auto"
              variant="hollow"
              color="brand.pink"
              onClick={nextSlide}
              selfAlign="baseline"
            >
              {!isLastItem ? t('auth.next', 'Next') : t('auth.back', 'Back')}
            </Button>
          )}
        </Flex>
      )}
    </StyledWrapper>
  );
};

Slider.propTypes = {
  activeIndex: PropTypes.number,
  auto: PropTypes.number,
  BulletComponent: PropTypes.node,
  bulletStyle: PropTypes.any,
  children: PropTypes.node,
  hasBullets: PropTypes.bool,
  onSlideChange: PropTypes.func,
  setSlideCustom: PropTypes.func,
  hasBulletButton: PropTypes.bool,
  bulletsCentered: PropTypes.bool
};
