import React from 'react';
import PropTypes from 'prop-types';
import { useMenuState, Menu, MenuItem } from 'reakit/Menu';
import { Text } from 'modules/ui/primitives/text';
import { Flex } from 'modules/ui/primitives/flex';
import { Stack } from 'modules/ui/primitives/stack';
import { theme } from 'modules/ui/theme';
import ProfileImage from 'modules/profile/components/profileImage';
import { MenuAnimated, MenuTriggerStyled } from './styles/dropdownStyles';

export const Dropdown = ({ firstName, items, color }) => {
  const menu = useMenuState({
    animated: 250,
    placement: 'bottom-end',
    gutter: 20
  });

  const textColor = color || theme.colors.text.primary;

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      id="profile-dropdown"
      className="hidden-mobile"
    >
      <Text variant="body-1" mr="2" display={['none', 'block']} color={textColor}>
        {firstName}
      </Text>
      <MenuTriggerStyled
        id="profile-dropdown-menu-trigger"
        {...menu}
        active={menu.visible || false}
      >
        <ProfileImage />
      </MenuTriggerStyled>
      <Menu
        id="profile-dropdown-menu"
        {...menu}
        aria-label="Open profile menu"
        tabIndex={0}
        style={{ outline: 'none', zIndex: '99' }}
      >
        <Stack
          axis="vertical"
          as={MenuAnimated}
          minWidth="20rem"
          flexDirection="column"
          bg="white"
          py="2"
          px="2"
        >
          {items.map((item, i) => (
            <MenuItem
              id={`profile-dropdown-item-${i}`}
              {...menu}
              {...item.props}
              // eslint-disable-next-line react/no-array-index-key
              key={`profile-dropdown-item-${i}`}
            >
              {itemProps => React.cloneElement(item, itemProps)}
            </MenuItem>
          ))}
        </Stack>
      </Menu>
    </Flex>
  );
};

Dropdown.propTypes = {
  items: PropTypes.any,
  firstName: PropTypes.string,
  color: PropTypes.string
};
