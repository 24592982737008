import React from 'react';
import PropTypes from 'prop-types';
import { Button, Flex, Heading, Text } from 'modules/ui/primitives';
import { Trans, useTranslation } from 'react-i18next';
import { PinkFragment } from 'modules/ui/components/pinkFragment';
import styled from 'styled-components';
import AvatarImage from 'modules/shared/components/avatarImage';
import { ProfileSection } from './profileSection';
import { useGetInvitedPeople } from '../api';

const StyledList = styled.ul`
  list-style-type: none;
  li {
    margin-bottom: ${props => props.theme.space[3]}px;
  }
`;

const ListItem = ({ firstName, lastName, profileImage }) => {
  const fullName = `${firstName} ${lastName}`;
  return (
    <Flex as="li" alignItems="center">
      <AvatarImage
        firstName={firstName}
        lastName={lastName}
        profileImage={profileImage}
        mr={4}
        width="36px"
        height="36px"
      />
      <Text>{fullName}</Text>
    </Flex>
  );
};

ListItem.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  profileImage: PropTypes.string
};

export default function InvitedPeople() {
  const { t } = useTranslation();

  const [{ loading, invited, moreToShow, total }, fetchMore] = useGetInvitedPeople();

  return invited.length ? (
    <ProfileSection>
      <Flex flexDirection="column">
        <Heading as="h2" variant="h3Bold" mb={4}>
          <Trans i18nKey="profile.inviteFriend.invitedPeopleTitle" count={total}>
            <PinkFragment>{{ count: total }} person</PinkFragment> you invited has joined
            Supermajority
          </Trans>
        </Heading>
        <StyledList>
          {invited.map(u => (
            <ListItem key={u.email} {...u} />
          ))}
        </StyledList>
      </Flex>
      {Boolean(moreToShow) && (
        <Flex justifyContent="center">
          <Button variant="link" isLoading={loading} onClick={fetchMore}>
            {t('profile.inviteFriend.showMoreInvitedPeople', {
              defaultValue: 'Show {{moreCount}} more',
              moreCount: moreToShow
            })}
          </Button>
        </Flex>
      )}
    </ProfileSection>
  ) : null;
}
