import styled from 'styled-components';
import { Box } from '../box';

export const Container = styled(Box)`
  max-width: 100%;
  width: 100%;
  margin-left: 0;
  margin-right: 0;

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    max-width: ${props => (props.fullWidth ? '100%' : '1200px')};
    ${'' /* max-width: 126rem; */}
    ${'' /* max-width: 148rem; */}
    margin-left: auto;
    margin-right: auto;
  }
`;
