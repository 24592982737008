import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { MenuButton } from 'reakit/Menu';
import { Flex } from 'modules/ui/primitives/flex';

export const MenuTrigger = forwardRef(
  ({ children, borderRadius, borderColor, ...props }, triggerRef) => {
    return (
      <MenuButton
        ref={triggerRef}
        as={Flex}
        width="36px"
        height="36px"
        bg="base.grey100"
        alignItems="center"
        justifyContent="center"
        borderWidth="1px"
        borderStyle="solid"
        borderColor={props.active ? 'action.pink' : 'ui.transparent'}
        {...props}
      >
        {children}
      </MenuButton>
    );
  }
);

MenuTrigger.propTypes = {
  children: PropTypes.any,
  borderRadius: PropTypes.string,
  borderColor: PropTypes.string,
  active: PropTypes.bool
};

MenuTrigger.defaultProps = {
  borderRadius: '50%',
  borderColor: 'action.pink'
};
