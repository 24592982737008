import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from 'modules/ui/primitives';
import { Heading } from 'modules/ui/primitives/heading';
import { Text } from 'modules/ui/primitives/text';
import { INFO_EMAIL } from 'modules/shared/constants';
import { Link } from 'modules/ui/primitives/link';

export const ActionsHeader = () => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection={['column', 'row']} mb={5}>
      <Box width={['100%', '75%', '35%']} px={1} mb={[3, 0]}>
        <Heading as="h2" variant="h1" fontWeight="bold" uppercase>
          {t('admin.actionCenter', 'Action center')}
        </Heading>
      </Box>
      <Box width={['100%', '35%', '65%']} px={1}>
        <Text color="text.secondary">
          {t(
            'home.joinThousands',
            "Join thousands of other women who are ready to build women's power and fight for gender equality. Explore active petitions, polls, events, GOTV efforts, and more. And check back often for the most up-to-date ways to make an impact."
          )}{' '}
          {t('home.havingTrouble', 'Having trouble?')}{' '}
          <Link external variant="brand" href={`mailto:${INFO_EMAIL}`}>
            {t('home.contactForAssistance', 'Contact us for assistance.')}
          </Link>
        </Text>
      </Box>
    </Flex>
  );
};
