import { Box } from 'modules/ui/primitives';
import React from 'react';
import { any } from 'prop-types';

export const ProfileSection = ({ children }) => {
  return (
    <Box
      as="section"
      borderRadius={[0, 4]}
      mb={5}
      bg="base.white"
      p={[4, 5]}
      maxWidth={['100%', '75%']}
    >
      {children}
    </Box>
  );
};

ProfileSection.propTypes = {
  children: any
};
