/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const triggerGtmEvent = (category, action, label) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'GA-event',
      category,
      action,
      label
    });
  }
};
