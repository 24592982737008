import React from 'react';
import { Redirect } from 'wouter';

const OverviewScreen = React.lazy(() => import('./screens/overviewScreen'));
const ActionsTableScreen = React.lazy(() => import('./screens/actions/tableScreen'));
const ActionsFormScreen = React.lazy(() => import('./screens/actions/formScreen'));
const TeamTableScreen = React.lazy(() => import('./screens/team/tableScreen'));
const MembersTableScreen = React.lazy(() => import('./screens/members/tableScreen'));
const UpdateStatsScreen = React.lazy(() => import('./screens/updateStatsScreen.js'));

export default [
  {
    name: 'Admin',
    path: '/admin',
    component: () => <Redirect to="/admin/overview" />
  },
  {
    name: 'Overview',
    path: '/admin/overview',
    component: OverviewScreen
  },
  {
    name: 'Actions',
    path: '/admin/actions',
    component: ActionsTableScreen
  },

  {
    name: 'Action',
    path: '/admin/action/:id?',
    component: ActionsFormScreen
  },
  {
    name: 'Team',
    path: '/admin/team',
    component: TeamTableScreen
  },
  {
    name: 'Members',
    path: '/admin/members',
    component: MembersTableScreen
  },
  {
    name: 'Update Stats',
    path: '/admin/update-stats',
    component: UpdateStatsScreen
  }
];
