import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { Icons } from 'modules/ui/assets';
import { Box } from '../box';

const spinFrames = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const spin = () =>
  css`
    ${spinFrames} 3s infinite linear;
  `;

const Rotating = styled(Icons.Loading)`
  animation: ${spin};
`;

export const Spinner = ({ block, title, size, color, ...props }) => {
  return (
    <Box display={block ? 'block' : 'inline-block'} {...props}>
      <Rotating title={title} fontSize={size} color={color} />
    </Box>
  );
};

Spinner.propTypes = {
  block: PropTypes.bool,
  title: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  color: PropTypes.string
};

Spinner.defaultProps = {
  block: true,
  title: 'Loading...',
  color: 'text.secondary'
};
