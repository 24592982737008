import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalize } from 'modules/core/language';
import { Box, Flex, Text } from 'modules/ui/primitives';
import { Link } from 'modules/ui/primitives/link';
import { Stack } from 'modules/ui/primitives/stack';
import { Container } from 'modules/ui/primitives/container';
import { Icons } from 'modules/ui/assets';
import { triggerGtmEvent } from 'modules/shared/services';
import { StyledIcon } from './styles/footerStyles';

export const AppFooter = () => {
  const { t } = useTranslation();
  const { langState } = useLocalize();

  const privacyLink =
    langState.currentLangKey === 'en'
      ? `${process.env.REACT_APP_SUPERMAJORITY_WEB_BASE_URL}/privacy-policy`
      : `${process.env.REACT_APP_SUPERMAJORITY_WEB_BASE_URL}/${langState.currentLangKey}/privacy-policy-2`;

  return (
    <Box as="footer" bg="base.white">
      <Flex>
        <Box minHeight="0" width={1 / 2} bg="action.pink" />
        <Box minHeight="0" width={1 / 2} bg="action.orange" />
      </Flex>
      <Container>
        <Flex
          as="article"
          pt={[7, 6, 6, '19rem']}
          pb={[4, 5, 5, 7]}
          px={[4, 4, 4, 0]}
          flexDirection={['column', 'row']}
        >
          <Flex order={[2, 1]} flexDirection={['column', 'column']}>
            <Stack mb="2">
              <Link
                external
                href={`${process.env.REACT_APP_SUPERMAJORITY_WEB_BASE_URL}/${langState.currentLangKey}/contact`}
                variant="brand"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  triggerGtmEvent('Member experience', 'Click', 'Contact-Footer');
                }}
              >
                {t('auth.contact', 'Contact')}
              </Link>
              <Link
                external
                href={`${process.env.REACT_APP_SUPERMAJORITY_WEB_BASE_URL}/${langState.currentLangKey}/about-us`}
                variant="brand"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  triggerGtmEvent('Member experience', 'Click', 'About us-Footer');
                }}
              >
                {t('auth.aboutUs', 'About Us')}
              </Link>
              <Link
                external
                href={privacyLink}
                variant="brand"
                onClick={() => {
                  triggerGtmEvent('Member experience', 'Click', 'Privacy policy-Footer');
                }}
              >
                {t('auth.privacyPolicy', 'Privacy Policy')}
              </Link>
            </Stack>
            <Text color="text.secondary">©2020 Supermajority</Text>
          </Flex>
          <Stack ml={[null, 'auto']} order={[1, 2]} mb={[7, null]} space={4}>
            <StyledIcon
              href="https://twitter.com/supermajority"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Icons.Twitter
                title="supermajority twitter icon link"
                fontSize="2"
                color="transparent"
              />
            </StyledIcon>
            <StyledIcon
              href="https://www.facebook.com/thesupermajority/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Icons.Facebook
                title="supermajority facebook icon link"
                fontSize="2"
                color="transparent"
              />
            </StyledIcon>
            <StyledIcon
              href="https://instagram.com/supermajority"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Icons.Instagram
                title="supermajority instagram icon link"
                fontSize="2"
                color="transparent"
              />
            </StyledIcon>
          </Stack>
        </Flex>
      </Container>
    </Box>
  );
};
