import React, { useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toastTypes } from 'modules/shared/components/toast/toast';
import { uuid } from 'modules/ui/assets/uuid';
import ToastContainer from './toastContainer';

export const toastPosition = {
  left: 'left',
  center: 'center',
  right: 'right'
};

const ToastContext = React.createContext();

export const ToastProvider = ({
  toastContainerID,
  hideAfter,
  aminationDuration,
  position,
  children
}) => {
  const [toasts, setToasts] = useState([]);

  const animationTime = aminationDuration * 1000;

  const removeToast = useCallback(toastId => {
    setToasts(prevToasts => {
      const toastToRemove = prevToasts.find(t => t.id === toastId);
      if (toastToRemove.hideTimerId) {
        clearTimeout(toastToRemove.hideTimerId);
      }
      return prevToasts.filter(t => t.id !== toastId);
    });
  }, []);

  const hideToast = useCallback(toastId => {
    setToasts(prevToasts => {
      const nextToasts = [...prevToasts];
      const index = nextToasts.findIndex(t => t.id === toastId);
      nextToasts[index] = { ...nextToasts[index], hiding: true };
      return nextToasts;
    });
  }, []);

  const hideAllToasts = useCallback(() => {
    setToasts(prevToasts => prevToasts.map(t => ({ ...t, hiding: true })));
  }, []);

  const showToast = useCallback(
    (text, options) => {
      const resolvedPosition = options?.position || position;
      const hideTime = (options?.hideAfter === undefined ? hideAfter : options.hideAfter) * 1000;

      const toastId = uuid();
      let hideTimerId = null;
      if (hideTime > 0) {
        hideTimerId = setTimeout(() => {
          hideToast(toastId);
        }, hideTime);
      }

      const hide = () => hideToast(toastId);

      // add the new toast to state
      setToasts(prevToasts => {
        const toast = {
          id: toastId,
          position: resolvedPosition,
          text,
          type: options.type,
          hide,
          hiding: false,
          hideTimerId,
          remove: () => removeToast(toastId),
          animationDuration: animationTime
        };
        return [...prevToasts, toast];
      });

      return {
        hide
      };
    },
    [animationTime, hideAfter, hideToast, position, removeToast]
  );

  return (
    <ToastContext.Provider
      value={{
        hideAllToasts,
        showError: useCallback(
          (text, options) => showToast(text, { ...options, type: toastTypes.error }),
          [showToast]
        ),
        showInfo: useCallback(
          (text, options) => showToast(text, { ...options, type: toastTypes.info }),
          [showToast]
        ),
        toasts
      }}
    >
      <ToastContainer id={toastContainerID} />
      {children}
    </ToastContext.Provider>
  );
};

ToastProvider.propTypes = {
  children: PropTypes.any.isRequired,
  toastContainerID: PropTypes.string,
  hideAfter: PropTypes.number,
  aminationDuration: PropTypes.number,
  position: PropTypes.string
};

ToastProvider.defaultProps = {
  toastContainerID: 'sm-toast-container',
  hideAfter: 6,
  aminationDuration: 0.3,
  position: toastPosition.right
};

export function useToasts() {
  const context = useContext(ToastContext);

  if (!context)
    throw new Error(
      'useToast can only be called from a component wrrapped within a ToastProvider.'
    );

  return context;
}
