export default {
  lineHeights: [
    '1.8rem',
    '2rem',
    '2.4rem',
    '2.6rem',
    '2.8rem',
    '3.2rem',
    '3.6rem',
    '4rem',
    '5.6rem',
    '7.6rem',
    '10rem'
  ]
};
