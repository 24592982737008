import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography } from 'styled-system';
import { string } from 'prop-types';
import { uuid } from './uuid';

const ExpandMore = forwardRef(({ title, ...props }, svgRef) => {
  const [titleId] = useState(() => uuid());
  return (
    <svg
      viewBox="0 0 24 24"
      width="1.1em"
      height="1.1em"
      fill="currentcolor"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
});

const ExpandMoreIcon = styled(ExpandMore)(
  {
    flex: 'none',
    verticalAlign: 'middle'
  },
  compose(space, color, layout, typography)
);

ExpandMore.propTypes = {
  title: string
};

ExpandMore.defaultProps = {
  title: 'visibility off icon'
};

export default ExpandMoreIcon;
