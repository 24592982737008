import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { fadeInUp } from './shared/fadeInUp';
import { Box } from '../box';

const FadeInStyled = styled(Box)`
  animation-name: ${fadeInUp};
  animation-delay: ${props => props.delay}ms;
  animation-duration: ${props => props.duration}ms;
  animation-timing-function: cubic-bezier(0.25, 0.75, 0.5, 1.25);
  animation-iteration-count: 1;
  animation-fill-mode: both;
`;

const FadeIn = memo(({ duration, delay, children, ...rest }) => {
  return (
    <FadeInStyled duration={duration} delay={delay} {...rest}>
      {children}
    </FadeInStyled>
  );
});

FadeIn.propTypes = {
  duration: PropTypes.number,
  delay: PropTypes.number,
  children: PropTypes.any
};

FadeIn.defaultProps = {
  duration: 300,
  delay: 0
};

export { FadeIn };
