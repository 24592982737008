import React from 'react';
import { withProfileLayout } from 'modules/shared/layouts/profile/withProfileLayout';
import GetToteSection from '../components/getToteSection';
import SendInvites from '../components/sendInvites';
import InvitedPeople from '../components/invitedPeople';
import WhoShouldIInvite from '../components/whoShouldIInvite';

const InviteFriendScreen = () => {
  return (
    <>
      <GetToteSection />
      <SendInvites />
      <InvitedPeople />
      <WhoShouldIInvite />
    </>
  );
};

export default withProfileLayout({
  showFooter: true
})(InviteFriendScreen);
