import React from 'react';
import { InvitedFriendsCount } from 'modules/profile/components/invitedFriendsCount';
import { Flex, Heading } from 'modules/ui/primitives';
import toteImg from 'modules/profile/assets/images/inviteFriendsToteNew.png';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ProfileSection } from './profileSection';

const ToteImage = styled.img`
  display: none;
  @media (min-width: ${props => props.theme.breakpoints[0]}) {
    display: inline-block;
    height: 186px;
    width: 186px;
    position: relative;
    top: -91px;
    right: -133px;
    margin-bottom: -91px;
    margin-left: -133px;
  }
`;

export default function GetToteSection() {
  const { t } = useTranslation();
  return (
    <ProfileSection>
      <Flex flexDirection="column">
        <Flex>
          <Heading as="h2" variant="h2Bold" mb={4}>
            {t(
              'profile.inviteFriend.getTote',
              'Get 10 friends to join to get a Supermajority tote!'
            )}
          </Heading>
          <ToteImage src={toteImg} alt="" />
        </Flex>
        <InvitedFriendsCount />
      </Flex>
    </ProfileSection>
  );
}
