import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Icons } from 'modules/ui/assets';
import { Text } from '../text';
import { Box } from '../box';
import { focusRing } from '../shared/focusRing';

const checkboxIconStyles = css`
  outline: 1px solid transparent;
  color: ${props => (props.error ? props.theme.colors.ui.error : props.theme.colors.action.pink)};

  font-size: ${props => props.theme.fontSizes[4]};

  &:hover {
    color: ${props =>
      props.error ? props.theme.colors.ui.error : props.theme.colors.action.pinkDark};
  }

  input:focus ~ & {
    color: ${props =>
      props.error ? props.theme.colors.ui.error : props.theme.colors.action.pinkDark};
    ${focusRing}
  }

  input:checked:disabled ~ &,
  input:disabled ~ & {
    color: ${props => props.theme.colors.base.grey200};
  }

  input:checked ~ & {
    color: ${props => (props.error ? props.theme.colors.ui.error : props.theme.colors.action.pink)};
  }
`;

const CheckboxUnchecked = styled(Icons.CheckboxChecked)`
  display: none;

  input:checked ~ & {
    display: block;
  }

  ${checkboxIconStyles}
`;

const CheckboxChecked = styled(Icons.CheckboxBlank)`
  display: block;

  input:checked ~ & {
    display: none;
  }

  ${checkboxIconStyles}
`;

const CheckboxIcon = props => (
  <>
    <CheckboxUnchecked {...props} />
    <CheckboxChecked {...props} />
  </>
);

export const Checkbox = forwardRef(
  (
    {
      children,
      label,
      variant,
      lineHeight,
      disabled,
      error,
      mb,
      mt,
      mx,
      my,
      ml,
      mr,
      title,
      ...props
    },
    ref
  ) => (
    <Text
      as="label"
      variant={variant}
      display="flex"
      alignItems="center"
      color={disabled ? 'text.disabled' : 'text.secondary'}
      lineHeight={lineHeight}
      mx={mx}
      my={my}
      mb={mb}
      mt={mt}
      ml={ml}
      mr={mr}
    >
      <Box display="inline-block" position="relative" minWidth="2">
        <Box
          ref={ref}
          as="input"
          type="checkbox"
          disabled={disabled}
          {...props}
          position="absolute"
          opacity={0}
          zIndex={-1}
          width={1}
          height={1}
          overflow="hidden"
          aria-invalid={error ? 'true' : 'false'}
        />
        <Box as={CheckboxIcon} title={title} display="inline-block" aria-hidden="true" />
      </Box>
      {!children && (
        <Box as="span" ml="2">
          {label}
        </Box>
      )}
      {children && (
        <Box as="span" ml="0" width="100%">
          {children}
        </Box>
      )}
    </Text>
  )
);

Checkbox.propTypes = {
  children: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  variant: PropTypes.oneOf(['body-1', 'body-2', 'body-semibold', 'caption', 'hint', 'label']),
  lineHeight: PropTypes.any,
  mb: PropTypes.any,
  mt: PropTypes.any,
  mx: PropTypes.any,
  my: PropTypes.any,
  ml: PropTypes.any,
  mr: PropTypes.any,
  title: PropTypes.any
};

Checkbox.defaultProps = {
  variant: 'label'
};
