import React from 'react';
import styled from 'styled-components';
import { Flex } from 'modules/ui/primitives/flex';
import { Text } from 'modules/ui/primitives/text';
import { focusRingDashed } from 'modules/ui/primitives/shared/focusRing';

export const TriggerBase = () => {
  return (
    <Flex
      borderRadius="0"
      bg="action.orange"
      textAlign="center"
      width="16px"
      height="16px"
      justifyContent="center"
      alignItems="center"
    >
      <Text color="base.white" fontSize="12px">
        ?
      </Text>
    </Flex>
  );
};

export const TooltipTrigger = styled(TriggerBase)`
  outline: none;
  &:focus {
    ${focusRingDashed};
  }
`;
