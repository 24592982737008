import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography } from 'styled-system';
import { string } from 'prop-types';
import { uuid } from './uuid';

const Error = forwardRef(({ title, ...props }, svgRef) => {
  const [titleId] = useState(() => uuid());
  return (
    <svg
      viewBox="0 0 24 24"
      width="1.1em"
      height="1.1em"
      fill="currentcolor"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM12 8v4M12 16h.01"
        stroke="#FFFFFF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

const ErrorIcon = styled(Error)(
  {
    flex: 'none',
    verticalAlign: 'middle'
  },
  compose(space, color, layout, typography)
);

Error.propTypes = {
  title: string
};

Error.defaultProps = {
  title: 'input error icon'
};

export default ErrorIcon;
