import React from 'react';
import styled, { css } from 'styled-components';
import { Box } from 'modules/ui/primitives/box';
import { Text } from 'modules/ui/primitives/text';
import PropTypes from 'prop-types';

const animatedContent = css`
  transition: opacity 250ms ease-in-out, transform 300ms ease-in-out;
  opacity: 0;
  transform-origin: top center;
  transform: translate3d(0, -15px, 0);
  z-index: 999;
  [data-enter] & {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const Wrapper = styled(Box)`
  ${animatedContent}
`;

export const TooltipContent = ({ title, children }) => {
  return (
    <Wrapper borderRadius="0" maxWidth="5" bg="text.secondary" p="3">
      {!title && children}
      {title && (
        <Text variant="caption" color="base.white" textAlign="left">
          {title}
        </Text>
      )}
    </Wrapper>
  );
};

TooltipContent.propTypes = {
  title: PropTypes.any,
  children: PropTypes.any
};
