import styled from 'styled-components';
import {
  compose,
  space,
  color,
  layout,
  position,
  border,
  shadow,
  background,
  variant
} from 'styled-system';

import PropTypes from 'prop-types';

const boxVariants = variant({
  variants: {
    'border-bottom': {
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'base.grey200'
    }
  }
});

export const Box = styled.div(
  boxVariants,
  {
    boxSizing: 'border-box',
    minWidth: 0
  },
  compose(space, color, layout, position, border, shadow, background)
);

Box.propTypes = {
  variant: PropTypes.oneOf(['border-bottom'])
};
