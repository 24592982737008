import React, { forwardRef } from 'react';
import ReactGa from 'react-ga';
import PropTypes from 'prop-types';

export const OutboundLink = forwardRef(
  ({ eventLabel, eventId, eventType, to, children, ...props }, ref) => {
    const trackClick = () => {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: eventLabel,
          actionId: eventId,
          actionType: eventType
        });
      }
    };

    return (
      <ReactGa.OutboundLink
        eventLabel={eventLabel}
        to={to}
        target="_blank"
        onClick={trackClick}
        ref={ref}
        {...props}
      >
        {children}
      </ReactGa.OutboundLink>
    );
  }
);

OutboundLink.propTypes = {
  eventId: PropTypes.string.isRequired,
  eventLabel: PropTypes.string.isRequired,
  eventType: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.any
};
