import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getPhoto, useGetProfile, useSubmitProfile } from 'modules/profile/api';
import { getQuestionsWithAnswers } from 'modules/onboarding';
import { isGoogleImage } from 'modules/core/utils';

const ProfileContext = React.createContext();

function getOnboardingStatus(profile) {
  const questionsWithAnswers = getQuestionsWithAnswers(profile);

  const totalQuestions = questionsWithAnswers.length;
  const doneQuestions = questionsWithAnswers.filter(q => !!q.answers).length;
  const nextQuestionLink = questionsWithAnswers.find(q => !q.answers)?.path;
  const completed = totalQuestions === doneQuestions;

  return {
    totalQuestions,
    doneQuestions,
    nextQuestionLink,
    completed,
    questionsWithAnswers
  };
}

const useGetProfileImage = profileImageUrl => {
  const [profileImageData, setProfileImageData] = useState();

  useEffect(() => {
    if (isGoogleImage(profileImageUrl)) {
      setProfileImageData(profileImageUrl);
      return;
    }

    if (!profileImageUrl && profileImageData) {
      setProfileImageData(null);
      return;
    }

    const fetchImage = async imageUrl => {
      const response = await getPhoto(imageUrl);
      if (response?.ok) {
        const imageData = await response.text();
        setProfileImageData(imageData);
      }
    };
    if (profileImageUrl && !profileImageData) {
      fetchImage(profileImageUrl);
    }
  }, [profileImageData, profileImageUrl]);

  return profileImageData;
};

export const ProfileProvider = ({ children }) => {
  const { data, error, isLoading, setData } = useGetProfile();
  const [
    submit,
    { submitData, submitError, isSubmitting /* , submitValidationErrors */ }
  ] = useSubmitProfile();

  const onboardingStatus = useMemo(() => data && getOnboardingStatus(data), [data]);

  const profileApiData = submitData || data;

  const profileImageData = useGetProfileImage(profileApiData?.profileImageUrl);

  const profileData = {
    ...profileApiData,
    ...(profileImageData ? { profileImage: profileImageData } : {}),
    ...(onboardingStatus && { onboarding: onboardingStatus })
  };

  return (
    <ProfileContext.Provider
      value={[
        {
          data: Object.keys(profileData).length && profileData,
          loadingError: error,
          submitError,
          isSubmitting,
          isLoading,
          setData
        },
        { submit }
      ]}
    >
      {children}
    </ProfileContext.Provider>
  );
};

ProfileProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export function useProfile() {
  const context = useContext(ProfileContext);

  if (!context)
    throw new Error(
      'useContext can only be called from a component wrrapped within a ProfileProvider.'
    );

  return context;
}
