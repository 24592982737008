import AdminRoutes from './admin/route';
import AuthRoutes from './auth/route';
import HomeRoutes from './home/route';
import ProfileRoutes from './profile/route';
import OnboardingRoutes from './onboarding/route';
import SharedRoutes from './shared/route';
import BallotGuideRoutes from './ballotGuide/route';
import ActionCenterRoutes from './actionCenter/route';
import ElectionCenterRoutes from './electionCenter/route';

export const publicRoutes = [...AuthRoutes, ...SharedRoutes];
export const privateRoutes = [
  ...AdminRoutes,
  ...HomeRoutes,
  ...ProfileRoutes,
  ...BallotGuideRoutes,
  ...OnboardingRoutes,
  ...ActionCenterRoutes,
  ...ElectionCenterRoutes,
  ...SharedRoutes
];
