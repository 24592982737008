import styled from 'styled-components';
import { focusRingDashed } from 'modules/ui/primitives/shared/focusRing';

export const StyledIcon = styled.a`
  outline: none;

  &:focus {
    ${focusRingDashed}
  }
`;
