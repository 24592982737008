import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

import { theme } from 'modules/ui/theme';
import { getStateCode } from 'modules/home/helpers';
import { Tooltip } from './tooltip';
import usGeoJson from './usStates.geo.json';

export const Map = ({ data }) => {
  const ref = React.createRef();
  const stateColor = theme.colors.base.disabled;
  const mapRatio = 0.45;

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const resizeWindow = () => {
    const svgElem = document.getElementById('us-map');

    if (svgElem) {
      const parentWidth = svgElem.parentElement.clientWidth;
      setWidth(parentWidth);
      setHeight(parentWidth * mapRatio);
    }
  };

  const drawMap = useCallback(() => {
    const projection = d3
      .geoAlbersUsa()
      .translate([width / 2, height / 2])
      .scale(width);

    const path = d3.geoPath().projection(projection);

    const svg = d3
      .select(ref.current)
      .select('svg')
      .attr('width', width)
      .attr('height', height);

    const tooltip = d3.select(ref.current).select('#state-tooltip');

    svg.selectAll('path').remove();

    svg
      .selectAll('path')
      .data(usGeoJson.features)
      .enter()
      .append('path')
      .attr('d', path)
      .style('stroke', '#fff')
      .style('stroke-width', '1')
      .style('fill', stateColor)
      .on('mousemove', function handleMouseMove(e, hoveredStateData) {
        const stateName = hoveredStateData.properties.name;
        const stateCode = getStateCode(stateName);
        const stateData = data.find(s => s.state === stateCode);

        d3.select(this).style('fill', theme.colors.action.orange);

        let html = '';
        html += '<p class="name">';
        html += stateName;
        html += '</p>';
        html += '<p class="value">';
        html += stateData ? stateData.count : 0;
        html += ' members</p>';

        const mouseLocation = d3.pointer(e, svg.node()).map(value => parseInt(value, 10));

        tooltip
          .classed('hidden', false)
          .attr('style', `left:${mouseLocation[0] + 15}px; top:${mouseLocation[1] - 35}px`)
          .html(html);
      })
      .on('mouseout', function handleMouseOut() {
        d3.select(this).style('fill', stateColor);
        tooltip.classed('hidden', true);
      });
  }, [data, height, ref, stateColor, width]);

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, [drawMap]);

  useEffect(() => {
    if (ref.current && width && height) {
      drawMap();
    }
  }, [data, ref, stateColor, drawMap, width, height]);

  return (
    <div ref={ref} style={{ position: 'relative', width: '100%' }}>
      <svg id="us-map" />
      <Tooltip id="state-tooltip" className="hidden" />
    </div>
  );
};

Map.propTypes = {
  data: PropTypes.array.isRequired
};
