/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { Box, Flex, Text, Button } from 'modules/ui/primitives';
import { Link } from 'modules/ui/primitives/link';
import { theme } from 'modules/ui/theme';

export const Navigation = ({ items, currentItem, type }) => {
  const { t } = useTranslation();

  if (!items || !currentItem) return null;

  const itemIndex = items.indexOf(currentItem);
  const isFirst = itemIndex === 0;
  const isLast = itemIndex === items.length - 1;

  return (
    <Flex
      pb={[3]}
      flexDirection={['row']}
      backgroundColor="base.white"
      justifyContent={['center', 'left']}
    >
      {isFirst ? (
        <Button
          pl="0"
          mr={1}
          variant="hollow-disabled"
          leftIcon={<ChevronLeft size="22" color={theme.colors.base.disabled} />}
        >
          <Text color="base.disabled">{t('ballotGuide.previous', 'Previous')}</Text>
        </Button>
      ) : (
        <Button
          mr={1}
          pl="0"
          variant="hollow"
          leftIcon={<ChevronLeft size="22" color={theme.colors.action.pink} />}
        >
          <Link to={`/ballot-guide/${items[itemIndex - 1]?.path}`} variant="brand">
            {t('ballotGuide.previous', 'Previous')}
          </Link>
        </Button>
      )}
      <Box
        style={{
          minWidth: '1px',
          borderRight: `1px solid ${theme.colors.base.disabled}`
        }}
      />
      {isLast ? (
        <Button
          ml={1}
          variant="hollow-disabled"
          rightIcon={<ChevronRight size="22" color={theme.colors.base.disabled} />}
        >
          <Text color="base.disabled">
            {t('ballotGuide.next', 'Next')} {type}
          </Text>
        </Button>
      ) : (
        <Button
          ml={1}
          variant="hollow"
          rightIcon={<ChevronRight size="22" color={theme.colors.action.pink} />}
        >
          <Link to={`/ballot-guide/${items[itemIndex + 1].path}`} variant="brand">
            {t('ballotGuide.next', 'Next')} {type}
          </Link>
        </Button>
      )}
    </Flex>
  );
};

Navigation.propTypes = {
  items: PropTypes.array,
  currentItem: PropTypes.any,
  type: PropTypes.string
};
