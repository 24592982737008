import React from 'react';
import { Redirect } from 'wouter';

const ActionCenterLayout = React.lazy(() => import('modules/actionCenter/layout'));
const ActionCenterScreen = React.lazy(() => import('modules/actionCenter/screens'));

export default [
  {
    name: 'Action center',
    path: '/action-center',
    component: () => <Redirect to="/member-center" />
  },
  {
    name: 'Action center',
    path: '/member-center',
    component: () => (
      <ActionCenterLayout>
        <ActionCenterScreen />
      </ActionCenterLayout>
    )
  }
];
