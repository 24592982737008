import styled from 'styled-components';
import { Text } from '../text';

export const Hint = styled(Text).attrs({
  variant: 'hint',
  color: 'text.secondary',
  minHeight: '18px' // avoid content shifting when there is empty div
})``;

export const Error = styled(Text).attrs({
  variant: 'hint',
  color: 'ui.error'
})``;
