import { useRoute } from 'wouter';
import { useProfile } from 'modules/profile';
import { useMemo } from 'react';

export const questionTypes = {
  selectOne: 'Select One',
  selectMultiple: 'Select Multiple',
  paragraph: 'Paragraph',
  dateField: 'Date Field',
  uploadImage: 'Upload image',
  zipCode: 'Zip Code'
};

const exclusive = true;
const custom = true;

const questions = [
  {
    key: 'zipCode',
    routeParam: 'zip-code',
    type: questionTypes.zipCode,
    label: 'Where is home for you?',
    tooltip:
      "We are committed to building a movement of women across the country. We ask your location to hold us accountable to that commitment and make sure we are building women's power in every state."
  },
  {
    key: 'birthday',
    routeParam: 'birthday',
    type: questionTypes.dateField,
    label: "What's your date of birth?",
    tooltip:
      "We're commited to building a multi-generational community. We ask members this question to help keep us accountable to our goal! (Also: we really want to know your sign.)"
  },
  {
    key: 'race',
    routeParam: 'race',
    type: questionTypes.selectMultiple,
    label: 'I am... (select all that apply)',
    answers: [
      {
        key: 'african-american-or-black',
        label: 'African American or Black'
      },
      {
        key: 'american-indian-or-alaska-native',
        label: 'American Indian or Alaska Native'
      },
      {
        key: 'asian',
        label: 'Asian'
      },
      {
        key: 'hispanic-or-latinix',
        label: 'Hispanic or Latinx'
      },
      {
        key: 'middle-eastern-or-north-african',
        label: 'Middle Eastern or North African'
      },
      {
        key: 'native-hawaiian-or-other-pacific-islander',
        label: 'Native Hawaiian or Other Pacific Islander'
      },
      {
        key: 'white',
        label: 'White'
      },
      {
        key: 'none',
        label: 'None of the above (please specify)',
        custom,
        exclusive
      },
      {
        key: 'rather-not-say',
        label: 'Rather not say',
        exclusive
      }
    ],
    tooltip:
      "We're a community for people of all backgrounds and races, centering women and non-binary people of color. This question helps ensure that we are truly building a multiracial movement for equality."
  },
  {
    key: 'gender',
    routeParam: 'gender',
    type: questionTypes.selectOne,
    label: 'What best describes your gender identity?',
    answers: [
      {
        key: 'cisgender-man',
        label: 'Cisgender man'
      },
      {
        key: 'cisgender-woman',
        label: 'Cisgender woman'
      },
      {
        key: 'nonbinary',
        label: 'Non-binary/Gender non-conforming'
      },
      {
        key: 'transgender-man',
        label: 'Transgender man'
      },
      {
        key: 'transgender-woman',
        label: 'Transgender woman'
      },
      {
        key: 'other',
        label: 'Other (please specify)',
        custom
      },
      {
        key: 'prefer-not-say',
        label: 'Prefer not to say'
      }
    ],
    tooltip:
      'Supermajority welcomes people of all genders. We ask your this so we can accurately describe our community and ensure we are being gender expansive.'
  },
  {
    key: 'pronouns',
    routeParam: 'pronouns',
    type: questionTypes.selectMultiple,
    label: 'What pronouns do you use? (select all that apply)',
    answers: [
      {
        key: 'she/her',
        label: 'She/her'
      },
      {
        key: 'he/him',
        label: 'He/Him'
      },
      {
        key: 'they/them',
        label: 'They/Them'
      },
      {
        key: 'other',
        label: 'Other (please specify)',
        custom
      },
      {
        key: 'just-name',
        label: 'Just my name, please'
      }
    ],
    tooltip:
      'Pronouns are how we refer to people without using their name. Just like we ask your name, we ask your pronouns so we can respect your identity.'
  },
  {
    key: 'broadActivismExperience',
    routeParam: 'broad-activism-experience',
    type: questionTypes.selectOne,
    label: 'Do you consider yourself to be an activist?',
    answers: [
      { key: 'new', label: "Not quite yet, but that's why I'm here!" },
      { key: 'occasionally', label: 'Sort of, trying to find my lanes... ' },
      {
        key: 'consistently',
        label: "Yes, let's gooooo!"
      }
    ],
    tooltip:
      "We think there are so many women doing work in their communities who are already activists and organizers – they just might not see themselves that way. So we're curious, where do you see yourself today?We want to understand the general experience level of our members and provide opportunities for member-to-member mentorship and networking."
  },
  {
    key: 'importantIssue',
    routeParam: 'important-issue',
    type: questionTypes.paragraph,
    label:
      "What's one issue keeping you up at night? (Or two or five... we want a sense of what's top of mind!)",
    tooltip:
      'Supermajority is building a multi-issue platform to advance gender equality. What matters to our community most helps inform our priorities.'
  },
  {
    key: 'whatBringsYouJoy',
    routeParam: 'what-brings-you-joy',
    type: questionTypes.paragraph,
    label: 'Lastly, what is something that brings you JOY?',
    tooltip:
      "We believe joy can connect us, inspire us, and sustain us. We're so excited for the energy AND the joy you're bringing to the Supermajority community!"
  },
  {
    key: 'tellUsMore',
    routeParam: 'tell-us-more',
    type: questionTypes.paragraph,
    label: "Is there anything else you'd like to share with us?",
    tooltip: "Got more to tell us? We're listening!"
  },
  {
    key: 'profileImageUrl',
    routeParam: 'upload-profile-picture',
    type: questionTypes.uploadImage,
    label: 'Add profile photo',
    tooltip:
      'This profile picture will be used to represent you throughout your logged in experience at Supermajority. We want to see all the beautiful faces of our community members!',
    alignment: 'center'
  }
];

export default questions;

function getQuestionPath(routeParam) {
  return `/onboarding/${routeParam}`;
}

export function useQuestion() {
  const [, params] = useRoute(getQuestionPath(':questionRouteParam'));
  const question = questions.find(q => q.routeParam === params?.questionRouteParam);

  if (!question) throw new Error('useQuestion can only be called on a question route.');

  const index = questions.indexOf(question);

  const next =
    index < questions.length - 1 ? getQuestionPath(questions[index + 1].routeParam) : null;
  const previous = index > 0 ? getQuestionPath(questions[index - 1].routeParam) : null;

  return {
    question,
    previous,
    next,
    orderNumber: index + 1,
    totalQuestions: questions.length
  };
}

export function firstQuestionPath() {
  return getQuestionPath(questions[0].routeParam);
}

const getProfileAnswer = (profile, question) => {
  if (question.type === questionTypes.zipCode && profile.liveAbroad) {
    return 'Live abroad';
  }

  return profile[question.key];
};

export function getQuestionsWithAnswers(profile) {
  if (!profile) return [];

  const questionsWithAnswers = questions.map(question => {
    const profileAnswer = getProfileAnswer(profile, question);
    let answers = null;
    if (profileAnswer !== null) {
      answers = Array.isArray(profileAnswer)
        ? profileAnswer.map(
            answerKey => question.answers.find(a => a.key === answerKey)?.label || answerKey
          )
        : [profileAnswer];
    }
    return {
      key: question.key,
      path: getQuestionPath(question.routeParam),
      label: question.label,
      answers,
      rawAnswers: question.answers
    };
  });
  return questionsWithAnswers;
}

export function useGetQuestionsWithAnswers() {
  const [{ data: profile, isLoading, loadingError }] = useProfile();
  return [useMemo(() => getQuestionsWithAnswers(profile), [profile]), isLoading, loadingError];
}
