import React from 'react';
import { useTranslation } from 'react-i18next';
import { DismissibleBanner } from 'modules/shared/components/dismissibleBanner';
import { HIDE_BALLOT_GUIDE_BANNER } from 'modules/shared/constants';
import { Text } from 'modules/ui/primitives';

export const IncompleteInfoBanner = () => {
  const { t } = useTranslation();

  return (
    <DismissibleBanner bg="base.grey100" localStorageKey={HIDE_BALLOT_GUIDE_BANNER}>
      <Text variant="body-1" color="text.secondary">
        {t(
          'ballotGuide.incompleteInfoBanner',
          'If your ballot guide seems incomplete, we may still be waiting for all the information to come through for your area. We’ll update the guide as soon as we have it'
        )}
      </Text>
    </DismissibleBanner>
  );
};
