import styled from 'styled-components';

export const generateTooltipHtml = (x, y, formatLabel) => {
  let html = '';
  html += '<p class="name">';
  html += formatLabel ? formatLabel(x) : x;
  html += '</p>';
  html += '<p class="value">';
  html += y;
  html += ' members</p>';

  return html;
};

export const Tooltip = styled.div`
  position: absolute;
  display: block;

  padding: ${props => props.theme.space[2]}px;

  background: #4a5259;
  border-radius: 4px;

  color: #fff;

  z-index: 100;

  white-space: nowrap;

  &.hidden {
    display: none;
  }

  .name {
    display: block;
    font-weight: 700;
  }
`;
