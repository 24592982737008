import React, { useCallback, useState, useEffect } from 'react';
import { useDialogState } from 'reakit';
import { useTranslation } from 'react-i18next';
import { Archive, Search, Bookmark } from 'react-feather';
import styled from 'styled-components';

import { useAuth } from 'modules/auth/useAuth';
import { useProfile } from 'modules/profile/profileProvider';
import { HIDE_BALLOT_WELCOME } from 'modules/shared/constants';
import { Button, Flex, Text, Heading, FormControl, Input, Label } from 'modules/ui/primitives';
import { Dialog } from 'modules/ui/components/dialog';
import { theme } from 'modules/ui/theme';
import { triggerGtmEvent } from 'modules/shared/services';

const IconWrap = styled(Flex)`
  background-color: rgba(255, 78, 167, 0.05);
  height: 56px;
  border-radius: 50%;
  padding: 16px;
  position: relative;
  flex: none;
`;

const IconDummy = styled(Flex)`
  padding-left: 56px;
`;

const iconProps = {
  width: '24px',
  height: '24px',
  position: 'absolute',
  top: '16px',
  left: '16px',
  color: theme.colors.action.pink
};

export const WelcomeToBallot = () => {
  const { t } = useTranslation();
  const [{ data: profileData }, { submit: saveProfile }] = useProfile();
  const { user } = useAuth();
  const { votingAddress, votingCity, votingState, votingZipCode, ballotAddress } = user;
  const [address, setAddress] = useState(ballotAddress);
  const [addressError, setAddressError] = useState();

  const hidden = Boolean(window.localStorage.getItem(HIDE_BALLOT_WELCOME) || false) && !!address;
  const dialog = useDialogState({
    animated: true,
    visible: !hidden,
    modal: false,
    unstable_orphan: true
  });

  const goToActionsPage = useCallback(() => {
    window.localStorage.setItem(HIDE_BALLOT_WELCOME, 'true');
    triggerGtmEvent('BallotGuide', 'Click', "Let's Go");
    dialog.setVisible(() => false);
    window.location.assign('/ballot-guide');
  }, [dialog]);

  useEffect(() => {
    if (!address && !ballotAddress && votingAddress && votingCity && votingState && votingZipCode) {
      setAddress(`${votingAddress}, ${votingCity}, ${votingState} ${votingZipCode}`);
    }

    if (!address && ballotAddress) {
      setAddress(ballotAddress);
    }
  }, [address, votingAddress, votingCity, votingState, votingZipCode, ballotAddress]);

  const submitBallotAddress = async () => {
    if (!address || address.length < 2) {
      setAddressError(t('election.errorAddress', 'Address is required'));
      return;
    }

    const submitData = {
      ballotAddress: address,
      user: { id: profileData?.user?.id }
    };

    await saveProfile(submitData);

    goToActionsPage();
  };

  return (
    <Dialog
      maxwidth="850px"
      borderradius={0}
      {...dialog}
      title={t(
        'ballotGuide.welcome',
        "You're voting for the next president — and a lot more. See who and what's on your ballot!"
      )}
      id="welcome-to-ballot-dialog"
      contentPadding={[4, 6, 6]}
      hideCloseButton
      hideOnClickOutside={false}
    >
      <Heading variant="h2" mb="6">
        {t(
          'ballotGuide.welcome',
          "You're voting for the next president — and a lot more. See who and what's on your ballot!"
        )}
      </Heading>
      <Flex width="100%" flexDirection="column" mb="5">
        <Flex flexDirection={['row']}>
          <IconWrap className="hidden-mobile">
            <Search {...iconProps} />
          </IconWrap>
          <Flex flexDirection="column" px={[0, 4]}>
            <Heading variant="h3" mb="1">
              {t('ballotGuide.explore', 'Explore')}
            </Heading>
            <Text mb="4" variant="body-2">
              {t(
                'ballotGuide.exploreText',
                'Get to know the candidates on your ballot, including their background and track record on issues you care about.'
              )}
            </Text>
          </Flex>
        </Flex>
        <Flex flexDirection={['row']}>
          <IconWrap className="hidden-mobile">
            <Bookmark {...iconProps} />
          </IconWrap>
          <Flex flexDirection="column" px={[0, 4]}>
            <Heading variant="h3" mb="1">
              {t('ballotGuide.build', 'Build')}
            </Heading>
            <Text mb="4" variant="body-2">
              {t(
                'ballotGuide.buildText',
                'Mark your favorite candidates for local, state, federal, and presidential races and save as you go!'
              )}
            </Text>
          </Flex>
        </Flex>
        <Flex flexDirection={['row']}>
          <IconWrap className="hidden-mobile">
            <Archive {...iconProps} />
          </IconWrap>
          <Flex flexDirection="column" px={[0, 4]}>
            <Heading variant="h3" mb="1">
              {t('ballotGuide.save', 'Save')}
            </Heading>
            <Text variant="body-2">
              {t(
                'ballotGuide.saveText',
                'Be prepared at the polls (even if the "polls" are a mail ballot delivered to your door) by keeping your voter guide handy.'
              )}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex width="100%" flexDirection={['row']} mb={5}>
        <IconDummy className="hidden-mobile" />
        <Flex width="100%" px={[0, 4]}>
          <FormControl.Field width="100%">
            <Flex>
              <Label htmlFor="address" required>
                {t('election.address', 'Address')}
              </Label>
            </Flex>
            <Input
              defaultValue={address}
              name="address"
              id="address"
              autocomplete="street-address"
              aria-describedby={t('election.address', 'Address')}
              placeholder={t('election.address', 'Address')}
              onChange={e => setAddress(e.target.value)}
            />
            <FormControl.Error id="error-text-address">{addressError}</FormControl.Error>
          </FormControl.Field>
        </Flex>
      </Flex>
      <Button width="100%" mb="1" onClick={submitBallotAddress} borderRadius={0}>
        {t('home.letsGo', "Let's go")}
      </Button>
    </Dialog>
  );
};
