import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { ToastProvider } from 'modules/shared/components/toast';
import { ApiProvider } from 'jsonapi-react';

import { ErrorBoundary } from 'modules/shared/components';
import { apiClient } from 'modules/core/apiClient';
import { theme } from 'modules/ui/theme';
import { AuthorizationProvider } from 'modules/auth/useAuth';
import { ConfigProvider as AntdConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import esEs from 'antd/es/locale/es_ES';

import App from './App';
import * as serviceWorker from './serviceWorker';

import './minireset.min.css';
import i18n from './i18n';
import 'moment/locale/es';

ReactDOM.render(
  <React.StrictMode>
    <AntdConfigProvider locale={i18n.language === 'en' ? enUS : esEs}>
      <ThemeProvider theme={theme}>
        <ToastProvider>
          <ErrorBoundary>
            <ApiProvider client={apiClient}>
              <AuthorizationProvider>
                <App />
              </AuthorizationProvider>
            </ApiProvider>
          </ErrorBoundary>
        </ToastProvider>
      </ThemeProvider>
    </AntdConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
