import React, { useState, useRef, useCallback, useMemo } from 'react';
import { FormControl } from 'modules/ui/primitives/formControl';
import { Label } from 'modules/ui/primitives/label';
import { Flex } from 'modules/ui/primitives';
import { Button } from 'modules/ui/primitives/button';
import { Input } from 'modules/ui/primitives/input';
import { useTranslation } from 'react-i18next';
import { Icons } from 'modules/ui/assets';
import { triggerGtmEvent } from 'modules/shared/services';
import { useProfile } from 'modules/profile';
import FacebookShareLink from 'modules/shared/components/mediaShare/facebookShareLink';
import MessengerShareLink from 'modules/shared/components/mediaShare/messengerShareLink';
import TwitterShareLink from 'modules/shared/components/mediaShare/twitterShareLink';
import FacebookButton from '../assets/images/Facebook.svg';
import MessengerButton from '../assets/images/Messenger.svg';
import TwitterButton from '../assets/images/Twitter.svg';

export function CopyInviteLink() {
  const [{ data: profileData }] = useProfile();
  const referralCode = profileData?.user?.referralCode;
  const referralInput = useRef(null);
  const { t } = useTranslation();

  const [linkCopiedSuccess, setLinkCopiedSuccess] = useState();

  const InputStatusIcon = useMemo(() => {
    switch (linkCopiedSuccess) {
      case true:
        return <Icons.CheckCircle aria-hidden color="ui.success" />;
      case false:
        return <Icons.Error aria-hidden color="ui.error" />;
      default:
        return null;
    }
  }, [linkCopiedSuccess]);

  const handleCopyLink = useCallback(e => {
    e.preventDefault();
    referralInput.current.select();
    const success = document.execCommand('copy');
    setLinkCopiedSuccess(success);
    triggerGtmEvent('Friend', 'Invite', 'Invite by link');
  }, []);

  const inviteURL = `${process.env.REACT_APP_WEB_BASE_URL}/auth/signup?ref=${referralCode}`;

  return referralCode ? (
    <FormControl.Field>
      <Label htmlFor="share-link">
        {t('profile.inviteFriend.shareLink', 'Share your invite link')}
      </Label>
      <Flex mb={3}>
        <Input
          id="share-link"
          name="share-link"
          ref={referralInput}
          readOnly
          error={linkCopiedSuccess === false}
          success={linkCopiedSuccess === true}
          defaultValue={inviteURL}
          rightIcon={InputStatusIcon}
          pr={5}
        />
        <Button ml={1} id="copy-button" variant="link" onClick={handleCopyLink}>
          {t('profile.inviteFriend.copyLink', 'Copy link')}
        </Button>
      </Flex>
      <Flex justifyContent="space-around">
        <FacebookShareLink
          url={inviteURL}
          onClick={() => triggerGtmEvent('Friend', 'Share', 'Facebook')}
        >
          <img src={FacebookButton} alt="Share on Facebook" />
        </FacebookShareLink>
        <MessengerShareLink
          url={inviteURL}
          redirectUri={window.location.href}
          onClick={() => triggerGtmEvent('Friend', 'Share', 'Messenger')}
        >
          <img src={MessengerButton} alt="Share on Messanger" />
        </MessengerShareLink>
        <TwitterShareLink
          url={inviteURL}
          external
          onClick={() => triggerGtmEvent('Friend', 'Share', 'Twitter')}
        >
          <img src={TwitterButton} alt="Share on Twitter" />
        </TwitterShareLink>
      </Flex>
    </FormControl.Field>
  ) : null;
}
