import { useEffect, useState } from 'react';

export const useScrolling = ref => {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    if (ref.current) {
      let scrollingTimeout;

      const handleScrollEnd = () => {
        setScrolling(false);
      };

      const handleScroll = () => {
        setScrolling(true);
        clearTimeout(scrollingTimeout);
        scrollingTimeout = setTimeout(() => handleScrollEnd(), 150);
      };

      ref.current.addEventListener('scroll', handleScroll, false);
      return () => {
        if (ref.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          ref.current.removeEventListener('scroll', handleScroll, false);
        }
      };
    }
    return () => {};
  }, [ref]);

  return scrolling;
};
