import React from 'react';
import { Facebook, Twitter, Linkedin, Instagram, Link2 } from 'react-feather';
import { Link } from 'modules/ui/primitives/link';
import { theme } from 'modules/ui/theme';

const socialType = {
  FACEBOOK: 'facebook',
  GOVERNMENT: 'government',
  INSTAGRAM: 'instagram',
  LINKEDIN: 'linkedin',
  TWITTER: 'twitter',
  WEBSITE: 'website'
};

export const Social = ({ type, url }) => {
  let link;
  switch (type) {
    case socialType.FACEBOOK:
      link = (
        <Link to={url} external mr="3">
          <Facebook title={url} color={theme.colors.text.secondary} size={24} />
        </Link>
      );
      break;

    case socialType.LINKEDIN:
      link = (
        <Link to={url} external mr="3">
          <Linkedin title={url} color={theme.colors.text.secondary} size={24} />
        </Link>
      );
      break;

    case socialType.INSTAGRAM:
      link = (
        <Link to={url} external mr="3">
          <Instagram title={url} color={theme.colors.text.secondary} size={24} />
        </Link>
      );
      break;

    case socialType.TWITTER:
      link = (
        <Link to={url} external mr="3">
          <Twitter title={url} color={theme.colors.text.secondary} size={24} />
        </Link>
      );
      break;

    case socialType.WEBSITE:
      link = (
        <Link to={url} external mr="3">
          <Link2 title={url} color={theme.colors.text.secondary} size={24} />
        </Link>
      );
      break;
    default:
      return null;
  }
  return link;
};
