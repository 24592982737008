import { apiClient } from 'modules/core/apiClient';
import { useClient, useMutation, useQuery } from 'jsonapi-react';
import { schema } from 'modules/core/schema';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { httpMethods } from 'modules/core/constants';
import { isGoogleImage } from 'modules/core/utils';

export const sendInvites = async inviteeEmails => {
  const promises = inviteeEmails.map(async email => {
    const { error } = await apiClient.mutate(schema['member-invites'].endpoint, {
      inviteeEmail: email
    });
    return { email, error };
  });

  return Promise.all(promises);
};

export function useGetProfile() {
  return useQuery([`${schema['user-profiles'].endpoint}`, { include: 'user' }], {
    // cacheTime: 10 * 60,
    // staleTime: 5 * 60
  });
}

export function useDeactivateAccount() {
  const [deactivate] = useMutation(schema.deactivate.endpoint, {
    method: 'DELETE'
  });

  return deactivate;
}

export function useSubmitProfile() {
  const [submit, { isLoading, data, error, errors }] = useMutation(
    [`${schema['user-profiles'].endpoint}`, { include: 'user' }],
    {
      method: 'PATCH'
    }
  );

  return [
    submit,
    {
      submitData: data,
      submitError: error,
      isSubmitting: isLoading,
      submitValidationErrors: errors
    }
  ];
}

export function useSubmitLanguage() {
  const [submit, { isLoading, error }] = useMutation([`${schema['user-settings'].endpoint}`], {
    method: 'PATCH'
  });

  return [
    useCallback(lang => submit({ language: lang }), [submit]),
    {
      error,
      isSubmitting: isLoading
    }
  ];
}

export function useGenerateUrl() {
  const [generateUrl, { isLoading, data, error }] = useMutation(schema['generate-url'].endpoint, {
    method: 'POST',
    _type: 'asset-actions'
  });

  return [
    generateUrl,
    {
      submitData: data,
      submitError: error,
      isSubmitting: isLoading
    }
  ];
}

export const uploadPhoto = async (url, photo) =>
  fetch(url, {
    method: httpMethods.PUT,
    body: photo
  });

export const getPhoto = async url => {
  const response = await fetch(url, {
    method: httpMethods.GET
  });
  return response;
};

export const deletePhoto = async url => {
  const response = await fetch(url, {
    method: httpMethods.DELETE
  });
  return response;
};

const getInvitedPeopleProfileImages = invitedPeopleData => {
  const dataWithProfileImagesPromises = invitedPeopleData.map(async userData => {
    const { profileImageUrl, ...userDataWithoutProfileImageUrl } = userData;

    if (isGoogleImage(profileImageUrl)) {
      return { ...userDataWithoutProfileImageUrl, profileImage: profileImageUrl };
    }

    if (profileImageUrl) {
      const profileImageResponse = await getPhoto(profileImageUrl);
      if (profileImageResponse?.ok) {
        const profileImage = await profileImageResponse.text();
        return { ...userDataWithoutProfileImageUrl, profileImage };
      }
      return userDataWithoutProfileImageUrl;
    }
    return userDataWithoutProfileImageUrl;
  });

  return Promise.all(dataWithProfileImagesPromises);
};

export const useGetInvitedPeople = (pageSize = 10) => {
  const [invitedPeopleData, setInvitedPeopleData] = useState({
    page: 0,
    total: 0,
    loading: false,
    invitedPeople: []
  });
  const client = useClient();

  const fetchMoreInvitedPeople = useCallback(async () => {
    if (invitedPeopleData.loading) return;
    if (
      invitedPeopleData.page > 0 &&
      invitedPeopleData.invitedPeople.length === invitedPeopleData.total
    )
      return;

    const nextPage = invitedPeopleData.page + 1;

    setInvitedPeopleData(currentData => ({ ...currentData, loading: true }));

    const { data, meta } = await client.fetch([
      schema['invited-friends'].endpoint,
      {
        page: {
          offset: invitedPeopleData.page * pageSize,
          limit: pageSize
        }
      }
    ]);

    if (!data?.length) {
      setInvitedPeopleData(currentData => ({ ...currentData, loading: false }));
      return;
    }

    const dataWithProfileImages = await getInvitedPeopleProfileImages(data);

    setInvitedPeopleData(currentData => {
      const { totalCount } = meta;
      return {
        ...currentData,
        loading: false,
        page: nextPage,
        total: totalCount,
        invitedPeople: [...currentData.invitedPeople, ...dataWithProfileImages]
      };
    });
  }, [
    client,
    invitedPeopleData.invitedPeople.length,
    invitedPeopleData.loading,
    invitedPeopleData.page,
    invitedPeopleData.total,
    pageSize
  ]);

  useEffect(() => {
    fetchMoreInvitedPeople();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const returnData = useMemo(() => {
    const moreToLoad = invitedPeopleData.total - invitedPeopleData.invitedPeople.length;
    const moreToShow = moreToLoad > pageSize ? pageSize : moreToLoad;

    return {
      loading: invitedPeopleData.loading,
      invited: invitedPeopleData.invitedPeople,
      moreToShow,
      total: invitedPeopleData.total
    };
  }, [
    invitedPeopleData.invitedPeople,
    invitedPeopleData.loading,
    invitedPeopleData.total,
    pageSize
  ]);

  return [returnData, fetchMoreInvitedPeople];
};
