import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'modules/ui/primitives';
import objectToURLParams from 'modules/core/utils';

const messangerShareURL = 'https://www.facebook.com/dialog/send';

/**
 * @param {string} url The url to share. Required
 * @param {string} appId Your app's unique identifier.
 * @param {string} redirectUri The URL to redirect to after a person clicks a button on the dialog.
 * Required when using URL redirection.
 */
export default function MessengerShareLink({ url, appId, redirectUri, to, onClick, children }) {
  const toURL = `${messangerShareURL}${objectToURLParams({
    link: url,
    redirect_uri: redirectUri,
    app_id: appId,
    to
  })}`;

  return (
    <Link to={toURL} external onClick={onClick}>
      {children}
    </Link>
  );
}

MessengerShareLink.propTypes = {
  url: PropTypes.string.isRequired,
  appId: PropTypes.number,
  redirectUri: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any
};

MessengerShareLink.defaultProps = {
  appId: 229311334564122
};
