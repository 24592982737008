import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { compose, space, layout, flexbox, border, position, color, variant } from 'styled-system';
import MaskedInput from 'react-input-mask';
import PropTypes from 'prop-types';
import { Box } from '../box';
import { Flex } from '../flex';
import { Text } from '../text';
import { focusRingInput } from '../shared/focusRing';

const inputTokens = {
  iconSize: 2, // icon size on font-size scale
  y: '11px', // padding y
  x: 2 // padding x
};

const areaVariants = variant({
  inline: {
    borderRadius: '0'
  }
});

const defaultContentAreaProps = {
  pt: inputTokens.y,
  pb: inputTokens.y,
  borderWidth: '2px',
  borderStyle: 'solid',
  borderRadius: 0,
  color: 'text.primary',
  fontSize: 3,
  bg: 'base.grey100',
  lineHeight: 3,
  mb: 0,
  maxHeight: '48px',
  hasLeftIcon: false,
  hasRightIcon: false
};

const ContentArea = styled(Text).withConfig({
  shouldForwardProp: prop => {
    return !Object.keys(defaultContentAreaProps)
      .concat(['error', 'success'])
      .includes(prop);
  }
})`
  display: block;
  width: 100%;
  appearance: none;

  color: ${props => props.theme.colors.text.primary};

  padding-left: ${props =>
    props.hasLeftIcon
      ? `calc(${props.theme.fontSizes[inputTokens.iconSize]} + ${2 *
          props.theme.space[inputTokens.x]}px)`
      : `${props.theme.space[inputTokens.x]}px`};

  padding-right: ${props =>
    props.hasRightIcon
      ? inputTokens.iconSize + 4 * props.theme.space[inputTokens.x]
      : props.theme.space[inputTokens.x]}px;

  border-color: ${props =>
    // eslint-disable-next-line no-nested-ternary
    props.error
      ? props.theme.colors.ui.error
      : props.success
      ? props.theme.colors.ui.success
      : props.theme.colors.ui.transparent};

  &::placeholder {
    color: ${props => props.theme.colors.text.disabled};
  }

  &:hover {
    border-color: ${props =>
      props.error ? props.theme.colors.ui.error : props.theme.colors.action.pink};
  }

  &:focus {
    ${focusRingInput};
    background-color: ${props => props.theme.colors.base.white};
    border-color: ${props => props.theme.colors.action.pink};

    &::placeholder {
      color: transparent;
    }

    & > * {
      color: white;
    }
  }

  &:-moz-read-only {
    background-color: ${props => props.theme.colors.base.grey200};
    border-color: ${props => props.theme.colors.base.grey200};
  }

  &:read-only {
    background-color: ${props => props.theme.colors.base.grey200};
    border-color: ${props => props.theme.colors.base.grey200};

    &::placeholder {
      color: ${props => props.theme.colors.text.secondary};
    }
  }

  &:disabled {
    -webkit-text-fill-color: currentColor; /* set text fill to current color for safari */
    opacity: 1; /* correct opacity on iOS */
    color: ${props => props.theme.colors.text.secondary};
    background-color: ${props => props.theme.colors.base.grey200};
    border-color: ${props => props.theme.colors.base.grey200};

    &::placeholder {
      color: ${props => props.theme.colors.text.disabled};
      opacity: 1;
    }
  }
  ${compose(space, layout, flexbox, border, position, color)}
  ${areaVariants}
`;

ContentArea.defaultProps = defaultContentAreaProps;

const LeftIcon = styled(Box)`
  position: absolute;

  svg {
    display: block;
    font-size: ${props => props.theme.fontSizes[2]};
    color: ${props =>
      props.disabled ? props.theme.colors.text.disabled : props.theme.colors.text.primary};
  }
`;

const RightIcon = styled(Box)`
  position: absolute;
  cursor: pointer;

  svg {
    display: block;
    font-size: ${props => props.theme.fontSizes[2]};
    ${props => props.disabled && { color: props.theme.colors.text.disabled }};
  }
`;

export const maskedInputMasks = {
  date: '99/99/9999'
};

export const Input = forwardRef(
  (
    {
      leftIcon,
      rightIcon,
      mb,
      mt,
      mx,
      my,
      ml,
      mr,
      disabled,
      maskedInput,
      dateFormat,
      maxLength,
      ...props
    },
    ref
  ) => {
    const [characterCount, setCharacterCount] = useState(props.value ? props.value.length : 0);
    return (
      <>
        <Flex
          alignItems="center"
          position="relative"
          mx={mx}
          my={my}
          mb={mb}
          mt={mt}
          ml={ml}
          mr={mr}
          flex="1"
        >
          {leftIcon && (
            <LeftIcon left={inputTokens.x} disabled={disabled}>
              {leftIcon}
            </LeftIcon>
          )}
          {maskedInput ? (
            <MaskedInput
              mask={maskedInput.mask}
              maskPlaceholder={maskedInput.maskPlaceholder || maskedInput.mask}
              disabled={disabled}
              inputRef={ref}
            >
              <ContentArea
                as="input"
                fontWeight="300"
                py={2}
                aria-invalid={props.error ? 'true' : 'false'}
                ref={ref}
                maxLength={maxLength}
                {...{ placeholder: maskedInput.maskPlaceholder || maskedInput.mask }}
                {...props}
              />
            </MaskedInput>
          ) : (
            <ContentArea
              as="input"
              fontWeight="300"
              ref={ref}
              py={2}
              hasLeftIcon={!!leftIcon}
              hasRightIcon={!!rightIcon}
              disabled={disabled}
              aria-invalid={props.error ? 'true' : 'false'}
              maxLength={maxLength}
              onChange={e => {
                const newValue = e.target.value;
                setCharacterCount(newValue.length);
                return props.onChange ? props.onChange(e) : null;
              }}
              {...props}
            />
          )}

          {rightIcon && (
            <RightIcon right={inputTokens.x} disabled={disabled}>
              {rightIcon}
            </RightIcon>
          )}
        </Flex>
        {maxLength && (
          <Flex width="100%" justifyContent="flex-end">
            <Text variant="caption" color="text.secondary">
              {characterCount}/{maxLength}
            </Text>
          </Flex>
        )}
      </>
    );
  }
);

Input.propTypes = {
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  mb: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  mt: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  mx: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  my: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  ml: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  mr: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  type: PropTypes.string,
  maskedInput: PropTypes.shape({
    mask: PropTypes.string.isRequired,
    maskPlaceholder: PropTypes.string.isRequired
  }),
  dateFormat: PropTypes.string,
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  value: PropTypes.string
};

Input.defaultProps = { error: false, success: false, type: 'text' };
