import Loading from './loading';
import Question from './question';
import Close from './close';
import Search from './search';
import Info from './info';
import CheckCircle from './checkCircle';
import Visibility from './visibility';
import VisibilityOff from './visibilityOff';
import Error from './error';
import RadioChecked from './radioChecked';
import RadioUnchecked from './radioUnchecked';
import CheckboxChecked from './checkboxChecked';
import CheckboxBlank from './checkboxBlank';
import Place from './place';
import ExpandMore from './expandMore';
import Twitter from './twitter';
import Facebook from './facebook';
import Instagram from './instagram';
import PencilEdit from './pencil-edit';
import PlusSign from './plus-sign';
import PolygonUp from './polygonUp';

export const Icons = {
  Loading,
  Question,
  Close,
  Search,
  Info,
  CheckCircle,
  Visibility,
  VisibilityOff,
  Error,
  RadioChecked,
  RadioUnchecked,
  CheckboxChecked,
  CheckboxBlank,
  Place,
  ExpandMore,
  Twitter,
  Facebook,
  Instagram,
  PencilEdit,
  PlusSign,
  PolygonUp
};
