import React, { forwardRef } from 'react';
import styled from 'styled-components';
import {
  compose,
  space,
  layout,
  flexbox,
  position,
  border,
  color,
  typography
} from 'styled-system';
import PropTypes from 'prop-types';
import { Box } from '../box';
import { Flex } from '../flex';
import { Spinner } from '../spinner';
import { buttonVariants } from './shared/variants';
import { focusRingButton } from '../shared/focusRing';

const ButtonIcon = styled(Box)`
  display: inline-block;
  width: ${props => props.theme.fontSizes[4]};
  height: ${props => props.theme.fontSizes[4]};
  svg {
    position: absolute;
    display: block;
    font-size: ${props => props.theme.fontSizes[4]};
  }
`;

const StyledButton = styled.button`
  ${buttonVariants}
  ${compose(space, layout, flexbox, border, position, color, typography)}
  &:focus {
    ${focusRingButton}
  }
`;

export const Button = forwardRef(
  (
    { leftIcon, rightIcon, disabled, isLoading, onClick, children, mr, ml, spinnerColor, ...props },
    ref
  ) => (
    <StyledButton
      ref={ref}
      disabled={disabled || isLoading}
      isLoading={isLoading}
      onClick={!disabled && !isLoading ? onClick : undefined}
      ml={ml}
      {...props}
      {...(isLoading ? { 'aria-label': 'loading' } : {})}
    >
      <Flex alignItems="center" position="relative" justifyContent="center">
        {leftIcon && (
          <ButtonIcon disabled={disabled} mr={mr || 2}>
            {leftIcon}
          </ButtonIcon>
        )}
        {children}
        {rightIcon && (
          <ButtonIcon disabled={disabled} ml={ml || 2}>
            {rightIcon}
          </ButtonIcon>
        )}
        {isLoading && <Spinner color={spinnerColor || 'base.white'} size={2} ml={2} />}
      </Flex>
    </StyledButton>
  )
);

Button.propTypes = {
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'outline',
    'inline',
    'hollow',
    'ihollow',
    'chip',
    'chip-grey',
    'hollow-grey',
    'light',
    'light-primary',
    'hollow-disabled',
    'link',
    'hollow-grey-border'
  ]),
  isLoading: PropTypes.bool,
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  leftIcon: PropTypes.any,
  rightIcon: PropTypes.any,
  disabled: PropTypes.bool,
  mr: PropTypes.any,
  ml: PropTypes.any,
  spinnerColor: PropTypes.string
};

Button.defaultProps = {
  disabled: false,
  variant: 'primary',
  onClick: undefined
};
