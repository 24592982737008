import styled from 'styled-components';
import { compose, color, typography, layout, variant, space, system } from 'styled-system';
import PropTypes from 'prop-types';

const defaultextStyles = {
  fontFamily: 'body',
  fontWeight: 'regular',
  lineHeight: 0,
  color: 'text.primary',
  mt: 0,
  mb: 0
};

const textVariants = variant({
  variants: {
    hint: {
      ...defaultextStyles,
      fontSize: 0,
      lineHeight: 0
    },
    caption: {
      ...defaultextStyles,
      fontSize: 1,
      lineHeight: 1
    },
    'body-1': {
      ...defaultextStyles,
      fontSize: 2,
      lineHeight: 2
    },
    'body-semibold': {
      ...defaultextStyles,
      fontWeight: 'medium',
      color: 'text.primary',
      fontSize: [2, 3],
      lineHeight: [2, 3]
    },
    'body-lightMedium': {
      ...defaultextStyles,
      fontWeight: 'lightMedium',
      color: 'text.primary',
      fontSize: 3,
      lineHeight: 3
    },
    'body-lightMedium-1': {
      ...defaultextStyles,
      fontWeight: 'lightMedium',
      color: 'text.secondary',
      fontSize: 2,
      lineHeight: 3
    },
    'body-lightMedium-disabled': {
      ...defaultextStyles,
      fontWeight: 'lightMedium',
      color: 'base.disabled',
      fontSize: 3,
      lineHeight: 3
    },
    'body-2': {
      ...defaultextStyles,
      fontSize: 2,
      lineHeight: 2,
      color: 'text.secondary'
    },
    bodyLora: {
      ...defaultextStyles,
      fontFamily: 'bodyLora',
      fontSize: '2rem',
      lineHeight: '2.8rem'
    },
    description: {
      ...defaultextStyles,
      color: 'text.secondary',
      fontSize: 3,
      lineHeight: 3
    }
  }
});

export const Text = styled.p(
  textVariants,
  compose(
    color,
    typography,
    layout,
    space,
    system({
      whiteSpace: {
        property: 'whiteSpace',
        cssProperty: 'whiteSpace'
      },
      uppercase: {
        property: 'textTransform',
        cssProperty: 'textTransform',
        transform: value => (value ? 'uppercase' : 'none')
      }
    })
  )
);

Text.propTypes = {
  variant: PropTypes.oneOf([
    'hint',
    'caption',
    'body-1',
    'body-2',
    'bodyLora',
    'body-semibold',
    'body-lightMedium',
    'body-lightMedium-1',
    'body-lightMedium-disabled',
    'description',
    'label'
  ])
};

Text.defaultProps = {
  variant: 'body-1'
};
