import React from 'react';
import { Redirect } from 'wouter';

import withBallotGuideLayout from './layout';

const FavoritesScreen = React.lazy(() => import('modules/ballotGuide/screens/favoritesScreen'));
const CandidateListScreen = React.lazy(() => import('modules/ballotGuide/screens/candidateList'));
const CandidateDetailsScreen = React.lazy(() =>
  import('modules/ballotGuide/screens/candidateDetails')
);
const PresidentDetailsScreen = React.lazy(() =>
  import('modules/ballotGuide/screens/presidentDetailsScreen')
);
const MeasureDetailsScreen = React.lazy(() => import('modules/ballotGuide/screens/measureDetails'));

export default [
  {
    name: 'Ballot Guide',
    path: '/ballot-guide',
    component: () => <Redirect to="/ballot-guide/favorites" />
  },
  {
    name: 'Ballot Guide',
    path: '/ballot-guide/favorites',
    component: withBallotGuideLayout(FavoritesScreen)
  },
  {
    name: 'Measure details',
    path: '/ballot-guide/measures/:measureId',
    component: withBallotGuideLayout(MeasureDetailsScreen)
  },
  {
    name: 'Candidates List',
    path: '/ballot-guide/:category',
    component: withBallotGuideLayout(CandidateListScreen)
  },
  {
    name: 'President Details',
    path: '/ballot-guide/president/:electionId/:presidentId/:vicePresidentId',
    component: withBallotGuideLayout(PresidentDetailsScreen)
  },
  {
    name: 'Candidate Details',
    path: '/ballot-guide/:category/:identificator/:electionId',
    component: withBallotGuideLayout(CandidateDetailsScreen)
  }
];
