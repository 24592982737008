import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import styled from 'styled-components';

import { Text } from 'modules/ui/primitives';
import { transformToDisplayNumber } from 'modules/core/utils';
import { useGetCurrentBreakpointValue } from 'modules/ui/hooks';
import { Tooltip, generateTooltipHtml } from './tooltip';

const GridLineText = styled(Text)`
  position: absolute;
  top: ${props => (props.position === 'bottom' ? 'none' : '0')};
  bottom: ${props => (props.position === 'bottom' ? '16px' : 'none')};
  right: 0;
`;

const BarChartContainer = styled.div`
  padding-top: 16px;
  .grid path {
    stroke-width: 0;
  }

  .tick:first-child line {
    stroke-width: 3px;
  }

  .tick line {
    stroke-color: #4a5259;
    opacity: 0.1;
  }

  .tick:last-child line {
    opacity: 0.2;
  }

  .grid-text {
    color: red;
  }
`;

const roundNumber = (num, roundValue) => Math.ceil(num / roundValue) * roundValue;
const round = num => {
  const number = num < 100 ? 100 : num;
  return number < 1000 ? roundNumber(number, 100) : roundNumber(number, 1000);
};

export const BarChart = ({ width = [600], height = 330, data, colorScheme, highlightedKey }) => {
  const [maxValue, setMaxValue] = useState();
  const ref = React.createRef();
  const margin = 10;

  const currentBreakpointSize = useGetCurrentBreakpointValue(width);

  useEffect(() => {
    if (ref.current) {
      const maxY = d3.max(data, d => round(d.y));
      setMaxValue(maxY);
      const svg = d3
        .select(ref.current)
        .select('svg')
        .attr('width', currentBreakpointSize)
        .attr('height', height);

      const tooltip = d3.select(ref.current).select('#tooltip');

      const x = d3
        .scaleBand()
        .domain(d3.range(data.length))
        .padding(0.6)
        .range([0 + margin, currentBreakpointSize - margin]);

      const y = d3
        .scaleLinear()
        .domain([0, maxY])
        .nice()
        .range([height - margin, 0 + margin]);

      const yLines = d3
        .scaleLinear()
        .domain([0, maxY])
        .range([height - margin, 0 + margin]);

      const yAxisGrid = d3
        .axisLeft(yLines)
        .tickSize(margin - currentBreakpointSize)
        .tickFormat(d => {
          if (d === 0 || d === maxY) {
            return transformToDisplayNumber(d);
          }

          return '';
        })
        .tickValues([0, maxY * 0.25, maxY / 2, maxY * 0.75, maxY]);

      svg
        .select('.grid')
        .style('stroke-dasharray', '5 5')
        .call(yAxisGrid);

      svg
        .selectAll('rect')
        .data(data)
        .join('rect')
        .attr('x', (d, i) => x(i))
        .attr('y', d => y(d.y))
        .attr('height', d => y(0) - y(d.y))
        .attr('width', x.bandwidth())
        .attr('fill', d => {
          return colorScheme ? colorScheme[d.x] : 'red';
        })
        .on('mouseenter', function handleMouseMove(e, hoveredStateData) {
          const { x: hoveredX, y: hoveredY, formatLabel } = hoveredStateData;
          const hoveredIndex = data.findIndex(d => d.x === hoveredX);

          const html = generateTooltipHtml(hoveredX, hoveredY, formatLabel);

          tooltip.classed('hidden', false).html(html);

          const tooltipSize = tooltip.node().getBoundingClientRect();

          tooltip.attr(
            'style',
            `left:${x(hoveredIndex) - tooltipSize.width / 2 + x.bandwidth() / 2}px;
              bottom:${height - y(hoveredY) + 16}px;`
          );
        })
        .on('mouseleave', function handleMouseOut() {
          tooltip.classed('hidden', true);
        });

      if (highlightedKey) {
        const highlightedData = data.find(d => d.x === highlightedKey);
        const highlightedIndex = data.findIndex(d => d.x === highlightedKey);

        if (highlightedData) {
          const { x: highlightedX, y: highlightedY, formatLabel } = highlightedData;

          const html = generateTooltipHtml(highlightedX, highlightedY, formatLabel);

          tooltip.classed('hidden', false).html(html);

          const tooltipSize = tooltip.node().getBoundingClientRect();

          tooltip.attr(
            'style',
            `left:${x(highlightedIndex) - tooltipSize.width / 2 + x.bandwidth() / 2}px;
            bottom:${height - y(highlightedY) + 16}px;`
          );
        }
      }

      if (!highlightedKey) {
        tooltip.classed('hidden', true);
      }
    }
  }, [data, ref, width, height, colorScheme, highlightedKey, currentBreakpointSize]);

  return (
    <BarChartContainer ref={ref} style={{ position: 'relative' }}>
      <svg id="bar-chart">
        <g className="grid" />
      </svg>
      <Tooltip id="tooltip" className="hidden" />
      <GridLineText variant="caption" color="text.secondary" position="bottom">
        0
      </GridLineText>
      {maxValue && (
        <GridLineText variant="caption" color="text.secondary">
          {transformToDisplayNumber(maxValue)}
        </GridLineText>
      )}
    </BarChartContainer>
  );
};

BarChart.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.array,
  height: PropTypes.number,
  colorScheme: PropTypes.object,
  highlightedKey: PropTypes.string
};
