import React from 'react';
import styled from 'styled-components';
import { TimePicker as AntTimePicker } from 'antd';

import 'antd/lib/date-picker/style/css';

import { datePickerStyle } from './style';

export const TimePicker = styled(props => (
  <AntTimePicker
    format="h:mm a"
    showNow={false}
    getPopupContainer={trigger => {
      return trigger;
    }}
    showToday={false}
    {...props}
  />
))`
  ${datePickerStyle}
`;
