import { compose, variant, color, typography, space, display } from 'styled-system';
import styled from 'styled-components';

import PropTypes from 'prop-types';

const typographyFunctions = compose(color, typography, space, display);

const defaultHeadingStyles = {
  fontFamily: 'headingHeavy',
  fontWeight: 'bold',
  color: 'text.primary',
  mt: 0,
  mb: 0
};

const headingVariant = variant({
  variants: {
    display: {
      ...defaultHeadingStyles,
      fontSize: [8, 8, 9],
      lineHeight: [8, 8, 9]
    },
    h1: {
      ...defaultHeadingStyles,
      fontSize: [7, 8],
      lineHeight: [7, 8]
    },
    h1Bold: {
      ...defaultHeadingStyles,
      fontFamily: 'headingBold',
      fontSize: [7, 8],
      lineHeight: [7, 8]
    },
    h1Bolder: {
      ...defaultHeadingStyles,
      fontFamily: 'heading',
      fontWeight: 'bolder',
      fontSize: [7, 8],
      lineHeight: [7, 8]
    },
    h2: {
      fontSize: [5, 6],
      lineHeight: [5, 6],
      ...defaultHeadingStyles
    },
    h2Bold: {
      ...defaultHeadingStyles,
      fontSize: [5, 6],
      lineHeight: [5, 7],
      fontFamily: 'headingBold'
    },
    h2Bolder: {
      fontFamily: 'heading',
      fontSize: 6,
      lineHeight: 5,
      fontWeight: 'bolder'
    },
    h3: {
      fontSize: [4, 5],
      lineHeight: [4, 5],
      ...defaultHeadingStyles
    },
    h3Normal: {
      ...defaultHeadingStyles,
      fontSize: [4, 5],
      lineHeight: [4, 5],
      fontFamily: 'heading',
      ':hover': {
        'text-decoration': 'underline'
      }
    },
    h3Bold: {
      ...defaultHeadingStyles,
      fontFamily: 'headingBold',
      fontSize: [4, 5],
      lineHeight: [4, 5]
    },
    h4: {
      ...defaultHeadingStyles,
      fontSize: 3,
      lineHeight: 3
    },
    h4Bold: {
      ...defaultHeadingStyles,
      fontFamily: 'headingBold',
      fontSize: 3,
      lineHeight: 3
    },
    h5: {
      fontSize: [1, 2],
      lineHeight: [1, 2],
      ...defaultHeadingStyles
    },
    h4BallotGuide: {
      ...defaultHeadingStyles,
      fontFamily: 'headingBold',
      fontSize: 4,
      lineHeight: 4
    }
  }
});

export const Heading = styled.h1`
  ${headingVariant}
  ${typographyFunctions}

  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
`;

Heading.propTypes = {
  variant: PropTypes.oneOf([
    'display',
    'h1',
    'h1Bold',
    'h2',
    'h2Bold',
    'h2Bolder',
    'h3',
    'h3Normal',
    'h3Bold',
    'h4',
    'h4Bold',
    'h4BallotGuide',
    'h5'
  ]),
  uppercase: PropTypes.bool
};

Heading.defaultProps = {
  variant: 'h1',
  uppercase: false
};
