import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRoute, useLocation } from 'wouter';
import styled from 'styled-components';
import { Star, ChevronRight } from 'react-feather';
import PropTypes from 'prop-types';
import { Menu, HorizontalProgress } from 'modules/ui/components';
// import { DialogTrigger } from 'modules/ui/components/dialog';
import { Heading } from 'modules/ui/primitives';
import { triggerGtmEvent } from 'modules/shared/services';

import { Disclaimer } from './disclaimer';

import { useBallotData } from '../provider';

const SidebarWrap = styled.div`
  background: ${({ theme: { colors } }) => colors.base.white};
  height: 100%;
  margin-right: 1px;
  box-shadow: 1px 4px rgba(184, 192, 199, 0.2);
`;

const SidebarHeader = styled.div`
  padding: 16px 32px;
`;

const ProgressText = styled.span`
  color: ${({ theme: { colors } }) => colors.text.grey};
`;

const StarIcon = styled(Star)`
  width: 20px;
  height: 20px;
  vertical-align: middle;
  color: ${({ theme: { colors } }) => colors.background.darkPink};
  fill: ${({ theme: { colors } }) => colors.background.darkPink};
`;

const FavoritesLink = styled(Link)`
  font-size: ${({ theme: { fontSizes } }) => fontSizes[3]};
  line-height: ${({ theme: { lineHeights } }) => lineHeights[3]};
  color: ${({ theme: { colors } }) => colors.base.black};
  margin-top: ${({ theme: { space } }) => space[1]}px;
  display: block;
  &:hover {
    color: ${({ theme: { colors } }) => colors.action.pink};
  }
`;

const ChevronRightIcon = styled(ChevronRight)`
  width: 20px;
  height: 20px;
  vertical-align: middle;
  float: right;
`;

export const Sidebar = ({ hidden, setHidden = () => {} }) => {
  // const dialog = useDialogState({ animated: true });
  const { t } = useTranslation();
  const [selectedKeys, setSelectedKeys] = useState();
  const [{ data, isLoading }] = useBallotData();

  const [isOtherCanidatesMatch, candidatesParams] = useRoute(
    '/ballot-guide/:category/:identificator?/:electionId'
  );

  // const dialogOpenButton = (
  //   <Button
  //     variant="secondary"
  //     id="invite-a-friend-button"
  //     width="23.6rem"
  //     onClick={() => triggerGtmEvent('Ballot Guide', 'Invite', 'Intent to invite')}
  //     leftIcon={<Upload />}
  //   >
  //     {t('home.inviteFriends', 'Invite Friends')}
  //   </Button>
  // );

  const [isListMatch, listParams] = useRoute('/ballot-guide/:category');

  const [location] = useLocation();

  const { positions, measures, totalCount = 0, totalFavoritesCount = 0 } = data;

  useEffect(() => {
    let parentCategory;
    let childCategory;

    if (isOtherCanidatesMatch) {
      [parentCategory] = candidatesParams.category.split('-');
      childCategory = `/ballot-guide/${candidatesParams.category}`;
    }

    if (isListMatch) {
      [parentCategory] = listParams.category.split('-');
      childCategory = listParams.category;
    }

    if (location.indexOf('president') !== -1) {
      parentCategory = 'federal';
      childCategory = '/ballot-guide/president';
    }

    if (location.indexOf('measures') !== -1) {
      parentCategory = 'measures';
      childCategory = '/ballot-guide/measures';
    }

    if (!selectedKeys) {
      setSelectedKeys([`/ballot-guide/${parentCategory}`, childCategory, parentCategory, location]);
    }

    if (
      selectedKeys &&
      (selectedKeys.indexOf(parentCategory) === -1 ||
        selectedKeys.indexOf(childCategory) === -1 ||
        selectedKeys.indexOf(location) === -1)
    ) {
      setSelectedKeys([
        `/ballot-guide/${parentCategory}`,
        `/ballot-guide/${childCategory}`,
        parentCategory,
        childCategory,
        location
      ]);
    }
  }, [candidatesParams, listParams, isOtherCanidatesMatch, isListMatch, location, selectedKeys]);

  const toggleSelectedKey = (key, hide = true) => {
    if (selectedKeys.indexOf(key) !== -1) {
      setSelectedKeys(selectedKeys.filter(k => k !== key));
    } else {
      setSelectedKeys([...selectedKeys, key]);
    }

    const [sectionName] = key.replace('/ballot-guide/', '').split('-');

    triggerGtmEvent(
      'BallotGuide',
      'Click',
      sectionName === 'measures' ? 'ballot measures' : `${sectionName} candidates`
    );

    setHidden(hide);
  };

  return (
    <>
      <SidebarWrap className={hidden ? 'hidden-mobile' : ''}>
        {!isLoading && (
          <>
            <SidebarHeader>
              <Heading as="h2" variant="h2Bold" className="hidden-mobile" mb="3">
                {t('ballotGuide.myBallotGuide', 'My Ballot Guide')}
              </Heading>
              <HorizontalProgress
                pink
                progress={(totalFavoritesCount / totalCount) * 100 || 0}
                title="progress"
                animated={false}
                marginBottom="8px"
              />
              <FavoritesLink
                to="/ballot-guide/favorites"
                onClick={() => triggerGtmEvent('BallotGuide', 'Click', 'My favorites')}
              >
                <StarIcon /> {t('ballotGuide.myFavorites', 'My Favorites')}{' '}
                <ProgressText>({`${totalFavoritesCount}/${totalCount}`})</ProgressText>{' '}
                <ChevronRightIcon />
              </FavoritesLink>
            </SidebarHeader>
            {positions && (
              <Menu mode="inline" multiple selectedKeys={selectedKeys} openKeys={selectedKeys}>
                {positions.map(category => (
                  <Menu.SubMenu
                    key={category.path}
                    onTitleClick={({ key }) => {
                      toggleSelectedKey(key, false);
                    }}
                    title={<span>{category.name}</span>}
                  >
                    {category.subCategories?.map(subCategory => {
                      return subCategory.candidates?.length > 0 ? (
                        <Menu.SubMenu
                          onTitleClick={({ key }) => {
                            toggleSelectedKey(key);
                          }}
                          key={`/ballot-guide/${subCategory.path}`}
                          className="third-lvl-menu"
                          title={
                            <Link to={`/ballot-guide/${subCategory.path}`}>{subCategory.name}</Link>
                          }
                        >
                          {subCategory.candidates?.map(cnd => (
                            <Menu.Item className="hidden-mobile" key={`/ballot-guide/${cnd.path}`}>
                              <Link
                                to={`/ballot-guide/${cnd.path}`}
                                onClick={() => {
                                  setHidden(true);
                                  window.scrollTo(0, 0);
                                }}
                              >
                                {cnd.length === 2
                                  ? `${cnd[0].lastName} & ${cnd[1].lastName}`
                                  : `${cnd.firstName} ${cnd.lastName}`}
                              </Link>
                            </Menu.Item>
                          ))}
                        </Menu.SubMenu>
                      ) : (
                        <Menu.Item key={`/ballot-guide/${subCategory.path}`}>
                          <Link
                            to={`/ballot-guide/${subCategory.path}`}
                            onClick={() => {
                              setHidden(true);
                              window.scrollTo(0, 0);
                            }}
                          >
                            {subCategory.name}
                          </Link>
                        </Menu.Item>
                      );
                    })}
                  </Menu.SubMenu>
                ))}
              </Menu>
            )}
            {measures && measures.length > 0 ? (
              <Menu mode="inline" openKeys={selectedKeys} selectedKeys={selectedKeys}>
                <Menu.SubMenu
                  key="measures"
                  title={<span>{t('ballotGuide.ballotMeasures', 'Ballot measures')}</span>}
                  onTitleClick={({ key }) => {
                    toggleSelectedKey(key, false);
                  }}
                >
                  {measures.map(measure => (
                    <Menu.Item key={`/ballot-guide/measures/${measure.id}`}>
                      <Link
                        to={`/ballot-guide/measures/${measure.id}`}
                        onClick={() => {
                          setHidden(true);
                          window.scrollTo(0, 0);
                        }}
                      >
                        {measure.name}
                      </Link>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              </Menu>
            ) : null}
            {/* <Flex justifyContent="center" mt={[3, 5]} pb="3">
              <DialogTrigger {...dialog}>{dialogOpenButton}</DialogTrigger>
            </Flex> */}
            <Disclaimer className="hidden-mobile" />
          </>
        )}
      </SidebarWrap>
    </>
  );
};

Sidebar.propTypes = {
  hidden: PropTypes.bool,
  setHidden: PropTypes.func
};
