import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HIDE_LANGUAGE_SELECTOR } from 'modules/shared/constants';
import { languageConstants } from './constants';

export const getCurrentLanguage = () =>
  window.localStorage.getItem(languageConstants.LANGUAGE_STORAGE_KEY);

export const useLocalize = () => {
  const { i18n } = useTranslation();

  const { languages } = i18n.options.customSettings;

  const newState = lang => {
    return {
      hideSelector: window.localStorage.getItem(HIDE_LANGUAGE_SELECTOR) !== null,
      currentLangKey: getCurrentLanguage() || languageConstants.DEFAULT_LANGUAGE,
      availableLanguages: languages,
      dateFormat: languages.find(item => item.key === lang)?.dateFormat
    };
  };

  const [langState, setLangState] = useState(newState);

  const setLanguage = async lang => {
    if (langState.currentLangKey !== lang) {
      await i18n.options.customSettings.setLanguage(lang);

      window.localStorage.setItem(languageConstants.LANGUAGE_STORAGE_KEY, lang);

      setLangState(prevState => {
        return { ...prevState, ...newState(lang) };
      });
    }
  };

  return { langState, setLangState, setLanguage };
};
