import React from 'react';
import { Redirect } from 'wouter';

const SigninScreen = React.lazy(() => import('./screens/signin/signin'));
const SignupScreen = React.lazy(() => import('./screens/signup/signup'));
// const VerifyScreen = React.lazy(() => import('./screens/verify'));
const ForgotPasswordScreen = React.lazy(() => import('./screens/forgotPassword'));
const ResetPasswordScreen = React.lazy(() => import('./screens/resetPassword'));
const SuccessScreen = React.lazy(() => import('./screens/success'));

export default [
  {
    name: 'Signin',
    path: '/auth/signin',
    component: SigninScreen
  },
  {
    name: 'Signup',
    path: '/auth/signup',
    component: SignupScreen
  },
  {
    name: 'Success',
    path: '/auth/success',
    component: SuccessScreen
  },
  // {
  //   name: 'Verify',
  //   path: '/auth/verify',
  //   component: VerifyScreen
  // },
  {
    name: 'ForgotPassword',
    path: '/auth/forgotpassword',
    component: ForgotPasswordScreen
  },
  {
    name: 'ResetPassword',
    path: '/auth/reset',
    component: ResetPasswordScreen
  },
  {
    path: '/',
    // fix for url params not being passed after redirect
    component: () => {
      return <Redirect to={`/auth/signin/${window.location.search}`} />;
    }
  }
];
