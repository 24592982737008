// For schema reference, refer to link below.
// https://github.com/aribouius/jsonapi-react#user-content-schema-definition

export const schema = {
  // verify: {
  //   type: 'user-email-verify-actions',
  //   id: 'string',
  //   attributes: {
  //     code: 'string'
  //   },
  //   endpoint: 'auth/users/actions/verify'
  // },
  'verification-request': {
    type: 'user-resend-email-verification-actions',
    id: 'string',
    attributes: {
      email: 'string'
    },
    endpoint: 'auth/users/actions/verification-request'
  },
  users: {
    type: 'user-signup-actions',
    id: 'string',
    attributes: {
      email: 'string',
      password: 'string',
      referrerCode: 'string',
      firstName: 'string',
      lastName: 'string',
      zipCode: 'string',
      liveAbroad: 'boolean',
      utmSource: 'string',
      utmMedium: 'string',
      utmCampaign: 'string',
      utmContent: 'string'
    },
    relationships: {
      userProfile: {
        data: {
          type: 'user-profiles',
          id: 'string'
        }
      },
      'user-roles': {
        data: {
          type: 'user-roles',
          id: 'string'
        }
      },
      'user-settings': {
        data: {
          type: 'user-settings',
          id: 'string'
        }
      }
    },
    endpoint: 'auth/users'
  },
  tokens: {
    type: 'auth-token-create-actions',
    id: 'string',
    attributes: {
      tokenType: 'string'
    },
    endpoint: 'auth/tokens'
  },
  'google-token': {
    type: 'auth-token-create-actions',
    id: 'string',
    attributes: {
      token: 'string',
      created: 'boolean',
      tokenType: 'string',
      utmSource: 'string',
      utmMedium: 'string',
      utmCampaign: 'string',
      utmContent: 'string'
    },
    endpoint: 'auth/tokens/google'
  },
  'password-reset': {
    type: 'user-password-reset-actions',
    id: 'string',
    attributes: {
      code: 'string',
      newPassword: 'string'
    },
    endpoint: 'auth/users/actions/password-reset'
  },
  'request-password-reset': {
    type: 'user-password-reset-request-actions',
    id: 'string',
    attributes: {
      code: 'string'
    },
    endpoint: 'auth/users/actions/request-password-reset'
  },
  deactivate: {
    type: 'user-deactivation-actions',
    id: 'string',
    attributes: {
      reason: 'string'
    },
    endpoint: 'auth/users/me/actions/deactivate'
  },
  'user-profiles': {
    type: 'user-profiles',
    id: 'string',
    attributes: {
      firstName: 'string',
      lastName: 'string',
      location: 'al',
      broadActivismExperience: 'new',
      specificActivismExperience: ['donated'],
      seekingInMembership: ['looking-for-community'],
      motivationToStart: 'string',
      whenDoYouFeelMostPowerful: 'string',
      importantIssue: 'string',
      superpower: 'string',
      birthday: '1990-05-01',
      gender: 'woman',
      pronouns: ['she/her'],
      race: ['african-american-or-black'],
      religion: 'yes',
      sexualOrientation: ['lesbian'],
      tellUsMore: 'string',
      heardAboutUs: 'string'
    },
    relationships: {
      user: {
        data: {
          type: 'users',
          id: 'string'
        }
      }
    },
    endpoint: 'user-profiles/me'
  },
  'user-roles': {
    type: 'user-roles',
    attributes: {
      name: 'string',
      description: 'string'
    },
    endpoint: 'auth/user-roles/all'
  },
  elections: {
    type: 'elections',
    attributes: {
      name: 'string',
      electionDay: 'string',
      electionId: 'number',
      type: 'string',
      state: 'string',
      mailInMustBePostmarkedBy: 'boolean',
      status: 'string'
    },
    endpoint: 'elections'
  },
  'users-by-email': {
    type: 'user-search-actions',
    attributes: {
      email: 'string'
    },
    relationships: {
      userProfile: {
        data: {
          type: 'user-profiles',
          id: 'string'
        }
      },
      'user-roles': {
        data: {
          type: 'user-roles',
          id: 'string'
        }
      },
      'user-settings': {
        data: {
          type: 'user-settings',
          id: 'string'
        }
      }
    },
    endpoint: 'auth/users/actions/search?include=userProfile,roles&'
  },
  'member-invites': {
    type: 'member-invites',
    id: 'string',
    attributes: {
      inviteeEmail: 'string'
    },
    endpoint: 'member-invites'
  },
  'invited-friends': {
    type: 'invited-friends',
    id: 'string',
    attributes: {
      email: 'string',
      firstName: 'string',
      lastName: 'string',
      joined: 'boolean'
    },
    endpoint: '/auth/users/me/invited-friends'
  },
  'home-actions-base': {
    type: 'home-actions',
    id: 'string',
    attributes: {
      name: 'string',
      description: 'string',
      payload: 'object',
      maxCompletionCount: 'number',
      type: 'string',
      eventType: 'string',
      trackingTypes: [],
      whyItMatters: 'string',
      tag: 'string',
      targetTime: 'date',
      sponsor: 'string',
      localizations: 'object',
      published: 'boolean',
      authorId: 'number',
      lastEditedById: 'number'
    },
    relationships: {
      campaign: {
        data: {
          type: 'campaigns',
          id: 'string'
        }
      },
      author: {
        data: {
          type: 'users',
          id: 'string'
        }
      },
      lastEditedBy: {
        data: {
          type: 'users',
          id: 'string'
        }
      },
      regionalOrganizingDirector: {
        data: {
          type: 'regional-organizing-directors',
          id: 'string'
        }
      }
    },
    endpoint: 'home-actions'
  },
  'home-actions': {
    type: 'home-actions',
    id: 'string',
    attributes: {
      name: 'string',
      description: 'string',
      payload: 'object',
      maxCompletionCount: 'number',
      type: 'string',
      trackingTypes: [],
      whyItMatters: 'string',
      tag: 'string',
      targetTime: 'date',
      sponsor: 'string',
      localizations: 'object',
      published: 'boolean',
      authorId: 'number',
      lastEditedById: 'number'
    },
    relationships: {
      campaign: {
        data: {
          type: 'campaigns',
          id: 'string'
        }
      },
      regionalOrganizingDirector: {
        data: {
          type: 'regional-organizing-directors',
          id: 'string'
        }
      }
    },
    endpoint: 'home-actions?include=campaign&filter[active]=true'
  },
  'home-actions-include-inactive': {
    type: 'home-actions',
    id: 'string',
    attributes: {
      name: 'string',
      description: 'string',
      payload: 'object',
      maxCompletionCount: 'number',
      type: 'string',
      trackingTypes: [],
      whyItMatters: 'string',
      tag: 'string',
      targetTime: 'date',
      sponsor: 'string',
      localizations: 'object',
      published: 'boolean',
      authorId: 'number',
      lastEditedById: 'number'
    },
    relationships: {
      campaign: {
        data: {
          type: 'campaigns',
          id: 'string'
        }
      }
    },
    endpoint: 'home-actions?include=campaign&viewAll=true'
  },
  'home-actions-search': {
    type: 'home-actions',
    id: 'string',
    attributes: {
      name: 'string',
      description: 'string',
      payload: 'object',
      maxCompletionCount: 'number',
      type: 'string',
      trackingTypes: [],
      whyItMatters: 'string',
      tag: 'string',
      targetTime: 'date',
      sponsor: 'string',
      localizations: 'object',
      published: 'boolean',
      authorId: 'number',
      lastEditedById: 'number'
    },
    relationships: {
      campaign: {
        data: {
          type: 'campaigns',
          id: 'string'
        }
      }
    },
    endpoint: 'home-actions/actions/search?'
  },
  'home-action-statuses': {
    type: 'home-action-statuses',
    id: 'string',
    attributes: {
      additionalInfo: 'string',
      completionTimestamps: [],
      timesCompleted: 'number',
      updatedAt: 'string',
      createdAt: 'string'
    },
    relationships: {
      homeAction: {
        data: {
          type: 'home-actions',
          id: 'string'
        }
      },
      user: {
        data: {
          type: 'users',
          id: 'string'
        }
      }
    },
    endpoint: 'home-action-statuses?include=homeAction,user'
  },
  'home-action-complete-actions': {
    type: 'home-action-complete-actions',
    id: 'string',
    attributes: {},
    relationships: {
      homeAction: {
        data: {
          type: 'home-actions',
          id: 'string'
        }
      },
      user: {
        data: {
          type: 'users',
          id: 'string'
        }
      }
    },
    endpoint: 'home-action-statuses/actions/complete?include=homeAction,user'
  },
  'user-settings': {
    type: 'user-settings',
    id: 'string',
    attributes: {
      preferredEmail: 'user@example.com',
      phoneNumber: 'string',
      language: 'string'
    },
    relationships: {
      user: {
        data: {
          type: 'users',
          id: 'string'
        }
      }
    },
    endpoint: 'user-settings/me'
  },
  'voter-registration-verify': {
    type: 'voter-registration-verify-actions',
    id: 'string',
    attributes: {
      firstName: 'string',
      lastName: 'string',
      votingAddress: 'string',
      votingCity: 'string',
      votingState: 'string',
      votingZipCode: 'string',
      birthday: 'string',
      phoneNumber: 'string'
    },
    endpoint: 'voter-registrations/actions/verify'
  },
  'user-statistics': {
    type: 'user-statistics',
    id: 'string',
    attributes: {
      referrals: 'number'
    },
    endpoint: 'user-profiles/me/statistics'
  },
  'elections-deadlines-registration': {
    type: 'election-deadlines',
    id: 'string',
    attributes: {
      electionId: 'string',
      deadlineVoterRegistrationMailIn: 'string',
      deadlineVoterRegistrationMailInType: 'string',
      deadlineVoterRegistrationInPerson: 'string'
    },
    endpoint: 'elections/deadlines/voter-registration'
  },
  'elections-deadlines-vote-in-person': {
    type: 'election-deadlines',
    id: 'string',
    attributes: {
      state: 'string',
      earlyVotingType: 'string',
      earlyVotingStartDate: 'string',
      generalElectionDate: 'string',
      generalElection: 'string'
    },
    endpoint: 'elections/deadlines/vote-in-person'
  },
  'elections-deadlines-vote-by-mail': {
    type: 'election-deadlines',
    id: 'string',
    attributes: {
      mailInMustBePostmarkedBy: true,
      requestBallotReceiveDeadline: 'string',
      mailInPostmarkDeadline: 'string',
      dropOffDeadline: 'string',
      name: 'string',
      electionDay: 'string',
      electionId: 'string',
      type: 'string'
    },
    endpoint: 'elections/deadlines/vote-by-mail'
  },
  'elections-candidates': {
    type: 'election-candidates',
    id: 'string',
    attributes: {
      thumbUrl: 'string',
      photoUrl: 'string',
      urls: [
        {
          url: 'string',
          type: 'string'
        }
      ],
      firstName: 'string',
      lastName: 'string',
      middleName: 'string',
      suffix: 'string',
      candidacies: [
        {
          isRunningMate: 'boolean',
          electionName: 'string',
          electionDay: 'string',
          electionId: 0,
          state: 'string',
          partyName: 'string',
          positionName: 'string',
          positionId: 0,
          incumbent: 'boolean'
        }
      ],
      experience: [
        {
          entryType: 'string',
          startYear: 'string',
          endYear: 'string',
          company: 'string',
          position: 'string'
        }
      ],
      education: [
        {
          school: 'string',
          major: 'string',
          degree: 'string',
          gradYear: 'string'
        }
      ],
      endorsements: [
        {
          logoUrl: 'string',
          id: 0,
          websiteUrl: 'string',
          name: 'string'
        }
      ],
      issues: [
        {
          isQuestion: 'boolean',
          name: 'string',
          questionText: 'string',
          stances: [
            {
              referenceUrl: 'string',
              responseValue: 'string',
              description: 'string'
            }
          ]
        }
      ]
    },
    endpoint: 'elections/candidates'
  },
  'election-positions': {
    type: 'election-positions',
    id: 'string',
    attributes: {
      positionId: 'number',
      generalDate: 'string',
      primaryDate: 'string',
      description: 'string',
      name: 'string',
      state: 'string',
      level: 'string',
      judicial: 'boolean'
    },
    endpoint: 'elections/positions'
  },
  'election-measures': {
    type: 'election-measures',
    id: 'string',
    attributes: {
      proSnippet: 'string',
      name: 'string',
      title: 'string',
      text: 'string',
      summary: 'string',
      state: 'string',
      rowOrder: 'string',
      id: 'string',
      conSnippet: 'string'
    },
    endpoint: 'elections/measures'
  },
  'election-polling-places': {
    type: 'election-polling-places',
    attributes: {
      earlyVoting: [
        {
          name: 'string',
          city: 'string',
          state: 'string',
          zip: 'string',
          precinct: 'string',
          country: 'string',
          address: 'string',
          secondaryAddress: 'string',
          inPersonAbsentee: true,
          inPersonVoting: true,
          mailInAddress: 'string',
          ballotDropOff: true
        }
      ],
      electionDay: [
        {
          name: 'string',
          city: 'string',
          state: 'string',
          zip: 'string',
          precinct: 'string',
          country: 'string',
          address: 'string',
          secondaryAddress: 'string',
          inPersonAbsentee: true,
          inPersonVoting: true,
          mailInAddress: 'string',
          ballotDropOff: true
        }
      ]
    },
    endpoint: 'elections/polling-places'
  },
  statistics: {
    type: 'statistics',
    id: 'number',
    attributes: {
      id: 'number',
      key: 'string',
      value: 'number'
    },
    endpoint: 'statistics'
  },
  'regional-organizing-directors': {
    type: 'regional-organizing-directors',
    id: 'number',
    attributes: {
      id: 'number',
      firstName: 'string',
      lastName: 'string',
      profileImage: 'string'
    },
    endpoint: 'regional-organizing-directors'
  },
  'generate-url': {
    type: 'asset-actions',
    id: 'string',
    attributes: {
      fileName: 'string',
      assetPath: 'string',
      downloadUrl: 'string',
      context: {},
      assetClass: 'string'
    },
    endpoint: 'assets/actions/generate-url'
  },
  'home-action-poll-answers': {
    type: 'home-action-poll-answers',
    id: 'number',
    attributes: [
      {
        actionId: 'number',
        questionId: 'string',
        answerId: 'string'
      }
    ],
    relationships: {
      action: {
        data: {
          type: 'home-actions',
          id: 'string'
        }
      }
    },
    endpoint: 'home-action-poll-answers'
  },
  'poll-answers-statistics': {
    type: 'home-action-poll-answers',
    attributes: [
      {
        answerId: 'string',
        value: 'number'
      }
    ],
    endpoint: 'home-action-poll-answers'
  },
  'assign-user-role': {
    type: 'user-role-assignment-actions',
    id: 'string',
    attributes: { userId: 'string', roleId: 'string' },
    relationships: {
      user: {
        data: {
          type: 'users',
          id: 'string'
        }
      },
      userRole: {
        data: {
          type: 'user-roles',
          id: 'string'
        }
      }
    },
    endpoint: 'auth/user-role-assignments/actions/assign-role'
  },
  'remove-user-role': {
    type: 'user-role-assignment-actions',
    id: 'string',
    attributes: { userId: 'string', roleId: 'string' },
    relationships: {
      user: {
        data: {
          type: 'users',
          id: 'string'
        }
      },
      userRole: {
        data: {
          type: 'user-roles',
          id: 'string'
        }
      }
    },
    endpoint: 'auth/user-role-assignments/actions/remove-role'
  },
  admins: {
    type: 'users',
    id: 'string',
    attributes: {
      email: 'string',
      password: 'string',
      referrerCode: 'string',
      firstName: 'string',
      lastName: 'string',
      zipCode: 'string',
      liveAbroad: 'boolean'
    },
    relationships: {
      userProfile: {
        data: {
          type: 'user-profiles',
          id: 'string'
        }
      },
      'user-roles': {
        data: {
          type: 'user-roles',
          id: 'string'
        }
      }
    },
    endpoint: 'auth/users/actions/search/admins'
  }
};
