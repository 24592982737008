import { allStates } from 'modules/electionCenter/assets/states';

export const getQuestion = (questions, questionId) => {
  return questions.find(q => q.id === questionId);
};

export const getAnswer = (questions, questionId, answerId) => {
  const question = getQuestion(questions, questionId);
  const answer = question.answers.find(a => a.id === answerId);
  return answer;
};

export const filterActions = (actions, userAnswers) => {
  const answeredActionIds = userAnswers.map(userAnswer => userAnswer.action.id);
  return actions.filter(action => !answeredActionIds.includes(action.id));
};

export const sortActions = (actions, userAnswers) => {
  const answeredActionIds = userAnswers.map(userAnswer => userAnswer.action.id);
  return actions.sort(
    (a, b) => answeredActionIds.includes(a.id) - answeredActionIds.includes(b.id)
  );
};

export const getAnswerForAction = (actionId, userAnswers) => {
  return userAnswers.find(userAnswer => userAnswer.action.id === actionId);
};

export const formatStatistic = value => Math.round(value * 100);

export const getStatisticValue = (answerId, statistics) => {
  const statistic = statistics.find(stat => stat.answerId === answerId);

  if (!statistic) {
    return 0;
  }

  return formatStatistic(statistic.value);
};

export const getStateName = stateCode => {
  return allStates.find(s => s.Abbreviation === stateCode)?.Name || stateCode || 'Unknown state';
};

export const getStateCode = stateName => {
  return allStates.find(s => s.Name === stateName)?.Abbreviation || stateName || 'Unknown state';
};
