import styled from 'styled-components';
import { Menu as AntMenu } from 'antd';

import 'antd/lib/menu/style/css';

export const Menu = styled(AntMenu)`
  color: ${({ theme: { colors } }) => colors.base.black};

  .ant-motion-collapse-appear-active {
    height: auto !important;
  }

  .ant-menu-submenu-title {
    padding-right: 84px;
  }

  .ant-menu-submenu-title > a,
  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover {
    color: ${({ theme: { colors } }) => colors.base.black} !important;
    width: 100%;
    display: block;
  }

  .ant-menu-item-selected > a, .ant-menu-item > a:hover {
    color: ${({ theme: { colors } }) => colors.action.pink} !important;
  }

  > .ant-menu-submenu > .ant-menu-submenu-title {
    height: auto !important;
    margin: 0;
    font-size: ${({ theme: { fontSizes } }) => fontSizes[4]};
    line-height: 76px !important;
    border-top: 1px solid ${({ theme: { colors } }) => colors.base.grey200};
  }

  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    border-left: 3px solid ${({ theme: { colors } }) => colors.background.darkPink};
  }

  > .ant-menu-submenu > .ant-menu-sub > .ant-menu-item,
  > .ant-menu-submenu > .ant-menu-sub > .ant-menu-submenu > .ant-menu-submenu-title {
    height: auto;
    margin: 0;
    padding-top: 24px;
    padding-bottom: 24px;

    font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
    line-height: ${({ theme: { lineHeights } }) => lineHeights[2]};
    white-space: normal;

    border-top: 1px solid ${({ theme: { colors } }) => colors.base.grey200};
  }

  > .ant-menu-submenu > .ant-menu-sub > .ant-menu-submenu > .ant-menu-sub {
    padding-top: 40px;
    padding-bottom: 40px;

    font-size: ${({ theme: { fontSizes } }) => fontSizes[2]};
    line-height: ${({ theme: { lineHeights } }) => lineHeights[2]};
    background-color: ${({ theme: { colors } }) => colors.base.grey100};
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  .ant-menu-submenu-selected {
    background-color: transparent;
    font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium} !important;
    color: ${({ theme: { colors } }) => colors.base.black};
  }

  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: none;
    left: 0;
    // border-left: 3px solid ${({ theme: { colors } }) => colors.background.darkPink};
  }

  .ant-menu-submenu-title:hover,
  .ant-menu-item:hover {
    color: ${({ theme: { colors } }) => colors.background.darkPink};
  }

  .ant-menu-submenu-title::after {
    font-size: ${({ theme: { fontSizes } }) => fontSizes[7]};
    font-weight: ${({ theme: { fontWeights } }) => fontWeights.regular};
    color: ${({ theme: { colors } }) => colors.base.disabled};
    position: absolute;
    right: 38px;
    top: 21px;
    content: '+';
  }

  > .ant-menu-submenu > .ant-menu-submenu-title::after {
    top: 0;
  }

  .ant-menu-submenu-open > .ant-menu-submenu-title::after {
    content: '-';
    right: 40px;
  }

  .ant-menu-submenu-arrow {
    display: none;
  }
`;
