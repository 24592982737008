import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography } from 'styled-system';
import { string } from 'prop-types';
import { uuid } from './uuid';

const RadioUnchecked = forwardRef(({ title, ...props }, svgRef) => {
  const [titleId] = useState(() => uuid());
  return (
    <svg
      viewBox="0 0 24 24"
      width="24px"
      height="24px"
      fill="currentcolor"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
    </svg>
  );
});

const RadioUncheckedIcon = styled(RadioUnchecked)(
  {
    flex: 'none',
    verticalAlign: 'middle'
  },
  compose(space, color, layout, typography)
);

RadioUnchecked.propTypes = {
  title: string
};

RadioUnchecked.defaultProps = {
  title: 'radio unchecked icon'
};

export default RadioUncheckedIcon;
