export default {
  colors: {
    action: {
      pink: '#FF4EA7',
      pinkDark: '#cc0066',
      pinkLight: '#FFEFF7',
      pinkSecondary: '#ED2589',
      orange: '#fa7500',
      orangeLight: '#fdf8f2',
      purple: '#5E0541',
      darkBlue: '#030B46',
      yellow: '#FBB032',
      pinkLight2: '#FCD0D3'
    },
    text: {
      primary: '#121212',
      secondary: '#4A5259',
      grey: '#B3B3B3'
    },
    base: {
      white: '#ffffff',
      disabled: '#B8C0C7',
      grey100: '#f7f7f9',
      grey200: '#efeff4',
      black: '#000000'
    },
    ui: {
      line: '#E5E5EA',
      transparent: 'transparent',
      success: '#00D480', // 119, 211, 132
      successfade: 'rgba(231, 246, 238, 1)',
      error: '#F13A59'
    },
    background: {
      hover: 'rgba(230, 0, 115, 0.1)',
      pink: '#ffd7eb',
      electionLight: '#fdf5ee',
      darkPink: '#ff4ea7',
      light: '#FDF5EE',
      darkBlue: '#060046',
      light100: 'rgba(0, 0, 0, 0.03)',
      black: '#000000',
      lightPink: '#fffbfc'
    }
  }
};
