import React from 'react';
import { OnboardingStartScreen } from 'modules/onboarding/screens/start';
import questions, { questionTypes } from './questions';

const ParagraphQuestionScreen = React.lazy(() => import('./screens/paragraphQuestion'));
const SelectMultipleQuestionScreen = React.lazy(() => import('./screens/selectMultipleQuestion'));
const SelectOneQuestionScreen = React.lazy(() => import('./screens/selectOneQuestion'));
const DateFieldQuestionScreen = React.lazy(() => import('./screens/dateFieldQuestion'));
const UploadProfilePicture = React.lazy(() => import('./screens/uploadProfilePicture'));
const ZipCodeQuestionScreen = React.lazy(() => import('./screens/zipCodeQuestion'));

export const BASE_ONBOARDING_PATH = '/onboarding';

function resolveQuestionsRoutes() {
  return questions.map(q => {
    let component;
    switch (q.type) {
      case questionTypes.selectMultiple:
        component = SelectMultipleQuestionScreen;
        break;
      case questionTypes.selectOne:
        component = SelectOneQuestionScreen;
        break;
      case questionTypes.dateField:
        component = DateFieldQuestionScreen;
        break;
      case questionTypes.uploadImage:
        component = UploadProfilePicture;
        break;
      case questionTypes.zipCode:
        component = ZipCodeQuestionScreen;
        break;
      case questionTypes.paragraph:
      default:
        component = ParagraphQuestionScreen;
        break;
    }

    return {
      name: q.routeParam,
      path: `${BASE_ONBOARDING_PATH}/${q.routeParam}`,
      component
    };
  });
}

export default [
  {
    name: 'Onboarding',
    path: `${BASE_ONBOARDING_PATH}/start`,
    component: OnboardingStartScreen
  },
  ...resolveQuestionsRoutes()
];
