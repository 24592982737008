import React from 'react';
import styled from 'styled-components';
import { compose, variant, typography, color, space, layout } from 'styled-system';
import { useRoute, Link as LinkWouter } from 'wouter';
import PropTypes from 'prop-types';
import { focusRingDashed } from 'modules/ui/primitives/shared/focusRing';

const defaultVariantBase = {
  fontFamily: 'body',
  color: 'text.primary',
  fontSize: 2,
  lineHeight: 2,
  textDecoration: 'underline',
  ':hover': {
    color: 'text.secondary'
  },
  ':active': {
    color: 'text.secondary'
  }
};

const brandVariantBase = {
  color: 'action.pink',
  textDecoration: 'none',

  ':hover': {
    color: 'action.pinkDark'
  },
  ':active': {
    color: 'action.pinkDark'
  }
};

const variants = {
  default: {
    ...defaultVariantBase
  },
  brand: {
    ...brandVariantBase,
    fontSize: 2,
    lineHeight: 2
  },
  'brand-caption': {
    ...brandVariantBase,
    fontSize: 1,
    lineHeight: 1
  },
  nav: {
    fontWeight: 'normal',
    color: 'text.secondary',
    textDecoration: 'none',

    ':hover': {
      color: 'text.secondary'
    },
    ':active': {
      color: 'text.secondary'
    }
  },
  hint: {
    ...defaultVariantBase,
    fontSize: 0
  },
  privacy: {
    ...defaultVariantBase,
    textDecoration: 'underline',
    color: 'default'
  },
  box: {
    ...defaultVariantBase,
    textDecoration: 'none',
    color: 'action.pink',
    fontSize: 3,
    fontWeight: 'medium',
    px: 2,
    py: 1,
    borderRadius: 0,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'action.pink',
    ':hover': {
      color: 'action.pinkSecondary',
      borderColor: 'action.pinkSecondary',
      bg: 'action.pinkLight'
    },
    transition: 'opacity .35s ease-out, color .3s ease-out, background .3s ease-in'
  },
  button: {
    ...defaultVariantBase,
    textDecoration: 'none',
    bg: 'action.pink',
    color: 'base.white',
    fontSize: 3,
    lineHeight: 3,
    fontWeight: 'medium',
    px: '40px',
    py: 1,
    borderRadius: 0,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'action.pink',
    ':hover': {
      color: 'base.white',
      bg: 'action.pinkDark'
    },
    textAlign: 'center',
    transition: 'all .3s ease-out'
  },
  'brand-button': {
    fontSize: 3,
    lineHeight: 3,
    fontWeight: 'medium',
    px: '40px',
    py: 1,
    borderRadius: 0,
    textAlign: 'center',
    transition: 'all .3s ease-out',
    color: 'action.pink',
    textDecoration: 'none',

    ':hover': {
      color: 'action.pinkDark'
    },
    ':active': {
      color: 'action.pinkDark'
    }
  }
};

const linkVariants = variant({
  variants
});

const styleLinkComponent = themedStyledFunction => {
  const LinkStyled = themedStyledFunction(linkVariants, compose(color, typography, layout, space));

  return styled(LinkStyled)`
    outline: none;
    &:focus {
      ${focusRingDashed}
    }
  `;
};

const StyledRouteLink = styleLinkComponent(styled(LinkWouter));
const StyledAnchorLink = styleLinkComponent(styled.a);

const RouteLink = ({ to, variant: v, ...props }) => {
  const [isActive] = useRoute(to);

  return <StyledRouteLink href={to} variant={isActive ? 'brand' : v} {...props} />;
};

RouteLink.propTypes = {
  variant: PropTypes.oneOf(Object.keys(variants)),
  to: PropTypes.string
};

export const Link = ({ to, variant: v, external, ...props }) =>
  external ? (
    <StyledAnchorLink href={to} variant={v} target="_blank" rel="noopener noreferrer" {...props} />
  ) : (
    <RouteLink to={to} variant={v} {...props} />
  );

Link.defaultProps = {
  variant: 'default',
  external: false
};

Link.propTypes = {
  variant: PropTypes.oneOf(Object.keys(variants)),
  to: PropTypes.string,
  external: PropTypes.bool
};
