import React from 'react';
import { Redirect } from 'wouter';

const HomeScreen = React.lazy(() => import('./screens/home'));
const Signout = React.lazy(() => import('modules/home/components/signout'));

export default [
  {
    name: 'Home',
    path: '/',
    component: HomeScreen
  },
  {
    name: 'Login',
    path: '/auth/signin',
    component: () => <Redirect to={`/${window.location.search}`} />
  },
  {
    name: 'Signout',
    path: '/signout',
    component: Signout
  }
];
