import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { compose, space, layout, flexbox, border, position, color } from 'styled-system';
import PropTypes from 'prop-types';
import { Icons } from 'modules/ui/assets';
import { Box } from '../box';
import { Flex } from '../flex';
import { Text } from '../text';
import { focusRingInput } from '../shared/focusRing';

const selectTokens = {
  iconSize: 4, // icon size on font-size scale
  y: 1, // padding y
  x: 1, // padding x
  iconRightOffset: 4
};

const ContentArea = styled(Text)`
  display: block;
  width: 100%;
  appearance: none;

  padding-left: ${props =>
    props.hasLeftIcon
      ? `calc(${props.theme.fontSizes[selectTokens.iconSize]} + ${2 *
          props.theme.space[selectTokens.x]}px)`
      : `${props.theme.space[selectTokens.x]}px`};

  padding-right: ${props =>
    props.hasRightIcon
      ? selectTokens.iconSize + 2 * props.theme.space[selectTokens.x]
      : props.theme.space[selectTokens.x]}px;

  border-color: ${props =>
    props.error ? props.theme.colors.ui.error : props.theme.colors.base.grey100};

  &::placeholder {
    color: ${props => props.theme.colors.text.secondary};
  }

  &:hover {
    border-color: ${props =>
      props.error ? props.theme.colors.ui.error : props.theme.colors.action.pinkDark};
  }

  &:focus {
    ${focusRingInput}

    border-color: ${props => props.theme.colors.action.pink};

    &::placeholder {
      color: transparent;
    }
  }

  &:disabled {
    -webkit-text-fill-color: currentColor; /* set text fill to current color for safari */
    opacity: 1; /* correct opacity on iOS */
    color: ${props => props.theme.colors.base.disabled};
    background-color: ${props => props.theme.colors.base.grey200};
    border-color: ${props => props.theme.colors.base.grey200};

    &::placeholder {
      color: ${props => props.theme.colors.text.secondary};
      opacity: 1;
    }
  }

  ${compose(space, layout, flexbox, border, position, color)}
`;

ContentArea.defaultProps = {
  pt: selectTokens.y,
  pb: selectTokens.y,
  borderWidth: '2px',
  borderStyle: 'solid',
  borderRadius: 0,
  color: 'text.primary',
  bg: 'base.grey100',
  mb: 0
};

const LeftIcon = styled(Box)`
  position: absolute;

  svg {
    display: block;
    font-size: ${props => props.theme.fontSizes[2]};
    color: ${props =>
      props.disabled ? props.theme.colors.base.disabled : props.theme.colors.text.primary};
  }
`;

const RightIcon = styled(Box)`
  position: absolute;

  svg {
    display: block;
    font-size: ${props => props.theme.fontSizes[2]};
    ${props => props.disabled && { color: props.theme.colors.base.disabled }};
  }
`;

export const Select = forwardRef(
  ({ leftIcon, rightIcon, mb, mt, mx, my, ml, mr, disabled, width, minWidth, ...props }, ref) => (
    <Flex
      alignItems="center"
      position="relative"
      width={width}
      minWidth={minWidth}
      mx={mx}
      my={my}
      mb={mb}
      mt={mt}
      ml={ml}
      mr={mr}
    >
      {leftIcon && (
        <LeftIcon left={selectTokens.x} disabled={disabled}>
          {leftIcon}
        </LeftIcon>
      )}
      <ContentArea
        as="select"
        variant="body-1"
        ref={ref}
        py={2}
        hasLeftIcon={!!leftIcon}
        hasRightIcon={!!rightIcon}
        disabled={disabled}
        aria-invalid={props.error ? 'true' : 'false'}
        {...props}
      />
      {rightIcon && (
        <RightIcon right={selectTokens.iconRightOffset} disabled={disabled}>
          {rightIcon}
        </RightIcon>
      )}
      <RightIcon right={selectTokens.x} disabled={disabled} style={{ pointerEvents: 'none' }}>
        <Icons.ExpandMore color="text.primary" aria-hidden />
      </RightIcon>
    </Flex>
  )
);

Select.propTypes = {
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  leftIcon: PropTypes.any,
  rightIcon: PropTypes.any,
  mb: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  mt: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  mx: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  my: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  ml: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  mr: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  minWidth: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};

Select.defaultProps = {
  error: false
};
