import { css } from 'styled-components';

export const focusRing = css`
  outline: 1px solid ${props => props.theme.colors.action.pink};
  outline-offset: 1px;
`;

export const focusRingDashed = css`
  outline: 1px dashed ${props => props.theme.colors.action.pink};
  outline-offset: 3px;
`;

export const focusRingButton = css`
  outline: none;
  box-shadow: 0 0 0 3px rgba(230, 0, 115, 0.4);
`;

export const focusRingInput = css`
  outline: none;
  box-shadow: 0 0 0 3px rgba(230, 0, 115, 0.4);
`;

export const focusRingArea = css`
  outline: none;
  box-shadow: 0 0 0 3px rgba(230, 0, 115, 0.4);
`;
