export const httpMethods = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  PUT: 'PUT',
  DELETE: 'DELETE'
};
export const languageConstants = {
  DEFAULT_LANGUAGE: 'en',
  DEFAULT_NAMESPACE: 'translation',
  LANGUAGE_STORAGE_KEY: '__sm__lang'
};
