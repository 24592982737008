import colors from './tokens/colors';
import fonts from './tokens/fonts';
import letterSpacing from './tokens/letterSpacing';
import lineHeights from './tokens/lineHeights';
import space from './tokens/space';
import sizes from './tokens/size';
import radius from './tokens/radius';
import shadows from './tokens/shadows';
import styles from './tokens/styles';
import breakpoints from './tokens/breakpoints';

export const theme = {
  ...breakpoints,
  ...colors,
  ...fonts,
  ...letterSpacing,
  ...lineHeights,
  ...space,
  ...sizes,
  ...radius,
  ...shadows,
  ...styles
};
