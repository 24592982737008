import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

import { transformToDisplayNumber } from 'modules/core/utils';
import { theme } from 'modules/ui/theme';
import { useGetCurrentBreakpointValue } from 'modules/ui/hooks';
import { Tooltip, generateTooltipHtml } from './tooltip';

export const Donut = ({
  size,
  data,
  highlightedKey,
  colorScheme,
  total,
  totalText,
  showTooltip
}) => {
  const ref = React.createRef();

  const currentBreakpointSize = useGetCurrentBreakpointValue(size) || 335;

  const thickness = currentBreakpointSize / 13;

  const radius = currentBreakpointSize / 2 - 20;

  useEffect(() => {
    d3.select(ref.current)
      .selectAll('g')
      .remove();

    d3.select(ref.current)
      .selectAll('text')
      .remove();

    const svg = d3
      .select(ref.current)
      .select('svg')
      .attr('width', currentBreakpointSize)
      .attr('height', currentBreakpointSize);

    const tooltip = d3.select(ref.current).select('#tooltip');

    const g = svg
      .append('g')
      .attr('transform', `translate(${currentBreakpointSize / 2},${currentBreakpointSize / 2})`);

    const arc = d3
      .arc()
      .innerRadius(radius - thickness)
      .outerRadius(d => {
        const extraThickness = highlightedKey === d.data.key ? 10 : 0;
        return radius + extraThickness;
      });

    const bigArc = d3
      .arc()
      .innerRadius(radius - thickness)
      .outerRadius(radius + 10);

    const pie = d3
      .pie()
      .value(d => d.overrideValue || d.value)
      .sort(null);

    g.selectAll('path')
      .data(pie(data))
      .enter()
      .append('g')
      .append('path')
      .attr('d', arc)
      .attr('fill', d => colorScheme[d.data.key])
      .on('mouseenter', function handleMouseMove(e, hoveredData) {
        if (!showTooltip) {
          return false;
        }

        d3.select(this).attr('d', bigArc);

        const { key, value, formatLabel } = hoveredData.data;

        const html = generateTooltipHtml(key, value, formatLabel);

        tooltip.classed('hidden', false).html(html);

        const tooltipSize = tooltip.node().getBoundingClientRect();

        tooltip.attr(
          'style',
          `left:${currentBreakpointSize / 2 - tooltipSize.width / 2}px;
            top:${currentBreakpointSize / 2 - tooltipSize.height / 2}px;`
        );

        return true;
      })
      .on('mouseleave', function handleMouseOut() {
        tooltip.classed('hidden', true);
        d3.select(this).attr('d', arc);
      });

    if (showTooltip && highlightedKey) {
      const highlightedData = data.find(d => d.key === highlightedKey);
      const { key, value, formatLabel } = highlightedData;

      const html = generateTooltipHtml(key, value, formatLabel);

      tooltip.classed('hidden', false).html(html);

      const tooltipSize = tooltip.node().getBoundingClientRect();

      tooltip.attr(
        'style',
        `left:${currentBreakpointSize / 2 - tooltipSize.width / 2}px;
          top:${currentBreakpointSize / 2 - tooltipSize.height / 2}px;`
      );
    }

    if (!highlightedKey) {
      tooltip.classed('hidden', true);
    }

    if (total && totalText && !showTooltip) {
      svg
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('x', currentBreakpointSize / 2)
        .attr('y', currentBreakpointSize / 2 + 10)
        .style('font-size', '64px')
        .style('font-family', theme.fonts.headingHeavy)
        .style('color', theme.colors.text.primary)
        .text(`${transformToDisplayNumber(total)}`);

      svg
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('x', currentBreakpointSize / 2)
        .attr('y', currentBreakpointSize / 2 + 10 + 24)
        .style('font-size', '14px')
        .style('font-family', theme.fonts.body)
        .style('font-weight', 700)
        .style('color', theme.colors.text.primary)
        .text(totalText);
    }
  }, [
    data,
    colorScheme,
    radius,
    thickness,
    ref,
    highlightedKey,
    total,
    totalText,
    currentBreakpointSize,
    showTooltip
  ]);

  return (
    <div ref={ref} style={{ position: 'relative' }}>
      <svg id="donut" />
      <Tooltip id="tooltip" className="hidden" />
    </div>
  );
};

Donut.propTypes = {
  data: PropTypes.array.isRequired,
  colorScheme: PropTypes.object.isRequired,
  total: PropTypes.number,
  totalText: PropTypes.string,
  highlightedKey: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  showTooltip: PropTypes.bool
};
