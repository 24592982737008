import React from 'react';

const ElectionCenterLayout = React.lazy(() => import('modules/electionCenter/layout'));
const ElectionCenterScreen = React.lazy(() => import('modules/electionCenter/screens'));

export default [
  {
    name: 'Election center',
    path: '/election-center',
    component: () => (
      <ElectionCenterLayout>
        <ElectionCenterScreen />
      </ElectionCenterLayout>
    )
  }
];
