import React from 'react';
import { Link } from 'wouter';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { Menu as MenuIcon, X as CloseIcon } from 'react-feather';
// import { useMenuState, Menu } from 'reakit/Menu';
import { useDisclosureState } from 'reakit/Disclosure';

import { useAuth } from 'modules/auth/useAuth';
import { Flex, Container, Stack, Box } from 'modules/ui/primitives';
// import logoWhiteSvg from 'assets/logoWhite.svg';
import newLogoWhite from 'assets/newLogoWhite.svg';
import { triggerGtmEvent } from 'modules/shared/services';
import { Dropdown } from '../dropdown';
import { MenuItemStyled } from '../dropdown/styles/dropdownStyles';
import { CanUserSee } from '../userRoles';
import { roles } from '../userRoles/constants';
import {
  // MenuButtonStyled,
  NavbarWrap,
  NavLink,
  LinkStyled,
  // MoreIconStyled,
  MobileMenuBaseTrigger,
  MobileMenuContentAnimated,
  Divider
} from './components';

// const MoreDropdown = () => {
//   const { t } = useTranslation();

//   const menu = useMenuState({
//     animated: 250,
//     placement: 'bottom-end',
//     gutter: 20
//   });
//   return (
//     <>
//       <MenuButtonStyled {...menu} active={menu.visible || false}>
//         <MoreIconStyled />
//       </MenuButtonStyled>
//       <Menu
//         id="profile-dropdown-menu"
//         {...menu}
//         aria-label="Open profile menu"
//         tabIndex={0}
//         style={{ outline: 'none', zIndex: '99', backgroundColor: '#030b46' }}
//       >
//         <Stack axis="vertical" minWidth="20rem" flexDirection="column" bg="white" py="2" px="2">
//           <MenuItemStyled
//             as="a"
//             href="https://supermajority.com/news/"
//             target="_blank"
//             id="profile-dropdown-item-news"
//             key="profile-dropdown-item-news"
//             onClick={() => {
//               triggerGtmEvent('Member experience', 'Click', 'News-Nav');
//             }}
//             {...menu}
//           >
//             News
//           </MenuItemStyled>
//           <MenuItemStyled
//             id="profile-dropdown-item-shop"
//             key="profile-dropdown-item-shop"
//             as="a"
//             href="https://store.supermajority.com/"
//             target="_blank"
//             onClick={() => {
//               triggerGtmEvent('Member experience', 'Click', 'Shop-Nav');
//             }}
//             {...menu}
//           >
//             {t('auth.shop', 'Shop')}
//           </MenuItemStyled>
//         </Stack>
//       </Menu>
//     </>
//   );
// };

export const Navbar = props => {
  const { fullWidth, location, px, bg } = props;
  const disclosure = useDisclosureState({ animated: true });
  const { visible } = disclosure;
  const { t } = useTranslation();
  const { user, logout } = useAuth();
  const { firstName } = user;

  const homeOrProfile = () =>
    location === 'account' ? (
      <MenuItemStyled as="a" href="/">
        {t('auth.home', 'Member Experience')}
      </MenuItemStyled>
    ) : (
      <MenuItemStyled as="a" href="/profile">
        {t('auth.account', 'Account')}
      </MenuItemStyled>
    );
  const homeOrAdmin = () =>
    location === 'admin' ? (
      <MenuItemStyled as="a" href="/">
        {t('auth.home', 'Member Experience')}
      </MenuItemStyled>
    ) : (
      <MenuItemStyled as="a" href="/admin">
        {t('admin.linkToAdmin', 'Admin Dashboard')}
      </MenuItemStyled>
    );

  const items = [
    homeOrProfile(),
    <CanUserSee allowedRoles={[roles.Admin, roles.AdminPlus]}>{homeOrAdmin()}</CanUserSee>,
    <MenuItemStyled as="a" href="/signout" onClick={logout}>
      {t('auth.signout', 'Sign out')}
    </MenuItemStyled>
  ];

  return (
    <NavbarWrap px={px} fullWidth={fullWidth} bg={bg}>
      <Container fullWidth={fullWidth}>
        <Flex px={[px === 2 ? 0 : 2, px === 2 ? 0 : 2, 0]} flexDirection={['row']}>
          <Link href="/">
            {/* <img src={logoWhiteSvg} alt="Supermajority" /> */}
            <img src={newLogoWhite} alt="Supermajority" style={{ marginTop: 7, height: 50 }} />
          </Link>
          <Flex flex={1} display={['none', 'flex']} px={2} className="hidden-mobile">
            <NavLink
              href="/action-center"
              onClick={() => {
                triggerGtmEvent('Member experience', 'Click', 'Action center-Nav');
              }}
            >
              {t('auth.linkToActionCenter', 'Action center')}
            </NavLink>
            <NavLink
              href="/election-center"
              onClick={() => {
                triggerGtmEvent('Member experience', 'Click', 'Vote-Nav');
              }}
            >
              {t('auth.linkToElectionCenter', 'Vote')}
            </NavLink>
            <LinkStyled
              href="https://secure.actblue.com/donate/supermajority-1?refcode=home_nav"
              target="_blank"
              onClick={() => {
                triggerGtmEvent('Member experience', 'Click', 'Donate-Nav');
              }}
            >
              {t('auth.donate', 'Donate')}
            </LinkStyled>
            <NavLink
              href="/profile/invite-a-friend"
              onClick={() => triggerGtmEvent('Friend', 'Invite', 'Intent to invite-Nav')}
            >
              {t('profile.inviteFriend.inviteFriend', 'Invite a Friend')}
            </NavLink>
            {/* <MoreDropdown /> */}
          </Flex>
          <Dropdown color="#fff" firstName={firstName} items={items} className="hidden-mobile" />
          <Flex flex={1} justifyContent="flex-end" className="hidden-desktop">
            <MobileMenuBaseTrigger {...disclosure}>
              <Flex>
                {visible ? (
                  <>
                    <MenuIcon color="#fff" height={18} />
                    <CloseIcon color="#fff" height={18} />
                  </>
                ) : (
                  <MenuIcon color="#fff" height={22} />
                )}
              </Flex>
            </MobileMenuBaseTrigger>
          </Flex>
        </Flex>
      </Container>
      {visible && (
        <MobileMenuContentAnimated id="dropdown-mobile-menu-content" {...disclosure} width="100%">
          <Box as="nav" bg="white" height="100%" width="100%" px="4" py="3">
            <Stack axis="vertical" minWidth="20rem" flexDirection="column" bg="white" py="2" px="2">
              <MenuItemStyled
                as={Link}
                href="/action-center"
                onClick={() => {
                  triggerGtmEvent('Member experience', 'Click', 'Action center-Nav');
                }}
              >
                {t('auth.linkToActionCenter', 'Action center')}
              </MenuItemStyled>
              <MenuItemStyled
                as={Link}
                href="/election-center"
                onClick={() => {
                  triggerGtmEvent('Member experience', 'Click', 'Vote-Nav');
                }}
              >
                {t('auth.linkToElectionCenter', 'Vote')}
              </MenuItemStyled>
              <MenuItemStyled
                as="a"
                href="https://secure.actblue.com/donate/supermajority-1?refcode=home_nav"
                target="_blank"
                id="profile-dropdown-item-donate"
                key="profile-dropdown-item-donate"
                onClick={() => {
                  triggerGtmEvent('Member experience', 'Click', 'Donate-Nav');
                }}
              >
                {t('auth.donate', 'Donate')}
              </MenuItemStyled>
              <MenuItemStyled
                as={Link}
                href="/profile/invite-a-friend"
                onClick={() => triggerGtmEvent('Friend', 'Invite', 'Intent to invite-Nav')}
              >
                {t('profile.inviteFriend.inviteFriend', 'Invite a Friend')}
              </MenuItemStyled>
              {/* <MenuItemStyled
                as="a"
                href="https://supermajority.com/news/"
                target="_blank"
                id="profile-dropdown-item-news"
                key="profile-dropdown-item-news"
                onClick={() => {
                  triggerGtmEvent('Member experience', 'Click', 'News-Nav');
                }}
              >
                News
              </MenuItemStyled>
              <MenuItemStyled
                id="profile-dropdown-item-shop"
                key="profile-dropdown-item-shop"
                as="a"
                href="https://store.supermajority.com/"
                target="_blank"
                onClick={() => {
                  triggerGtmEvent('Member experience', 'Click', 'Shop-Nav');
                }}
              >
                {t('auth.shop', 'Shop')}
              </MenuItemStyled> */}
              <Divider />
              {items}
            </Stack>
          </Box>
        </MobileMenuContentAnimated>
      )}
    </NavbarWrap>
  );
};

Navbar.propTypes = {
  fullWidth: PropTypes.bool,
  location: PropTypes.string,
  px: PropTypes.number,
  bg: PropTypes.string
};

Navbar.defaultProps = {
  fullWidth: false,
  location: 'home',
  px: 0
};
