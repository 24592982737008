import { keyframes } from 'styled-components';

const END_VALUE = '25px';
const START_VALUE = '0';

const fadeInUp = keyframes`
    from {
        filter: alpha(opacity=0);
        opacity: 0;
        -webkit-transform: translateY(${END_VALUE});
        -moz-transform: translateY(${END_VALUE});
        -ms-transform: translateY(${END_VALUE});
        -o-transform: translateY(${END_VALUE});
        transform: translateY(${END_VALUE});
    }
    to {
        filter: alpha(opacity=100);
        opacity: 1;
        -webkit-transform: translateY(${START_VALUE});
        -moz-transform: translateY(${START_VALUE});
        -ms-transform: translateY(${START_VALUE});
        -o-transform: translateY(${START_VALUE});
        transform: translateY(${START_VALUE});
    }
`;

export { fadeInUp };
