/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Icons } from 'modules/ui/assets';
import { Box, Text } from 'modules/ui/primitives';

export const CandidateIssueStance = ({ stances }) => {
  if (!stances) {
    return null;
  }

  return (
    <Box
      mt="5"
      p="4"
      borderColor="base.grey200"
      borderWidth="1px"
      borderStyle="solid"
      borderBottomLeftRadius="2"
      borderTopRightRadius="2"
      borderBottomRightRadius="2"
      style={{ position: 'relative' }}
    >
      <Box display="block" backgroundColor="base.white" position="absolute" top="-24px" left="-2px">
        <Icons.PolygonUp />
      </Box>
      <Text fontSize="3" fontWeight="bold" lineHeight="3" mb={2}>
        {stances.name}
      </Text>
      {stances?.list.length > 0 && (
        <>
          {stances.list.map(stance => {
            return (
              <Box key={stance.id} mb="2">
                <Text>{stance.description}</Text>
              </Box>
            );
          })}
        </>
      )}
    </Box>
  );
};

CandidateIssueStance.propTypes = {
  stances: PropTypes.object
};
