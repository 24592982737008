import { css } from 'styled-components';

export const backdropStyles = css`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 90;

  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 250ms ease-in-out;
  opacity: 0;
  &[data-enter] {
    opacity: 1;
  }
`;

export const dialogStyles = css`
  outline: none;
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
  opacity: 0;
  transform: translate3d(0, -40px, 0);

  &[data-enter] {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
