import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import { useLocalize } from 'modules/core/language';
import { Box, Flex, Text } from 'modules/ui/primitives';
import { firstQuestionPath } from 'modules/onboarding/questions';
import { Logo } from 'modules/auth/assets/images/logo';
import { Heading } from 'modules/ui/primitives/heading';
import { Button } from 'modules/ui/primitives/button';
import { Link } from 'modules/ui/primitives/link';
import { Background } from 'modules/ui/components/background';
import { FadeIn } from 'modules/ui/primitives/transitions/fadeIn';
import { Grid } from 'modules/ui/primitives/grid';
import { LanguageSelector } from 'modules/auth/screens/shared/languageSelector';
import { triggerGtmEvent } from 'modules/shared/services';
import bg from '../assets/images/startBg.jpg';
import placeholder from '../assets/images/startPlaceholder.jpg';

function OnboardingStartScreen() {
  const [, setLocation] = useLocation();
  const handleLetsStartClick = useCallback(() => {
    setLocation(firstQuestionPath());
    triggerGtmEvent('Profile Creation', 'Click', "Let's Start");
  }, [setLocation]);
  const { langState } = useLocalize();
  const { t } = useTranslation();

  const privacyLink =
    langState.currentLangKey === 'en'
      ? `${process.env.REACT_APP_SUPERMAJORITY_WEB_BASE_URL}/privacy-policy`
      : `${process.env.REACT_APP_SUPERMAJORITY_WEB_BASE_URL}/${langState.currentLangKey}/privacy-policy-2`;

  return (
    <Grid
      as="main"
      minHeight="100vh"
      overflow="hidden"
      gridTemplateColumns={['1fr', '4fr 4fr']}
      gridColumnGap={0}
    >
      <Flex
        as={FadeIn}
        bg="white"
        delay={1000}
        duration={500}
        justifyContent="center"
        alignItems="center"
        py={[7, 5]}
        overflowY="auto"
      >
        <Box bg="white" borderRadius="2" maxWidth="408px" margin="auto" px={[4, 5, 5, 0]}>
          <Box display={['block', 'none']}>
            <LanguageSelector />
          </Box>
          <Box
            bg="white"
            borderRadius="2"
            maxWidth={['100%', '41rem', '50rem']}
            margin="auto"
            px={[4, 5, 5, 0]}
          >
            <Logo title="Supermajority logo" />
            <Heading variant="h3" mt="3" mb="3">
              {t('onboarding.start.title', 'Help us get to know you')}
            </Heading>
            <Text mb="6" variant="body-2">
              {t(
                'onboarding.start.text',
                'Complete your profile and join hundreds of thousands of members who are building women’s power together. It should take just a few minutes!'
              )}
            </Text>
            <Button mb="6" width="100%" onClick={handleLetsStartClick}>
              {t('onboarding.start.buttonText', 'Get Started')}
            </Button>

            <Flex justifyContent="center">
              <Link to={privacyLink} variant="brand" display="block">
                {t('auth.privacyPolicy', 'Privacy Policy')}
              </Link>
            </Flex>
          </Box>
        </Box>
      </Flex>
      <Background as="section" display={['none', 'block']} src={bg} placeholder={placeholder} />
    </Grid>
  );
}

export { OnboardingStartScreen };
