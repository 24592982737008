import React from 'react';
import styled from 'styled-components';
import { DatePicker as AntDatePicker } from 'antd';

import 'antd/lib/date-picker/style/css';

import { datePickerStyle } from './style';

const { RangePicker: AntRangePicker } = AntDatePicker;

export const RangePicker = styled(props => (
  <AntRangePicker
    format="MMM D, YYYY"
    getPopupContainer={trigger => {
      return trigger;
    }}
    showToday={false}
    {...props}
  />
))`
  ${datePickerStyle}
`;
