import React from 'react';
import { Link } from 'wouter';
import { Box } from 'modules/ui/primitives';

function Navlinks({ routes }) {
  return routes.map(privateRoute => {
    if (privateRoute.name === undefined) return null;
    return (
      <Box px="2" as="span" key={`${privateRoute.path}-${privateRoute.name}`}>
        <Link href={privateRoute.path}>{privateRoute.name}</Link>
      </Box>
    );
  });
}

export default Navlinks;
