import { useAuth } from 'modules/auth/useAuth';
import { useCallback } from 'react';
import { useLocation } from 'wouter';

export const useRedirectAfterLogin = () => {
  const setLocation = useLocation()[1];

  const redirectAfterLogin = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('redirectAfterLogin')) {
      const path = urlParams.get('redirectAfterLogin');
      urlParams.delete('redirectAfterLogin', null);
      setLocation(`${path}?${urlParams.toString()}`);
    } else {
      setLocation(`/${window.location.search}`);
    }
  }, [setLocation]);

  return redirectAfterLogin;
};

export const useIsUserInRoles = () => {
  const { user } = useAuth();

  const isUserInRolesCallback = useCallback(
    (roles = []) => {
      if (!user.roles) return false;
      const hasAnyRoles = user.roles.some(userRole =>
        roles.some(r => r === parseInt(userRole.id, 10))
      );
      return hasAnyRoles;
    },
    [user.roles]
  );

  return isUserInRolesCallback;
};
