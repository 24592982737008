const defaults = {};

class Loader {
  constructor(services, options = {}, allOptions = {}) {
    this.services = services;
    this.options = options;
    this.allOptions = allOptions;
    this.type = 'backend';
    this.init(services, options, allOptions);
  }

  init(services, options = {}, allOptions = {}) {
    this.services = services;
    this.options = {
      ...defaults,
      ...this.options,
      ...options
    };
    this.allOptions = allOptions;
  }

  async read(language, namespace, callback) {
    if (this.allOptions.initImmediate === false) {
      try {
        const module = await import(`assets/locales/${language}/translation.json`);
        callback(null, { ...module.default });
      } catch (err) {
        callback(err, false);
      }
    }
  }
}

Loader.type = 'backend';

export default Loader;
