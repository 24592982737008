import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const START_VALUE = 50;
const END_VALUE = 0;

function getTranslateValue(orientation) {
  return orientation === 'up' ? `${START_VALUE}px` : `-${START_VALUE}px`;
}

const FadeInTriggerStyled = styled.div`
  position: relative;
  z-index: 2;
  transition: all ${props => props.duration}ms ease-in;
  transition-delay: ${props => props.delay}ms;
  transition-timing-function: cubic-bezier(0.25, 0.75, 0.5, 1.25);
  opacity: ${props => (props.show ? 1 : 0)};
  transform: translateY(
    ${props => (props.show ? END_VALUE : getTranslateValue(props.orientation))}
  );
`;

const FadeInTrigger = ({ show, delay, orientation, duration, children }) => {
  return (
    <FadeInTriggerStyled show={show} delay={delay} duration={duration} orientation={orientation}>
      {children}
    </FadeInTriggerStyled>
  );
};

FadeInTrigger.propTypes = {
  show: PropTypes.bool,
  delay: PropTypes.number,
  duration: PropTypes.number,
  children: PropTypes.any,
  orientation: PropTypes.oneOf(['up', 'down'])
};

FadeInTrigger.defaultProps = {
  delay: 0,
  duration: 300,
  orientation: 'up'
};

export { FadeInTrigger };
