import React, { Suspense } from 'react';

import { AuthLayout, HomeLayout } from 'modules/shared/layouts';
import { ChunkLoading } from 'modules/shared/components';
import { useAuth } from 'modules/auth/useAuth';

import { publicRoutes, privateRoutes } from './modules';

export default function App() {
  const { user, loading } = useAuth();

  return (
    <Suspense fallback={<ChunkLoading />}>
      {user && user.loggedIn && !loading && <HomeLayout routes={privateRoutes} />}
      {user && !user.loggedIn && !loading && <AuthLayout routes={publicRoutes} />}
    </Suspense>
  );
}
