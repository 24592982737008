import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMenuBarState, MenuBar } from 'reakit/Menu';
import { Trail } from 'react-spring/renderprops';
import { Box } from 'modules/ui/primitives/box';
import { Link } from 'modules/ui/primitives/link';
import { useIsUserInRoles } from 'modules/auth/shared';
import { AdminMenuItemStyled } from './styles/sidebarStyles';

const config = { mass: 5, tension: 2000, friction: 200 };

export const AdminSidebar = ({ items }) => {
  const menuBar = useMenuBarState({
    orientation: 'vertical',
    loop: 'vertical'
  });

  const isUserInRoles = useIsUserInRoles();

  const allowedItems = useMemo(
    () =>
      items.filter(item => {
        if (!item.roles) return true;

        return isUserInRoles(item.roles);
      }),
    [isUserInRoles, items]
  );

  return (
    <Box as="aside" bg="white" display={['none', 'none', 'flex']} p={[1, 1]} minHeight="100vh">
      <MenuBar {...menuBar} style={{ width: '100%' }}>
        <Trail
          config={config}
          items={allowedItems}
          keys={item => item.href}
          delay={0}
          from={{ opacity: 0, transform: 'translate3d(0, 30px,0)' }}
          to={{ opacity: 1, transform: 'translate3d(0,0px,0)' }}
        >
          {(item, i) => props => (
            <AdminMenuItemStyled
              id={`sidebar-item-${i}`}
              key={item.href}
              as={Link}
              variant="nav"
              to={item.to}
              href={item.href}
              style={props}
            >
              {item.icon ? item.icon : null}
              {item.label}
            </AdminMenuItemStyled>
          )}
        </Trail>
      </MenuBar>
    </Box>
  );
};

AdminSidebar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  )
};

export default AdminSidebar;
