import React, { forwardRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Text } from '../text';
import { Box } from '../box';

const StyledLabel = styled(Text)`
  display: flex;
  width: 100%;
  align-items: center;

  ${props =>
    props.required &&
    `&:after {
      content: "*";
      color: ${props.theme.colors.action.pink};
    }`}
`;

StyledLabel.defaultProps = {
  pl: 0,
  pr: 0
};

export const Label = forwardRef(({ children, as, ...props }, ref) => (
  <StyledLabel as={as} ref={ref} variant="body-1" color="text.secondary" {...props}>
    {children}
    {props.adornment && (
      <Box display="inline-flex" alignItems="center" color="text.secondary" ml={1}>
        <Text variant="body-1" color="text.secondary">
          {props.adornment}
        </Text>
      </Box>
    )}
  </StyledLabel>
));

Label.propTypes = {
  children: PropTypes.any,
  as: PropTypes.string,
  adornment: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  required: PropTypes.bool
};

Label.defaultProps = {
  required: false,
  as: 'label'
};
