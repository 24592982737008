import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTagsInput from 'react-tagsinput';
import 'antd/dist/antd.css';
import { focusRingInput } from 'modules/ui/primitives/shared/focusRing';
import { Tooltip } from 'modules/ui/components/tooltip';

import { Box, Button, Flex, FormControl, Heading, Label, Text } from 'modules/ui/primitives';
import { useTranslation } from 'react-i18next';
import { sendInvites } from 'modules/profile/api';
import { triggerGtmEvent } from 'modules/shared/services';
import { Controller, useForm } from 'react-hook-form';
import { Tag as AntdTag } from 'antd';
import { ProfileSection } from './profileSection';
import { CopyInviteLink } from './copyInviteLink';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const TagStyled = styled(AntdTag)`
  font-size: ${props => props.theme.fontSizes[3]};
  line-height: ${props => props.theme.lineHeights[3]};
`;

const TagsInputStyled = styled(ReactTagsInput)`
  max-width: 100vw;
  overflow-x: auto;
  background-color: ${props => props.theme.colors.base.grey100};
  border-radius: 4px;
  padding: ${props => props.theme.space[1]}px;
  .react-tagsinput-input_sm {
    background-color: ${props => props.theme.colors.base.grey100};
    color: ${props => props.theme.colors.text.primary};
    background-color
    font-size: ${props => props.theme.fontSizes[3]};
    line-height: ${props => props.theme.lineHeights[3]};
    background: transparent;
    border: 0;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    min-width: ${props => (props.value.length ? '0' : '100%')};
    ::placeholder {
      color: ${props => props.theme.colors.text.disabled};
      font-size: ${props => props.theme.fontSizes[3]};
      line-height: ${props => props.theme.lineHeights[3]};
    }
    .focus-visible {
      background-color: ${props => props.theme.colors.base.white};
      font-size: ${props => props.theme.fontSizes[3]};
      line-height: ${props => props.theme.lineHeights[3]};
    }
  }

  &.react-tagsinput--focused {
    ${focusRingInput}
    background-color: ${props => props.theme.colors.base.white};
    border-color: ${props => props.theme.colors.action.pink};
    font-size: ${props => props.theme.fontSizes[3]};
    line-height: ${props => props.theme.lineHeights[3]};

`;

const TagsInput = ({ ...props }) => {
  const renderTag = useCallback(tagProps => {
    const { tag, key, onRemove, getTagDisplayValue, ...otherTagProps } = tagProps;
    const tagValue = getTagDisplayValue(tag);
    return (
      <TagStyled
        key={key}
        color={!emailRegex.test(tagValue) ? 'error' : 'default'}
        closable
        onClose={() => onRemove(key)}
        style={{ marginRight: 3 }}
        {...otherTagProps}
      >
        {tagValue}
      </TagStyled>
    );
  }, []);

  const { placeholder, value, ...other } = props;

  return (
    <TagsInputStyled
      {...other}
      value={value}
      renderTag={renderTag}
      addKeys={[9, 13, 32, 188]}
      onlyUnique
      inputProps={{
        className: 'react-tagsinput-input_sm',
        placeholder: !value.length ? placeholder : null
      }}
    />
  );
};

TagsInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.array
};

const useSendInvitesNotifications = () => {
  const [notifications, setNotifications] = useState([]);

  const addNotifications = useCallback((...newNotifications) => {
    setNotifications(notificationsArray => [...notificationsArray, ...newNotifications]);
  }, []);

  const clearNotifications = useCallback(() => {
    setNotifications([]);
  }, []);

  return { notifications, addNotifications, clearNotifications };
};

export default function SendInvites() {
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { isSubmitting, dirty },
    reset,
    control
  } = useForm();

  const { notifications, addNotifications, clearNotifications } = useSendInvitesNotifications();

  const handleSubmitCallback = useCallback(
    handleSubmit(async ({ inviteeEmails }) => {
      clearNotifications();

      const validEmails = inviteeEmails.filter(email => emailRegex.test(email));

      const sendIvitesResult = await sendInvites(validEmails);
      reset({ inviteeEmails: [] });
      triggerGtmEvent('Friend', 'Invite', 'Send invitations');

      const failed = sendIvitesResult.filter(r => Boolean(r.error));
      const failedCount = failed.length;
      const succeddedCount = sendIvitesResult.filter(r => !r.error).length;
      if (succeddedCount) {
        addNotifications({
          text:
            succeddedCount > 1
              ? t('profile.inviteFriend.invitationsSent', {
                  defaultValue: '{{count}} invitations sent. Women all in!',
                  count: succeddedCount
                })
              : t('profile.inviteFriend.invitationSent', 'Invitation sent. Women all in!'),
          key: 'invitations-sent-message'
        });
      }

      if (failedCount) {
        const failedEmails = failed.map(r => r.email).join(', ');
        addNotifications({
          text: t('profile.inviteFriend.invitationsFailed', {
            defaultValue: '{{emails}} is already a member (yay!)',
            emails: failedEmails
          }),
          key: 'invitations-failed-message'
        });
      }
    }),
    []
  );

  return (
    <ProfileSection>
      <Flex flexDirection="column">
        <Heading as="h2" variant="h2Bold" mb={3}>
          {t('profile.inviteFriend.inviteFriend', 'Invite a friend')}
        </Heading>
        <Text mb={4}>
          {t(
            'profile.inviteFriend.importantToInvite',
            'Having your friends join our community is an important part of mobilizing our women to women voter engagement program!'
          )}
        </Text>
        {Boolean(notifications.length) && (
          <Box bg="action.pinkLight2" p={2} mb={3}>
            {notifications.map(notification => (
              <Text mb={1} key={notification.key}>
                {notification.text}
              </Text>
            ))}
          </Box>
        )}
        <form onSubmit={handleSubmitCallback}>
          <FormControl.Field mb="3">
            <Flex>
              <Label htmlFor="inviteeEmails">
                {t('profile.inviteFriend.inviteByEmail', 'Enter email addresses')}
              </Label>
              <Tooltip
                id="tooltip-content-for-invitees-emails"
                title={t(
                  'profile.inviteFriend.inviteesEmailsTooltip',
                  'Inviting your friends will NOT add them to our email list.'
                )}
                placement="left"
              />{' '}
            </Flex>
            <Controller
              as={TagsInput}
              id="inviteeEmails"
              name="inviteeEmails"
              defaultValue={[]}
              placeholder={t(
                'profile.inviteFriend.emailsInputPlaceholder',
                'Use a comma, spacebar, or return key to add more email addresses.'
              )}
              control={control}
            />
          </FormControl.Field>
          <Flex justifyContent="flex-end" mb="4">
            <Button
              id="send-invitations-button"
              type="submit"
              disabled={!dirty}
              isLoading={isSubmitting}
            >
              {t('profile.inviteFriend.sendInvites', 'Send Invites')}
            </Button>
          </Flex>
        </form>
        <CopyInviteLink />
      </Flex>
    </ProfileSection>
  );
}
