import * as apiClient from 'modules/core/apiClient';
import { schema } from 'modules/core/schema';
import { httpMethods } from 'modules/core/constants';
import { authKeys } from './constants';

export const addHeader = (key, value) => apiClient.addHeader(key, value);

export const removeHeader = key => apiClient.removeHeader(key);

export const getRefreshToken = async encodedAuth => {
  const { data } = await apiClient.post(
    schema.tokens.endpoint,
    {
      force: true,
      headers: { [authKeys.AUTHORIZATION_KEY]: `Basic ${encodedAuth}` }
    },
    { tokenType: 'refresh' },
    httpMethods.POST,
    schema.tokens.type
  );
  return data;
};

export const getAccessToken = async token => {
  const { data } = await apiClient.post(
    schema.tokens.endpoint,
    {
      force: true,
      headers: { [authKeys.AUTHORIZATION_KEY]: `Bearer ${token}` }
    },
    { tokenType: 'access' },
    httpMethods.POST,
    schema.tokens.type
  );
  return data;
};

export const getRefreshTokenWithGoogle = async googleToken => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const tokenData = {
    token: googleToken,
    utmSource: params.utmSource,
    utmMedium: params.utmMedium,
    utmCampaign: params.utmCampaign,
    utmContent: params.utmContent
  };
  const { data } = await apiClient.post(
    schema['google-token'].endpoint,
    {
      force: true
    },
    tokenData,
    httpMethods.POST,
    schema['google-token'].type
  );
  return data;
};

export const signUp = async values => {
  const { data } = await apiClient.post(
    schema.users.endpoint,
    { force: true },
    { ...values },
    httpMethods.POST,
    schema.users.type
  );
  return data;
};

// export const verify = async code => {
//   const { data } = await apiClient.post(
//     schema.verify.endpoint,
//     { force: true },
//     { code },
//     httpMethods.POST,
//     schema.verify.type
//   );
//   return data;
// };

// export const resendEmail = async email => {
//   apiClient.post(
//     schema['verification-request'].endpoint,
//     { force: true },
//     { email },
//     httpMethods.POST,
//     schema['verification-request'].type
//   );
// };

export const forgotPassword = async email =>
  apiClient.post(
    schema['request-password-reset'].endpoint,
    { force: true },
    { email },
    httpMethods.POST,
    schema['request-password-reset'].type
  );

export const resetPassword = async (code, newPassword) =>
  apiClient.post(
    schema['password-reset'].endpoint,
    { force: true },
    { code, newPassword },
    httpMethods.POST,
    schema['password-reset'].type
  );

export const getProfile = async () => {
  const { data } = await apiClient.get(schema['user-profiles'].endpoint);
  return data;
};

export const getUser = async id => {
  const { data } = await apiClient.get(`${schema.users.endpoint}/${id}?include=roles`);
  return data;
};
