import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { filter, findLast, uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { deepCopy } from 'modules/core/utils';
import { useProfile } from 'modules/profile/profileProvider';
import { useGetCandidates, useGetPositions, useGetMeasures } from './api';

const BallotDataContext = React.createContext();

export const BallotDataProvider = ({ children }) => {
  const { t } = useTranslation();
  const [{ data: profileData }] = useProfile();
  const {
    data: positionsData,
    error: positionsError,
    isLoading: positionsLoading,
    refetch: refetchPositions
  } = useGetPositions(t);
  const {
    data: measuresData,
    error: measuresError,
    isLoading: measuresLoading,
    refetch: refetchMeasures
  } = useGetMeasures();

  const [{ positions, measures, totalCount, totalFavoritesCount }, setMappedData] = useState({
    positions: [],
    measures: [],
    totalCount: 0,
    totalFavoritesCount: 0
  });

  const favoritesCount = useRef(0);
  const positionsCount = useRef(0);
  const emptyResponse = useRef(false);

  const candidateData = {
    firstName: '',
    lastName: '',
    thumbUrl: '#',
    partyName: '',
    urls: [],
    experience: [],
    education: [],
    issues: [],
    endorsements: []
  };

  // https://developers.civicengine.com/docs/api/positions/list
  // Notes on Levels
  // We have six primary "levels" for a position

  // federal - Federal positions (President, Vice President, US House, US Senate)
  // state - State level positions, executive and legislative
  // county - County level positions such as commissioners
  // city (introduced 2020) - Incorporated municipalities and places
  // local - Townships + Un-incorproated local positions
  // party - Intra-party positions
  const getMappedData = useCallback(
    (dataPositions, dataMeasures = []) => {
      if (dataPositions.length === 0) {
        emptyResponse.current = true;
        return { positions, measures, totalCount };
      }

      const levels = {
        FEDERAL: 'federal',
        COUNTY: 'state',
        STATE: 'county',
        CITY: 'city',
        LOCAL: 'local',
        PARTY: 'party'
      };
      const categories = {
        PRESIDENT: 10,
        VICEPRESIDENT: 11,
        GOVERNOR: 100,
        VICEGOVERNOR: 110
      };
      favoritesCount.current = 0;
      positionsCount.current = 0;
      const leveledPositions = [];
      let positionsMapped = dataPositions.filter(position => {
        if (position.state === 'GA') {
          return position.runoffDate && position.runoffDate === '2021-01-05';
        }

        if (position.state === 'US') return false;

        return true;
      });
      let presidentPosition = {};
      let vicePresidentPosition = {};
      let presidentAndVpCandidates = [];

      const presidentCategory = categories.PRESIDENT;
      const vicePresidentCategory = categories.VICEPRESIDENT;

      presidentPosition = positionsMapped.find(p => p.normalizedPosition.id === presidentCategory);

      vicePresidentPosition = positionsMapped.find(
        p => p.normalizedPosition.id === vicePresidentCategory
      );

      presidentAndVpCandidates = presidentPosition?.candidates?.map(pres => {
        const presCandidates = pres;
        const vicePresident = vicePresidentPosition?.candidates?.find(
          vp => vp.partyName === pres.partyName
        );
        return [presCandidates].concat(vicePresident);
      });

      if (presidentPosition) {
        presidentPosition.candidates = presidentAndVpCandidates;
        presidentPosition.isPresident = true;
      }

      const restPositions = positionsMapped.filter(
        p =>
          p.normalizedPosition.id !== presidentCategory &&
          p.normalizedPosition.id !== vicePresidentCategory
      );
      positionsMapped = [presidentPosition].concat(restPositions);

      positionsMapped = positionsMapped.filter(pos => !!pos);

      const getLeveledPositions = () => {
        let id = 0;
        Object.keys(levels).forEach(item => {
          const filteredPositions = positionsMapped.filter(
            pos => pos?.level?.toLowerCase() === levels[item]
          );
          if (filteredPositions.length > 0) {
            let favorites = [];
            const leveledPosition = {
              id: (id += 1),
              name: `${levels[item][0].toUpperCase()}${levels[item].slice(1)} ${t(
                'ballotGuide.candidates',
                'Candidates'
              )}`,
              path: levels[item],
              subCategories: filteredPositions.map(
                ({ candidates, isPresident, positionId, ...levPos }) => {
                  const candidatesWithPath = candidates?.map(cnd => {
                    const candidate = cnd;
                    if (cnd?.length === 2) {
                      const [first = candidateData, second = candidateData] = cnd;
                      candidate.id = first.id; // take only president id
                      candidate.path = `president/${first.electionId}/${first.id}/${second.id}`;
                      candidate.partyName = first.partyName;
                      candidate[0] = first;
                      candidate[1] = second;
                    } else {
                      candidate.id = cnd.id;
                      candidate.path = `${levels[item]}-${levPos.normalizedPosition.id}-${positionId}/${cnd.id}/${cnd.electionId}`;
                    }

                    const duplicateCandidate = findLast(candidates, c =>
                      isPresident
                        ? c[0].id === candidate.id && c[0].partyName !== candidate.partyName
                        : c.id === candidate.id && c.partyName !== candidate.partyName
                    );

                    if (
                      duplicateCandidate &&
                      (isPresident
                        ? duplicateCandidate[0].partyName !== candidate.partyName
                        : duplicateCandidate.partyName !== candidate.partyName)
                    ) {
                      candidate.partyName += `, ${
                        isPresident ? duplicateCandidate[0].partyName : duplicateCandidate.partyName
                      }`;
                    }

                    return candidate;
                  });

                  const result = profileData?.ballotData?.positions?.filter(
                    pos => pos.positionId === positionId
                  );
                  if (result && result.length > 0) {
                    result.forEach(res => {
                      const favoritedCandidates = candidatesWithPath.filter(
                        cp => cp.id === res.favoriteCandidateId
                      );
                      if (favoritedCandidates) {
                        favorites.push(...favoritedCandidates);
                        favoritesCount.current += 1;
                      }
                    });
                  }

                  const subCategory = {
                    ...levPos,
                    id: positionId,
                    path: isPresident
                      ? 'president'
                      : `${levels[item]}-${levPos.normalizedPosition.id}-${positionId}`,
                    candidates: uniqBy(candidatesWithPath, 'id'),
                    favorites
                  };

                  favorites = [];
                  return subCategory;
                }
              )
            };

            // // REMOVE POSITIONS WITH NO CANDIDATES
            // leveledPosition.subCategories = leveledPosition.subCategories.filter(
            //   ({ candidates }) => candidates.length > 0
            // );

            if (leveledPosition.subCategories.length > 0) {
              positionsCount.current += leveledPosition.subCategories.length;
              leveledPositions.push(leveledPosition);
            }
          }
        });
        return leveledPositions;
      };

      const returnData = {
        positions: leveledPositions.length === 0 && getLeveledPositions(),
        measures: dataMeasures?.map(measure => {
          const mappedMeasure = measure;
          mappedMeasure.path = `measures/${measure.id}`;
          const votingPreference = profileData?.ballotData?.measures?.find(
            fav => fav.measureId === measure.id
          );
          if (votingPreference) {
            mappedMeasure.votingPreference = votingPreference;
            favoritesCount.current += 1;
          }

          return mappedMeasure;
        })
      };

      if (returnData.positions.length === 0) {
        emptyResponse.current = true;
      }

      return {
        ...returnData,
        totalCount: Number(positionsCount.current + dataMeasures?.length) || 0,
        totalFavoritesCount: Number(favoritesCount.current) || 0
      };
    },
    [positions, measures, totalCount, t, profileData, candidateData]
  );

  useEffect(() => {
    if (
      !positionsLoading &&
      !measuresLoading &&
      !positionsError &&
      !measuresError &&
      (!positions || positions.length === 0) &&
      !emptyResponse.current
    ) {
      setMappedData({ ...getMappedData(deepCopy(positionsData), deepCopy(measuresData)) });
    }
  }, [
    setMappedData,
    getMappedData,
    positionsData,
    positionsLoading,
    positions,
    positionsError,
    measuresData,
    measuresLoading,
    measuresError
  ]);

  useEffect(() => {
    if (
      profileData &&
      profileData.ballotData &&
      positions &&
      (profileData.ballotData.measures || profileData.ballotData.positions) &&
      positions.length > 0
    ) {
      const { ballotData } = profileData;

      const filteredMeasures = filter(
        ballotData.measures,
        m => measures && measures.find(measure => measure.id === m.measureId)
      );
      const filteredPositions = filter(
        ballotData.positions,
        p =>
          positions &&
          positions.find(position => position.subCategories.find(sc => sc.id === p.positionId))
      );

      const measuresCount = filteredMeasures.length;
      const positionFavoritesCount = filteredPositions.length;
      const userFavoritesCount = measuresCount + positionFavoritesCount;

      if (totalFavoritesCount !== userFavoritesCount) {
        setMappedData({
          ...getMappedData(deepCopy(positionsData), []),
          totalCount,
          totalFavoritesCount: userFavoritesCount
        });
      }
    }
  }, [
    measures,
    positions,
    profileData,
    totalCount,
    totalFavoritesCount,
    getMappedData,
    positionsData
  ]);

  const useGetCandidateDetails = (candidateId, electionId) =>
    useGetCandidates(candidateId, electionId);

  const getMeasureDetails = measureId => {
    return measures?.find(measure => measure.id === measureId);
  };

  const resetData = async () => {
    setMappedData({ ...getMappedData(deepCopy(positionsData), []) });
  };

  const refetchData = async () => {
    await refetchPositions();
    await refetchMeasures();

    setMappedData({ positions: [], measures: [] });
  };

  return (
    <BallotDataContext.Provider
      value={[
        {
          useGetCandidateDetails,
          getMeasureDetails,
          refetchData,
          resetData,
          data: {
            positions,
            measures,
            totalCount,
            totalFavoritesCount
          },
          loadingError: positionsError,
          isLoading: positionsLoading && measuresLoading
        }
      ]}
    >
      {children}
    </BallotDataContext.Provider>
  );
};

export function useBallotData() {
  const context = useContext(BallotDataContext);

  if (!context)
    throw new Error(
      'useContext can only be called from a component wrrapped within a BallotDataProvider.'
    );

  return context;
}
BallotDataProvider.propTypes = {
  children: PropTypes.element
};
