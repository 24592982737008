import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'modules/ui/primitives';
import objectToURLParams from 'modules/core/utils';

const twitterShareURL = 'https://twitter.com/share';

export default function TwitterShareLink({
  url,
  title,
  via,
  hashtags,
  related,
  onClick,
  children
}) {
  const toURL = `${twitterShareURL}${objectToURLParams({
    url,
    text: title,
    via,
    hashtags: hashtags.length > 0 ? hashtags.join(',') : undefined,
    related: related.length > 0 ? related.join(',') : undefined
  })}`;

  return (
    <Link to={toURL} external onClick={onClick}>
      {children}
    </Link>
  );
}

TwitterShareLink.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  via: PropTypes.string,
  hashtags: PropTypes.arrayOf(PropTypes.string),
  related: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
  children: PropTypes.any
};

TwitterShareLink.defaultProps = {
  hashtags: [],
  related: []
};
