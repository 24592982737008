import { useQuery } from 'jsonapi-react';
import { schema } from 'modules/core/schema';

export const useGetCandidates = (id, electionId) => {
  return useQuery([`${schema['elections-candidates'].endpoint}`, { id, electionId }]);
};

// const electionId = 4975;
const electionDate = '2021-11-02';

export const useGetMeasures = () => {
  return useQuery([`${schema['election-measures'].endpoint}`, { electionDate }]);
};

export const useGetPositions = () => {
  return useQuery([`${schema['election-positions'].endpoint}`, { electionDate }]);
};
