import React from 'react';
import { Box, Heading } from 'modules/ui/primitives';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import whoShouldIInviteBottom from '../assets/images/whoShouldIInviteBottom.png';

const StyledList = styled.ul`
  list-style-type: disc;
  margin-bottom: ${props => props.theme.space[4]}px;
  padding: ${props => props.theme.space[2]}px;
  color: ${props => props.theme.colors.text.secondary};
  font-size: ${props => props.theme.fontSizes[3]};
  line-height: ${props => props.theme.lineHeights[3]};

  li {
    margin-bottom: ${props => props.theme.space[3]}px;
  }
`;

export default function WhoShouldIInvite() {
  const { t } = useTranslation();

  return (
    <Box
      as="section"
      mb={3}
      bg="action.pinkLight2"
      maxWidth={['100%', '75%']}
      flexDirection="column"
    >
      <Box px={[4, 6]} pt={[4, 7]}>
        <Heading as="h2" variant="h2Bold" mb={3}>
          {t(
            'profile.inviteFriend.whoShouldIInvite.title',
            'Who should I invite to Supermajority?'
          )}
        </Heading>
        <StyledList>
          <li>
            {t(
              'profile.inviteFriend.whoShouldIInvite.friendsAndFamily',
              'Friends and family in other states - especially swing states!'
            )}
          </li>
          <li>
            {t(
              'profile.inviteFriend.whoShouldIInvite.lessEngagedFriends',
              'Your "less engaged" friends - now\'s your chance to give them the tools they need'
            )}
          </li>
          <li>
            {t(
              'profile.inviteFriend.whoShouldIInvite.moreEngagedFriends',
              'Your "more engaged" friends - we\'ll help them find even more ways to get involved'
            )}
          </li>
          <li>
            {t(
              'profile.inviteFriend.whoShouldIInvite.peopleAroundYou',
              'People around you - anyone from your hairdresser to your next-door neighbor'
            )}
          </li>
        </StyledList>
      </Box>
      <img src={whoShouldIInviteBottom} alt="" />
    </Box>
  );
}
