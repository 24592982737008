import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledBullet = styled.li`
  cursor: pointer;
  height: ${props => props.theme.sizes[0]}px;
  width: ${props => props.theme.sizes[0]}px;
  background-color: ${props => props.theme.colors.action.orange};
  border-radius: ${props => props.theme.radii[3]}px;
  display: inline-block;
  margin: 0 3px;
  background-color: ${props =>
    props.active ? props.theme.colors.action.orange : props.theme.colors.base.grey200};
`;

export const Bullet = ({ index, BulletComponent, setSlide, activeIndex, bulletStyle }) => {
  const updateSlide = () => {
    setSlide(index);
  };

  if (BulletComponent) {
    return <BulletComponent key={index} isActive={index === activeIndex} onClick={updateSlide} />;
  }

  return (
    <StyledBullet
      active={index === activeIndex}
      key={index}
      style={bulletStyle}
      onClick={updateSlide}
    />
  );
};

Bullet.propTypes = {
  BulletComponent: PropTypes.node,
  bulletStyle: PropTypes.any,
  index: PropTypes.number,
  setSlide: PropTypes.func,
  activeIndex: PropTypes.number
};

Bullet.defaultProps = {
  BulletComponent: undefined,
  bulletStyle: {}
};
