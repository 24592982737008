import React, { useState, useCallback, useContext, createContext, useEffect, useRef } from 'react';
import { useLocation } from 'wouter';
import PropTypes from 'prop-types';
import { useClient } from 'jsonapi-react';
import { getCurrentLanguage } from 'modules/core/language';
import { HIDE_ONBOARDING_PROGRESS } from 'modules/shared/constants';
import * as api from './api';
import { authKeys } from './constants';

const getRefreshToken = async encodedAuth => api.getRefreshToken(encodedAuth);

const getAccessToken = async token => api.getAccessToken(token);

const storeItem = (key, token) => window.localStorage.setItem(key, token);

const getItem = key => window.localStorage.getItem(key);

const deleteItem = key => window.localStorage.removeItem(key);

const AuthContext = createContext();

export const AuthorizationProvider = props => {
  const client = useClient();
  const setLocation = useLocation()[1];
  const initialLoad = useRef(true);
  const [user, setUser] = useState({ loggedIn: false, roles: [] });
  const [loading, setLoading] = useState(initialLoad.current);

  const setUserState = useCallback(data => {
    setUser(prevState => {
      return { ...prevState, ...data };
    });
  }, []);

  const logout = useCallback(() => {
    deleteItem(authKeys.REFRESH_TOKEN_KEY);
    deleteItem(HIDE_ONBOARDING_PROGRESS);
    setUser({ loggedIn: false });
    setLoading(false);

    api.removeHeader(authKeys.AUTHORIZATION_KEY);
    setLocation('/auth/signin');
  }, [setLocation]);

  const forgotPassword = async email => api.forgotPassword(email);

  const resetPassword = async ({ code, newPassword }) => api.resetPassword(code, newPassword);

  const getProfile = async () => api.getProfile();

  // const resendEmail = async email => api.resendEmail(email);

  const getUser = async id => api.getUser(id);

  const signup = async values => {
    const userData = await api.signUp({ language: getCurrentLanguage(), ...values });
    setUser({ ...user, ...userData });
    setLoading(false);
  };

  const readToken = token => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');

    return JSON.parse(window.atob(base64));
  };

  // const verify = useCallback(
  //   async code => {
  //     try {
  //       const userData = await api.verify(code);
  //       setUser({ ...user, ...userData });
  //     } catch (error) {
  //       // console.log(error);
  //     }
  //   },
  //   [user]
  // );

  // authorize with refresh token
  const authorize = useCallback(async () => {
    try {
      setLoading(true);
      const refreshToken = getItem(authKeys.REFRESH_TOKEN_KEY);
      const newAccessToken = await getAccessToken(refreshToken);

      api.addHeader(authKeys.AUTHORIZATION_KEY, `Bearer ${newAccessToken.token}`);

      const userData = await getProfile();

      const { userId } = readToken(newAccessToken.token);
      const currentUser = await getUser(userId);
      const { roles } = currentUser;

      const userState = {
        ...user,
        ...userData,
        roles: [...roles],
        loggedIn: Boolean(newAccessToken.token)
      };
      setUser(userState);
      setLoading(false);

      return userState;
    } catch (error) {
      logout();
      return false;
    }
  }, [logout, user]);

  const login = useCallback(
    async ({ email, password }) => {
      const encodedAuth = btoa(`${email}:${password}`);

      const refreshToken = await getRefreshToken(encodedAuth);
      storeItem(authKeys.REFRESH_TOKEN_KEY, refreshToken.token);

      return authorize();
    },
    [authorize]
  );

  const loginWithGoogle = useCallback(
    async googleToken => {
      const refreshToken = await api.getRefreshTokenWithGoogle(googleToken);
      storeItem(authKeys.REFRESH_TOKEN_KEY, refreshToken.token);

      const authorizeResponse = await authorize();

      return { ...authorizeResponse, signedUp: refreshToken.created || false };
    },
    [authorize]
  );

  client.subscribe(async req => {
    if (req.error && req.error.status === '401') {
      switch (req.type) {
        case 'RECEIVE_QUERY':
          await authorize();
          // refetch failed query
          // eslint-disable-next-line no-case-declarations
          const { error } = await client.fetch(req.query);

          if (error) {
            logout();
          }
          break;
        // case 'RECEIVE_MUTATION':
        //   logout();
        //   break;
        default:
          logout();
          break;
      }
    }
  });

  useEffect(() => {
    if (getItem(authKeys.REFRESH_TOKEN_KEY) && !user.loggedIn && initialLoad.current) {
      initialLoad.current = false;
      authorize();
    } else if (!getItem(authKeys.REFRESH_TOKEN_KEY) && !user.loggedIn && initialLoad.current) {
      // setLocation('/auth/signin');
      setLoading(false);
    }
  }, [user, authorize, loading, setLocation]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUserState,
        loading,
        login,
        loginWithGoogle,
        logout,
        signup,
        // verify,
        // resendEmail,
        forgotPassword,
        resetPassword,
        getProfile
      }}
      {...props}
    />
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

AuthorizationProvider.propTypes = {
  children: PropTypes.element
};
