export const defaultButtonStyles = {
  position: 'relative',
  fontWeight: 'regular',
  fontFamily: 'body',
  fontSize: 3,
  lineHeight: 3,
  maxHeight: '48px',
  borderRadius: 0,
  borderWidth: 1,
  borderStyle: 'solid',
  px: 2,
  py: 1,
  ml: 0,
  mr: 0,
  mb: 0,
  appearance: 'none',
  cursor: 'pointer',
  transition: 'opacity .35s ease-out, color .3s ease-out, background .3s ease-in',
  boxShadow: 'none !important'
};
