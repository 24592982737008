import { useState, useCallback } from 'react';
import { ApiClient, useQuery } from 'jsonapi-react';
import { schema } from './schema';
import { httpMethods } from './constants';

export const apiClient = new ApiClient({
  url: process.env.REACT_APP_API_BASE_URL,
  schema
});

export const addHeader = (key, value) => apiClient.addHeader(key, value);

export const removeHeader = key => apiClient.removeHeader(key);

export function useLazyQuery(queryArg, config) {
  const [execute, setExecute] = useState(false);

  const queryResult = useQuery(execute && queryArg, config);

  const executed = execute && queryResult.isLoading;

  return [useCallback(() => setExecute(true), [setExecute]), { executed, ...queryResult }];
}

const callApi = async (endpoint, options, values, type) => {
  let response;

  if (options.method === httpMethods.GET) {
    response = await apiClient.fetch(endpoint);
  } else {
    response = await apiClient.mutate(endpoint, { ...values }, { type, ...options });
  }

  if (response.error) {
    throw Error(response.error.code);
  }
  const { data, meta, links } = response;

  return { data, meta, links };
};

export const get = (endpoint, options) => {
  return callApi(endpoint, {
    method: httpMethods.GET,
    force: options && options.force
  });
};

export const post = (endpoint, options, values, method, type) => {
  return callApi(
    endpoint,
    {
      method,
      headers: options && options.headers,
      force: options && options.force,
      ...(options?.invalidate ? { invalidate: options.invalidate } : {})
    },
    values,
    type
  );
};

export const patch = (endpoint, options, values, method, type) => {
  return callApi(
    endpoint,
    {
      method,
      headers: options && options.headers,
      force: options && options.force
    },
    values,
    type
  );
};
