import React from 'react';
import { DialogDisclosure } from 'reakit/Dialog';
import PropTypes from 'prop-types';

export const DialogTrigger = ({ children, ...props }) => {
  return (
    <>
      <DialogDisclosure {...props} ref={children.ref} {...children.props}>
        {disclosureProps => React.cloneElement(children, disclosureProps)}
      </DialogDisclosure>
    </>
  );
};

DialogTrigger.propTypes = {
  children: PropTypes.any
};
