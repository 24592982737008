import React, { useEffect } from 'react';
import { string, func, node, oneOfType, number, bool } from 'prop-types';
import { Text, Flex } from 'modules/ui/primitives';
import { Icons } from 'modules/ui/assets';
import { Button } from 'modules/ui/primitives/button';
import { useDisclosureState } from 'reakit';
import { ToastStyled } from './styles/toastStyles';

export const toastTypes = {
  error: 'error',
  info: 'info'
};

function resolveColor(toastType) {
  switch (toastType) {
    case toastTypes.error:
      return 'ui.error';
    default:
      return 'ui.success';
  }
}

const Toast = ({ id, text, type, hide, hiding, remove, animationDuration }) => {
  const CurrentIcon = Icons.Info;
  const color = resolveColor(type);

  const animationDisclosure = useDisclosureState({
    visible: true,
    animated: animationDuration
  });
  const { hide: hideAnimationDisclosure, visible, animating } = animationDisclosure;

  // if the toast.hide() was invoked, call disclosure.hide()
  useEffect(() => {
    if (hiding) {
      hideAnimationDisclosure();
    }
  }, [hideAnimationDisclosure, hiding]);

  // if the toast.hide() and disclosure.hide() have been invoked and the animation was finished,
  // remove the toast from Provider state
  useEffect(() => {
    if (hiding && !visible && !animating) {
      remove();
    }
  }, [animating, hiding, remove, visible]);

  return (
    <ToastStyled {...animationDisclosure} id={id} role="status">
      <Flex justifyContent="flex-start">
        <CurrentIcon mr={1} fontSize={3} color={color} />
        <Text mr={3} color={color}>
          {text}
        </Text>
      </Flex>
      <Button
        disabled={hiding}
        onClick={hide}
        variant="hollow"
        p="0"
        fontSize={2}
        color="text.secondary"
      >
        Hide
      </Button>
    </ToastStyled>
  );
};

Toast.propTypes = {
  id: string.isRequired,
  text: oneOfType([string, node]).isRequired,
  type: string.isRequired,
  hide: func.isRequired,
  hiding: bool,
  remove: func.isRequired,
  animationDuration: number
};

export default Toast;
