import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'modules/ui/primitives';
import { Heading } from 'modules/ui/primitives/heading';
import { withProfileLayout } from 'modules/shared/layouts/profile/withProfileLayout';
import { useGetQuestionsWithAnswers } from 'modules/onboarding/questions';
import { Link } from 'modules/ui/primitives/link';
import { ProfileSection } from 'modules/profile/components/profileSection';

function ProfileSetupScreen() {
  const [questionsWithAnswers, isLoading, loadingError] = useGetQuestionsWithAnswers();
  const { t } = useTranslation();

  return (
    <ProfileSection>
      <Heading variant="h3" mb="4">
        {t('auth.profileSetup', 'Profile Setup')}
      </Heading>
      {isLoading && <Box as="span">{t('profile.loading', 'Loading...')}</Box>}
      {loadingError && (
        <Box isInvalid>
          {t('auth.error', 'Error...')}
          {loadingError.detail}
        </Box>
      )}
      {questionsWithAnswers.map((qA, index) => {
        const answers = !qA.answers ? (
          <Text opacity="0.5" variant="body-2">
            {t('auth.skipped', 'Skipped')}
          </Text>
        ) : (
          <Box as="ul" pl="0">
            {qA.answers.map((a, i) => {
              let answerText = a;
              const rawAnswer = qA.rawAnswers?.find(ans => ans.key === a || ans.label === a);
              if (rawAnswer) {
                answerText = t(`qa.a-${rawAnswer.key}`, rawAnswer.label);
              }

              return (
                // eslint-disable-next-line react/no-array-index-key
                <li key={`${qA.key}-${i}`}>
                  <Text variant="body-2">{answerText}</Text>
                </li>
              );
            })}
          </Box>
        );

        const text = `${index + 1}. ${t(`qa.${qA.key}`, qA.label)}`;
        const isLast = index === questionsWithAnswers.length - 1;

        return (
          <Box as="article" key={qA.key} mb={isLast ? 0 : 6}>
            <Heading as="h5" fontWeight="medium" variant="h4" mb="2">
              {text}
            </Heading>
            <Box as="main" mb={[2, 0, 0, 0]}>
              {answers}
            </Box>
            <Flex as="footer" justifyContent="flex-end">
              <Link href={qA.path} variant="brand" fontSize="2">
                {t('auth.change', 'Change')}
              </Link>
            </Flex>
          </Box>
        );
      })}
    </ProfileSection>
  );
}

export default withProfileLayout({})(ProfileSetupScreen);
