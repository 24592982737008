import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDisclosureState } from 'reakit/Disclosure';
import { Box, Flex, Text } from 'modules/ui/primitives';
import { Link } from 'modules/ui/primitives/link';
import { Icons } from 'modules/ui/assets';
import { useIsUserInRoles } from 'modules/auth/shared';
import {
  DropdownBaseTrigger,
  DropdownContentAnimated,
  DropdownMobileTriggerIcon
} from './styles/dropdownStyles';

export const AdminDropdown = ({ items }) => {
  const disclosure = useDisclosureState({ animated: true });
  const { visible } = disclosure;
  const isUserInRoles = useIsUserInRoles();

  const allowedItems = useMemo(
    () =>
      items.filter(item => {
        if (!item.roles) return true;

        return isUserInRoles(item.roles);
      }),
    [isUserInRoles, items]
  );

  return (
    <Box as="nav" display={['block', 'block', 'none']} width="100%">
      <DropdownBaseTrigger {...disclosure} width="100%">
        <Flex px="4" py="3" alignItems="center" bg="white">
          <Text width="100%">Admin</Text>
          <DropdownMobileTriggerIcon
            visible={visible}
            data-visible={visible}
            id="mobile-dropdown-menu-trigger"
          >
            <Icons.ExpandMore
              title="expand more icon for mobile menu dropdown"
              fontSize="4"
              color={visible ? 'action.pink' : 'text.secondary'}
            />
          </DropdownMobileTriggerIcon>
        </Flex>
      </DropdownBaseTrigger>
      <DropdownContentAnimated id="dropdown-mobile-menu-content" {...disclosure}>
        <Box as="nav" bg="white" width="100%" px="4" py="3">
          {allowedItems.map((item, i) => (
            <Link
              id={`admin-item-${i}`}
              key={item.href}
              mb="3"
              display="block"
              variant="nav"
              to={item.to}
              href={item.href}
            >
              {item.label}
            </Link>
          ))}
        </Box>
      </DropdownContentAnimated>
    </Box>
  );
};

AdminDropdown.propTypes = {
  items: PropTypes.array
};

AdminDropdown.defaultProps = {
  items: []
};
