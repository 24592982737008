import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Container } from 'modules/ui/primitives';
import { Button } from 'modules/ui/primitives/button';
import { FadeIn } from 'modules/ui/primitives/transitions/fadeIn';
import PropTypes from 'prop-types';

export const DismissibleBanner = ({
  localStorageKey,
  showBanner,
  ActionButtonComponent,
  bg,
  children,
  ...rest
}) => {
  const { t } = useTranslation();

  const [shown, setShown] = useState(false);
  const dismissed = Boolean(window.localStorage.getItem(localStorageKey) || false);
  useEffect(() => {
    const shouldShow = showBanner && !dismissed;
    if (shown !== shouldShow) setShown(shouldShow);
  }, [dismissed, showBanner, shown]);

  const handleDismiss = () => {
    window.localStorage.setItem(localStorageKey, 'true');
    setShown(false);
  };

  return shown ? (
    <FadeIn
      as={Flex}
      delay={1500}
      px="2"
      borderBottomWidth="1px"
      borderBottomStyle="solid"
      borderBottomColor="ui.line"
      bg={bg}
      py={[4, 3]}
      duration={500}
    >
      <Flex
        as={Container}
        justifyContent={['flex-start', 'space-between']}
        flexDirection={['column', 'row']}
      >
        {children}
        <Flex minWidth="auto" justifyContent="center" px={2} mt={[2, 0]}>
          {ActionButtonComponent && <ActionButtonComponent {...rest} />}
          <Button
            fontSize={2}
            lineHeight={2}
            type="button"
            onClick={handleDismiss}
            variant="hollow"
            color="text.secondary"
            p={0}
            ml={2}
            height="2rem"
          >
            {t('auth.dismiss', 'Dismiss')}
          </Button>
        </Flex>
      </Flex>
    </FadeIn>
  ) : null;
};

DismissibleBanner.propTypes = {
  localStorageKey: PropTypes.string.isRequired,
  showBanner: PropTypes.bool,
  ActionButtonComponent: PropTypes.any,
  bg: PropTypes.string,
  children: PropTypes.any
};

DismissibleBanner.defaultProps = {
  showBanner: true,
  bg: 'base.white'
};
