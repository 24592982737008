/* eslint-disable camelcase */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Heading } from 'modules/ui/primitives';
import { Link } from 'modules/ui/primitives/link';

export const CandidateEndorsements = candidateEndorsements => {
  const { t } = useTranslation();
  const { endorsements } = { ...candidateEndorsements };

  return (
    <Flex flexDirection={['column']} width="100%">
      <Flex width="100%">
        <Box
          width="100%"
          mb="4"
          borderBottomWidth="1px"
          borderBottomStyle="solid"
          borderBottomColor="base.grey200"
        >
          <Heading as="h4" variant="h4BallotGuide" mb="2">
            {t('ballotGuide.endorsements', 'Endorsements')}
          </Heading>
        </Box>
      </Flex>
      <Flex
        width="100%"
        alignItems={['flex-start']}
        justifyContent={['flex-start']}
        flexDirection={['row']}
        flexWrap={['wrap']}
      >
        {endorsements.map(({ id, name, website_url: url }) => (
          <Flex
            key={id}
            mr={1}
            mb={2}
            borderWidth="1px"
            borderStyle="solid"
            borderColor="base.grey200"
            borderRadius="0"
            px="2"
            py="1"
            color="text.primary"
          >
            {url ? (
              <Link external href={url} variant="nav">
                {name}
              </Link>
            ) : (
              name
            )}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
