import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from 'modules/ui/primitives';
import styled from 'styled-components';
import { useGetStatistics } from 'modules/home/api';
import { useTranslation } from 'react-i18next';
import friendStar from '../assets/images/friendStar.png';
import friendStarDisabled from '../assets/images/friendStarDisabled.png';

const MAX_STARS = 3;

const FriendStarStyled = styled(Flex).attrs(props => ({
  backgroundImage: !props.disabled ? friendStar : friendStarDisabled
}))`
background-image: url('${props => props.backgroundImage}');
height: 46px;
width: 46px;
justify-content: center;
align-items: center;
margin: 0 1rem 1rem 0;
`;

const FriendStar = ({ disabled, color, children }) => {
  return (
    <FriendStarStyled disabled={disabled}>
      <Text
        color={!disabled ? color : 'base.disabled'}
        fontFamily="bodyLora"
        fontSize={3}
        lineHeight={3}
        fontWeight="bolder"
      >
        {children}
      </Text>
      {}
    </FriendStarStyled>
  );
};

FriendStar.propTypes = {
  disabled: PropTypes.bool,
  color: PropTypes.string.isRequired,
  children: PropTypes.number
};

FriendStar.defaultProps = {
  disabled: false
};

export const InvitedFriendsCount = ({ color }) => {
  const { t } = useTranslation();
  const { data, error } = useGetStatistics();

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.error(`Error while loading user statistics: ${error.details}`);
    }
  }, [error]);

  const referrals = useMemo(() => {
    if (data) return data.referrals;
    return 0;
  }, [data]);

  const text = useMemo(() => {
    if (referrals === 0)
      return t(
        'profile.inviteFriend.inviteFriendsForTote',
        "Invite {{friendsForTote}} friends and we'll send you a Supermajority tote!",
        {
          friendsForTote: MAX_STARS
        }
      );
    if (referrals < 10)
      return t(
        'profile.inviteFriend.inviteMoreFriendsForTote',
        'Nice work! {{referrals}} of your friends have become members. Can you get to {{friendsForTote}}?',
        {
          referrals,
          friendsForTote: MAX_STARS
        }
      );

    return t(
      'profile.inviteFriend.inviteMoreFriends',
      'Wow! {{referrals}} of your friends have joined Supermajority. Keep going!',
      {
        referrals
      }
    );
  }, [referrals, t]);

  return (
    <Flex as="section" flexDirection="column">
      <Flex mb={1} justifyContent={['center', 'center', 'flex-start']} flexWrap="wrap">
        {Array(MAX_STARS)
          .fill()
          .map((_, i) => (
            <FriendStar color={color} key={`star-${i + 1}`} disabled={i + 1 > referrals}>
              {i + 1}
            </FriendStar>
          ))}
      </Flex>
      <Text color={color} textAlign={['center', 'center', 'left']} variant="caption">
        {text}
      </Text>
    </Flex>
  );
};

InvitedFriendsCount.propTypes = {
  color: PropTypes.string
};

InvitedFriendsCount.defaultProps = {
  color: 'text.primary'
};
