import styled from 'styled-components';
import { Link } from 'wouter';
import { MenuButton } from 'reakit/Menu';
import { Disclosure, DisclosureContent } from 'reakit/Disclosure';
import { MoreHorizontal } from 'react-feather';

import { focusRingDashed } from 'modules/ui/primitives/shared/focusRing';

export const NavbarWrap = styled.div`
  background-color: ${props => (props.bg === 'action.pink' ? '#D90789' : '#030b46')};
  height: 64px;
  padding-left ${props => {
    const padding = props.fullWidth ? 16 : 0;
    return props.px ? props.theme.space[props.px] : padding;
  }}px;
  padding-right ${props => {
    const padding = props.fullWidth ? 16 : 0;
    return props.px ? props.theme.space[props.px] : padding;
  }}px;

  .hidden-desktop {
    display: none;
  }

  @media (max-width: 1200px) {
    padding: 0 16px;
  }

  @media (max-width: 650px) {
    .hidden-mobile {
      display: none;
    }

    .hidden-desktop {
      display: flex;
    }
  }
`;

export const LogoImg = styled.img``;

export const MenuButtonStyled = styled(MenuButton)`
  background: none;
  margin: 12px 0;
  height: 40px;
  padding: 10px 16px;
  border: none;

  a {
    &:hover {
      color: #fff;
      text-decoration: none;
      border: none;
      background-color: rgba(247, 247, 249, 0.1);
    }
  }

  @media (max-width: 650px) {
    padding: 10px 5px;
  }
`;

export const NavLink = styled(Link)`
  margin: 12px 0;
  height: 40px;
  padding: 10px 16px;
  color: #fff;
  &:hover {
    color: #fff;
    text-decoration: none;
    background-color: rgba(247, 247, 249, 0.1);
  }
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  @media (max-width: 650px) {
    padding: 10px 5px;
  }
`;

export const LinkStyled = styled.a`
  margin: 12px 0;
  height: 40px;
  padding: 10px 16px;
  color: #fff;
  &:hover {
    color: #fff;
    text-decoration: none;
    background-color: rgba(247, 247, 249, 0.1);
  }
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  @media (max-width: 650px) {
    padding: 10px 5px;
  }
`;

export const MoreIconStyled = styled(MoreHorizontal)`
  color: white;
`;

export const MobileMenuContentAnimated = styled(DisclosureContent)`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 10;

  background-color: ${props => props.theme.colors.base.white}

  opacity: 0;
  transition: opacity 350ms ease-out;
  box-shadow: 0px 3px 5px rgba(74, 82, 89, 0.1);

  &[data-enter] {
    opacity: 1;
  }

  &:focus {
    ${focusRingDashed}
  }
`;

export const MobileMenuBaseTrigger = styled(Disclosure)`
  border: none;
  outline: none;
  padding: 0;
  background: transparent;
  height: 64px;
`;

export const Divider = styled.div`
  border-bottom: 1px solid #d9d9d9;
  padding-top: ${({ theme }) => theme.space[1]}px;
  padding-bottom: ${({ theme }) => theme.space[1]}px;
`;
