import { useCallback, useRef } from 'react';
import { useSpring } from 'react-spring';
import { useScrolling } from './useScrolling';

export const useScrollToElement = (offset, config) => {
  const bodyRef = useRef(document.body);
  const scrollingBody = useScrolling(bodyRef);
  const [, setY] = useSpring(() => ({
    immediate: false,
    y: window.scrollY
  }));

  const scrollToId = useCallback(
    id => {
      if (!scrollingBody) {
        const el = document.querySelector(`#${id}`);
        const bounds = el.getBoundingClientRect();

        setY({
          config: config || { mass: 1, tension: 180, friction: 30 },
          y: window.scrollY + bounds.top - offset,
          reset: true,
          from: { y: window.scrollY },
          onFrame: props => window.scroll(0, props.y)
        });
      }
    },
    [config, offset, scrollingBody, setY]
  );

  const scrollToY = useCallback(
    position => {
      if (!scrollingBody) {
        setY({
          config: config || { mass: 1, tension: 180, friction: 30 },
          y: position - offset,
          reset: true,
          from: { y: window.scrollY },
          onFrame: props => window.scroll(0, props.y)
        });
      }
    },
    [config, offset, scrollingBody, setY]
  );

  return [scrollToId, scrollToY];
};
