import React, { useMemo } from 'react';
import { Flex } from 'modules/ui/primitives';
import { useToasts, toastPosition } from 'modules/shared/components/toast';
import Toast from './toast';

const renderToast = toast => (
  <Toast
    key={`${toast.position}_${toast.id}`}
    id={toast.id}
    text={toast.text}
    type={toast.type}
    hide={toast.hide}
    hiding={toast.hiding}
    remove={toast.remove}
    animationDuration={toast.animationDuration}
  />
);

const ToastContainer = () => {
  const { toasts } = useToasts();

  const toastsByPosition = useMemo(() => {
    const filterByPosition = position => {
      return toasts.filter(t => t.position === position);
    };
    return {
      left: filterByPosition(toastPosition.left),
      center: filterByPosition(toastPosition.center),
      right: filterByPosition(toastPosition.right)
    };
  }, [toasts]);

  return (
    <>
      <Flex
        id="other-view"
        display={['none', 'flex']}
        position="fixed"
        top="1vh"
        justifyContent={['space-between', 'space-between', 'space-between', 'space-around']}
        flexDirection="row"
        width="100vw"
        zIndex="99"
      >
        <Flex flexDirection="column" justifyContent="center">
          {toastsByPosition.left.map(toast => renderToast(toast))}
        </Flex>
        <Flex flexDirection="column" justifyContent="center">
          {toastsByPosition.center.map(toast => renderToast(toast))}
        </Flex>
        <Flex flexDirection="column" justifyContent="center">
          {toastsByPosition.right.map(toast => renderToast(toast))}
        </Flex>
      </Flex>
    </>
  );
};

export default ToastContainer;
