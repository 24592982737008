/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Button, Box, Heading } from 'modules/ui/primitives';
import { CandidateIssueStance } from './candidateIssueStance';

export const CandidateIssues = candidateIssues => {
  const { t } = useTranslation();
  const { issues } = { ...candidateIssues };
  const [candidateStances, setCandidateStances] = useState({ list: [], name: '' });

  const showStances = (stances, issueName) => {
    setCandidateStances({ list: stances, name: issueName });
  };

  return (
    <>
      <Box
        mb="4"
        borderBottomWidth="1px"
        borderBottomStyle="solid"
        borderBottomColor="base.grey200"
      >
        <Heading as="h4" variant="h4BallotGuide" mb="2">
          {t('ballotGuide.issues', 'Issues')}
        </Heading>
      </Box>
      <Flex
        width="100%"
        alignItems={['flex-start']}
        justifyContent={['flex-start']}
        flexDirection={['row']}
        flexWrap={['wrap']}
      >
        {issues.map(({ id, name, stances }) => (
          <Flex key={name} mr={1} mb={2}>
            <Button
              maxHeight="100%"
              key={id}
              variant="light-primary"
              type="button"
              onClick={() => showStances(stances, name)}
            >
              {name}
            </Button>
          </Flex>
        ))}
      </Flex>

      {candidateStances?.list.length > 0 && <CandidateIssueStance stances={candidateStances} />}
    </>
  );
};
