import styled from 'styled-components';
import { Grid } from '../grid';

export const Field = styled(Grid).attrs({
  role: 'group'
})`
  grid-gap: ${props => props.gap || '4px'};
`;

export const FieldSet = styled(Grid).attrs({
  gridGap: 1,
  as: 'fieldset'
})``;

FieldSet.defaultProps = {
  border: 0,
  p: 0,
  ml: 0,
  mr: 0
};
