import React, { useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDialogState } from 'reakit/Dialog';
import { INFO_EMAIL } from 'modules/shared/constants';
import { Box, Flex, Text } from 'modules/ui/primitives';
import { Heading } from 'modules/ui/primitives/heading';
import { Button } from 'modules/ui/primitives/button';
import { TextArea } from 'modules/ui/primitives/textarea';
import { Dialog, DialogTrigger } from 'modules/ui/components/dialog';
import { useDeactivateAccount } from 'modules/profile/api';
import { useAuth } from 'modules/auth/useAuth';
import { useForm } from 'react-hook-form';
import { FormControl } from 'modules/ui/primitives/formControl';
import { Label } from 'modules/ui/primitives/label';
import { Icons } from 'modules/ui/assets';
import { Link } from 'modules/ui/primitives/link';

function useCloseAccount() {
  const dialog = useDialogState({ animated: true });
  const {
    handleSubmit,
    register,
    errors,
    formState: { isSubmitting }
  } = useForm();

  const deactivate = useDeactivateAccount();

  const { logout } = useAuth();

  const handleSubmitDelete = useCallback(
    handleSubmit(async data => {
      const { error } = await deactivate(data);
      if (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } else {
        logout();
      }
    }),
    [deactivate]
  );

  return {
    dialog,
    form: { register, errors, handleSubmitDelete, deleting: isSubmitting }
  };
}

export const CloseAccountSection = ({ disabled, loading }) => {
  const { dialog, form } = useCloseAccount();
  const { t } = useTranslation();

  const email = INFO_EMAIL;

  return (
    <Box as="section">
      <Heading variant="h3" mb="3">
        {t('auth.account', 'Account')}
      </Heading>

      <Flex flexDirection={['column', 'row']}>
        <Box mb={[3, 0]} px={1}>
          <Text variant="body-1" mb="1">
            {t('auth.closeAccount', 'Close my account')}
          </Text>
          <Trans i18nKey="auth.closeAccountConfirmation" email={email}>
            <Text variant="caption">
              You can also always reach us by email at{' '}
              <Link
                external
                fontSize={1}
                to={`mailto:${email}`}
                variant="brand"
                id="supermajority-mail-link"
              >
                {{ email }}
              </Link>
              .
            </Text>
          </Trans>
        </Box>
        <DialogTrigger {...dialog}>
          <Button
            id="close-account-button"
            variant="outline"
            disabled={disabled || loading}
            ml="auto"
            width={['100%', 1 / 3]}
          >
            {t('auth.close', 'Close')}
          </Button>
        </DialogTrigger>
        <Dialog
          {...dialog}
          title="Close account dialog."
          id="close-account-dialog"
          contentPadding={[4, 6, 6]}
          hideCloseButton
        >
          <Heading variant="h3" fontWeight="medium" mb="2">
            {t('auth.closeAccountSure', 'Are you sure you want to delete your account?')}
          </Heading>
          <Box as="form" mb={1}>
            <FormControl.Field>
              <Label htmlFor="reason" mb="1" fontSize="1" lineHeight="1">
                <Trans i18nKey="auth.closeAccountConfirm" email={email}>
                  By closing your account, your profile will be removed from our platform along with
                  any information and data associated with your account. Should you choose to
                  return, you will not be able to retrieve your previous profile, but you will be
                  able to recreate a new profile at any time. The team at Supermajority welcomes
                  feedback on how we can improve the member experience; if you're willing, comment
                  below with your reasons for closing your account. You can also always reach us by
                  email at
                  {{ email }}.
                </Trans>
              </Label>
              <TextArea
                id="reason"
                name="reason"
                placeholder={t('auth.placeholderCloseReason', 'Tell us more about it...')}
                aria-describedby={t('auth.ariaCloseReason', 'A reason for closing your account')}
                rows="5"
                ref={form.register({
                  required: {
                    value: true,
                    message: t(
                      'auth.errorCloseReason',
                      'Please enter a reason for closing your account'
                    )
                  }
                })}
                error={Boolean(form.errors?.reason)}
                rightIcon={form.errors?.reason && <Icons.Error color="ui.error" aria-hidden />}
              />
              <FormControl.Error id="error-text-first-name">
                {form.errors?.reason?.message}
              </FormControl.Error>
            </FormControl.Field>
          </Box>
          <Button width="100%" mb="1" disabled={form.deleting} onClick={dialog.hide}>
            {t('auth.backToSettings', 'Back to Settings')}
          </Button>
          <Button
            variant="hollow"
            onClick={form.handleSubmitDelete}
            isLoading={form.deleting}
            width="100%"
          >
            {t('auth.deleteProfile', 'Delete my profile')}
          </Button>
        </Dialog>
      </Flex>
    </Box>
  );
};

CloseAccountSection.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool.isRequired
};
