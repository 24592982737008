import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Flex } from 'modules/ui/primitives';

export const SourceOfInformation = () => {
  const { t } = useTranslation();

  return (
    <Flex
      width="100%"
      p={[4]}
      flexDirection={['column']}
      backgroundColor="base.grey100"
      borderRadius={0}
    >
      <Text fontSize="2" fontWeight="bold" lineHeight="2" mb={2}>
        {t('ballotGuide.sourceOfInformation', 'Where do we get our information?')}{' '}
      </Text>
      <Text>
        {t(
          'ballotGuide.sourceOfInformationText',
          'We receive our information from CivicEngine, a site that aggregates information from candidate websites, social media, endorsers, and boards of election to help you cast an informed vote. If little or no information is displayed for a candidate, the information online did not meet CivicEngine’s research criteria and was not included.'
        )}{' '}
      </Text>
    </Flex>
  );
};
