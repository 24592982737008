import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ImageEditor } from 'modules/profile';
import { Box, Flex } from 'modules/ui/primitives';
import { Heading } from 'modules/ui/primitives/heading';
import { Tooltip } from 'modules/ui/components/tooltip';
import { Button } from 'modules/ui/primitives/button';
import { Icons } from 'modules/ui/assets';
import { Label } from 'modules/ui/primitives/label';
import { FormControl } from 'modules/ui/primitives/formControl';
import { Input } from 'modules/ui/primitives/input';
import { Checkbox } from 'modules/ui/primitives/checkbox';
import { useProfile } from 'modules/profile/profileProvider';
import { withProfileLayout } from 'modules/shared/layouts/profile/withProfileLayout';
import { ProfileSection } from 'modules/profile/components/profileSection';
import { CloseAccountSection } from '../components/closeAccountSection';
import { LanguageSelectSection } from '../components/languageSelectSection';

function useSetupForm() {
  const { register, reset, handleSubmit, watch, getValues, errors, formState } = useForm();

  const [{ data: profileData }, { submit }] = useProfile();

  const liveAbroad = watch('liveAbroad', profileData?.liveAbroad);

  useEffect(() => {
    if (!profileData) return;
    reset({
      email: profileData?.user?.email,
      firstName: profileData?.firstName,
      lastName: profileData?.lastName,
      zipCode: profileData?.zipCode,
      liveAbroad: profileData?.liveAbroad
    });
  }, [profileData, reset]);

  const handleSubmitCallback = useCallback(
    handleSubmit(data => {
      const submitData = {
        ...data,
        user: { id: profileData?.user?.id }
      };

      if (submitData.liveAbroad) delete submitData.zipCode;

      submit(submitData);
    }),
    [submit, profileData?.user?.id]
  );

  return {
    liveAbroad,
    handleSubmit: handleSubmitCallback,
    getValues,
    register,
    formErrors: errors,
    formState
  };
}

function useAccountAndProfileScreen() {
  const {
    liveAbroad,
    handleSubmit,
    getValues,
    register,
    formErrors,
    formState: { isSubmitted, isSubmitting, dirty }
  } = useSetupForm();

  const [{ loadingError, submitError, isLoading }] = useProfile();

  const loading = isLoading || isSubmitting;
  const disabled = loading || loadingError;

  const serverError = loadingError || (isSubmitted && submitError);

  return {
    liveAbroad,
    handleSubmit,
    register,
    getValues,
    dirty,
    loading,
    disabled,
    formErrors,
    serverError
  };
}

function MemberProfileScreen() {
  const {
    liveAbroad,
    handleSubmit,
    register,
    getValues,
    dirty,
    loading,
    disabled,
    formErrors,
    serverError
  } = useAccountAndProfileScreen();
  const { t } = useTranslation();

  return (
    <ProfileSection>
      <ImageEditor onboarding />
      <Heading variant="h3" mb="4" mt="2">
        {t('profile.label', 'Profile')}
      </Heading>
      {loading && <Box as="span">{t('profile.loading', 'Loading...')}</Box>}
      {serverError && (
        <Box isInvalid>
          {t('auth.error', 'Error:')}
          {serverError.detail}
        </Box>
      )}
      <Box as="form" onSubmit={handleSubmit} mb="6">
        <FormControl.Field mb="6">
          <Flex>
            <Label htmlFor="email" required>
              {t('auth.currentEmail', 'Your current email address is')}
            </Label>
            <Tooltip
              id="tooltip-content-for-email"
              placement="left"
              title={t(
                'auth.emailTooltip',
                "Your member ID is how you log in and out of your member portal, and how we'll contact you with important information about Supermajority and your account. Your member ID is unique to you."
              )}
            />
          </Flex>

          <Input
            name="email"
            id="email"
            placeholder={t('auth.placeholderEmail', 'Your current email address.')}
            aria-describedby={t('auth.ariaCurrentEmail', 'Your current email address.')}
            disabled
            ref={register}
          />
        </FormControl.Field>

        <Flex mb={['4', '3']} flexDirection={['column', 'row']} mx={-2}>
          <FormControl.Field width={['100%', 1 / 2]} px={2} mb={['3', '0']}>
            <Flex>
              <Label htmlFor="firstName" required>
                {t('auth.firstName', 'First Name')}
              </Label>
              <Tooltip
                id="tooltip-content-for-first-name"
                title={t(
                  'auth.firstNameTooltip',
                  `This the name we'll use to welcome you when you log into Home
                and in all our communications with you.`
                )}
                placement="left"
              />
            </Flex>

            <Input
              name="firstName"
              id="firstName"
              placeholder={t('auth.firstName', 'First Name')}
              aria-describedby={t('auth.ariaFirstName', 'Please enter first name.')}
              disabled={disabled}
              ref={register({
                required: {
                  value: true,
                  message: t('auth.errorFirstName', 'First name is required')
                },
                minLength: {
                  value: 2,
                  message: t('auth.errorFirstName1', 'Please enter a least 2 characters')
                }
              })}
              error={formErrors.firstName}
              rightIcon={formErrors.firstName && <Icons.Error color="ui.error" aria-hidden />}
            />
            <FormControl.Error id="error-text-first-name">
              {formErrors?.firstName?.message}
            </FormControl.Error>
          </FormControl.Field>

          <FormControl.Field width={['100%', 1 / 2]} px={2} mb={['4', '0']}>
            <Flex>
              <Label htmlFor="lastName" required>
                {t('auth.lastName', 'Last Name')}
              </Label>
              <Tooltip
                id="tooltip-content-for-last-name"
                title={t(
                  'auth.lastNameTooltip',
                  'Your last name is required to check for duplications in our system and to verify your account.'
                )}
                placement="left"
              />
            </Flex>
            <Input
              name="lastName"
              id="lastName"
              placeholder={t('auth.lastName', 'Last Name')}
              aria-describedby={t('auth.ariaLastName', 'Please enter last name.')}
              disabled={disabled}
              ref={register({
                required: {
                  value: true,
                  message: t('auth.errorLastName', 'Last name is required')
                },
                minLength: {
                  value: 2,
                  message: t('auth.errorLastName1', 'Please enter a least 2 characters')
                }
              })}
              error={formErrors.lastName}
              rightIcon={formErrors.lastName && <Icons.Error color="ui.error" aria-hidden />}
            />
            <FormControl.Error id="error-text-last-name">
              {formErrors?.lastName?.message}
            </FormControl.Error>
          </FormControl.Field>
        </Flex>

        <Flex mb={['3', '5']} flexDirection={['column', 'row']} mx={-2} alignItems="flex-start">
          <FormControl.Field width={['100%', 1 / 2]} px={2} mb={['3', '0']}>
            <Label htmlFor="zipCode" required>
              {t('auth.zipCode', 'ZIP Code')}
            </Label>
            <Input
              type="number"
              name="zipCode"
              id="zipCode"
              placeholder={t('auth.digitZipCode', '5 digit zip code')}
              aria-describedby={t('auth.fiveZipCode', 'Five digit zip code')}
              disabled={disabled || liveAbroad}
              ref={register({
                validate: value => {
                  const values = getValues();
                  if (values.liveAbroad) return true;

                  return value.length !== 5
                    ? t('auth.errorZipCodeLength', 'Please enter a 5-digit ZIP code')
                    : true;
                }
              })}
              error={formErrors.zipCode}
              rightIcon={formErrors.zipCode && <Icons.Error color="ui.error" aria-hidden />}
            />
            <FormControl.Error id="error-text-zip-code">
              {formErrors?.zipCode?.message}
            </FormControl.Error>
          </FormControl.Field>

          <FormControl.Field width={['100%', 1 / 2]} px={2}>
            <Box minHeight="1.3rem" />
            <Checkbox
              id="liveAbroad"
              name="liveAbroad"
              ref={register}
              disabled={disabled}
              variant="caption"
              lineHeight="1"
              label={t(
                'auth.primaryResidence',
                'My primary residence is outside the U.S. and its territories.'
              )}
            />
            <FormControl.Error id="error-text-live-abroad">
              {formErrors?.liveAbroad?.message}
            </FormControl.Error>
          </FormControl.Field>
        </Flex>

        {dirty && (
          <Flex width="100%" justifyContent="center">
            <Button
              id="save-changes-button"
              disabled={disabled}
              isLoading={disabled}
              type="submit"
              width="65%"
            >
              {t('profile.saveChanges', 'Save Changes')}
            </Button>
          </Flex>
        )}
      </Box>

      <LanguageSelectSection />
      <CloseAccountSection disabled={disabled} loading={loading} />
    </ProfileSection>
  );
}

export default withProfileLayout({
  showFooter: true
})(MemberProfileScreen);
