import styled from 'styled-components';
import { Flex } from 'modules/ui/primitives';
import { MenuItem } from 'reakit/Menu';
import { Disclosure, DisclosureContent } from 'reakit/Disclosure';
import { focusRingDashed, focusRingButton } from 'modules/ui/primitives/shared/focusRing';
import { MenuTrigger } from '../components/trigger';

export const MenuAnimated = styled(Flex)`
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
  opacity: 0;
  transform: translateY(20px);
  border-radius: ${props => props.theme.radii[3]}px;
  box-shadow: 0px 3px 5px rgba(74, 82, 89, 0.1);

  [data-enter] & {
    opacity: 1;
    transform: translateY(0);
  }

  &:focus {
    ${focusRingDashed}
  }
`;

export const MenuTriggerStyled = styled(MenuTrigger)`
  outline: none;
  border-radius: 50%;
  box-shadow: ${props => (props.active ? '0 0 0 3px rgba(230, 0, 115, 0.4)' : '0')};
  transition: box-shadow 0.25s ease-out;
  background-color: ${props => props.theme.colors.base.grey200};
  cursor: pointer;
  border-width: 2px;
  font-weight: bold;

  &:focus {
    ${focusRingButton}
  }
`;

export const MenuItemStyled = styled(MenuItem)`
  background: none;
  border: none;
  appearance: none;
  outline: none;
  text-decoration: none;
  color: ${props => props.theme.colors.text.primary};
  font-size: ${props => props.theme.fontSizes[2]};
  line-height: ${props => props.theme.lineHeights[2]};

  &:visited {
    color: ${props => props.theme.colors.text.primary};
  }

  &:focus {
    ${focusRingDashed}
  }
`;

export const DropdownMobileTriggerIcon = styled.div`
  margin-left: auto;
  transition: transform 0.4s ease-in-out;
  transform: rotate(${props => (props.visible ? '-180deg' : 0)});
`;

export const DropdownBaseTrigger = styled(Disclosure)`
  border: none;
  outline: none;
  width: 100%;
  padding: 0;
  transition: box-shadow 300ms ease-out;
  border-bottom: ${props => (props.withBorder ? `1px solid ${props.theme.colors.ui.line}` : 0)};
  box-shadow: ${props => (props.visible ? '0' : '0px 3px 5px rgba(74, 82, 89, 0.1)')};
`;

export const DropdownContentAnimated = styled(DisclosureContent)`
  opacity: 0;
  transition: opacity 350ms ease-out;
  box-shadow: 0px 3px 5px rgba(74, 82, 89, 0.1);

  &[data-enter] {
    opacity: 1;
  }

  &:focus {
    ${focusRingDashed}
  }
`;

export const PrivacyPolicyContentAnimated = styled(DisclosureContent)`
  transition: opacity 250ms ease-in-out, transform 350ms ease-in-out;
  opacity: 0;
  transform: translate3d(0, 40px, 0);

  &[data-enter] {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
