import React, { useState } from 'react';
import { Box, Container, Button } from 'modules/ui/primitives';
import { Grid } from 'modules/ui/primitives/grid';
import { Navbar } from 'modules/shared/components';
import styled from 'styled-components';
import { IncompleteInfoBanner } from 'modules/ballotGuide/components/incompleteInfoBanner';
import { Sidebar, Disclaimer, WelcomeToBallot } from './components';

const mediaQuery = '@media (max-width: 1000px)';

const BallotGuideBox = styled(Box)`
  width: 100%;
  height: 100vh;
  min-height: 100vh;

  ${mediaQuery} {
    width: 100%;
    height: auto;
    min-height: 100%;

    .hidden-mobile {
      display: none !important;
    }
  }
`;

const BallotGuideGrid = styled(Grid)`
  .hidden-desktop {
    display: none;
  }
  ${mediaQuery} {
    display: block;

    .hidden-mobile {
      display: none !important;
    }

    .third-lvl-menu > .ant-menu-submenu-title::after,
    .third-lvl-menu > ul {
      display: none !important;
    }

    .hidden-desktop {
      display: block;
    }
  }
`;

const BallotGuideMobileMenuWrap = styled.div`
  display: none;
  ${mediaQuery} {
    display: block;
  }
`;

const ShowMenuButton = styled(Button)`
  width: 100%;
  line-height: 70px;
  max-height: 70px;
  padding: 0;
  font-size: ${({ theme: { fontSizes } }) => fontSizes[4]};
  text-align: left;

  &:after {
    font-size: ${({ theme: { fontSizes } }) => fontSizes[7]};
    font-weight: ${({ theme: { fontWeights } }) => fontWeights.regular};
    color: ${({ theme: { colors } }) => colors.base.white};
    position: absolute;
    right: 12px;
    top: 0;
    content: '+';
  }
`;

const HideMenuButton = styled(ShowMenuButton)`
  background-color: #fff;
  color: ${({ theme: { colors } }) => colors.action.pink};
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.base.grey200};
`;

const withBallotGuideLayout = WrappedComponent => rest => {
  const [sidebarHidden, setSidebarHidden] = useState(true);
  return (
    <>
      <IncompleteInfoBanner />
      <BallotGuideBox bg={['base.white', 'base.grey100']}>
        <Navbar boxShadow="nav" mb="1px" isProfile fullWidth location="account" />
        <WelcomeToBallot />
        <BallotGuideMobileMenuWrap>
          {sidebarHidden ? (
            <ShowMenuButton onClick={() => setSidebarHidden(false)}>
              Candidates & measures
            </ShowMenuButton>
          ) : (
            <HideMenuButton onClick={() => setSidebarHidden(true)}>Close menu</HideMenuButton>
          )}
        </BallotGuideMobileMenuWrap>
        <BallotGuideGrid
          as={Container}
          width="100%"
          height="auto"
          gridTemplateRows="100%"
          gridTemplateColumns={['100%', '400px 5fr']}
          gridColumnGap={[0]}
          py="1px"
          fullWidth
        >
          <Sidebar hidden={sidebarHidden} setHidden={setSidebarHidden} />

          <Box as="main" bg="base.grey100" minHeight="100%" height="auto" overflowY="auto">
            <WrappedComponent {...rest} p={4} />
            <Disclaimer className="hidden-desktop" />
          </Box>
        </BallotGuideGrid>
      </BallotGuideBox>
    </>
  );
};

export default withBallotGuideLayout;
