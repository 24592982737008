import React, { memo } from 'react';
import { Route, Switch } from 'wouter';
import PropTypes from 'prop-types';
import { ProfileProvider } from 'modules/profile/profileProvider';
import { BallotDataProvider } from 'modules/ballotGuide/provider';

export const HomeLayout = memo(({ routes }) => {
  return (
    <ProfileProvider>
      <BallotDataProvider>
        <Switch>
          {routes.map(privateRoute => (
            <Route
              key={`${privateRoute.path}-${privateRoute.name}`}
              path={privateRoute.path}
              component={privateRoute.component}
            />
          ))}
        </Switch>
      </BallotDataProvider>
    </ProfileProvider>
  );
});

HomeLayout.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string.isRequired
    })
  )
};
