import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Icons } from 'modules/ui/assets/';
import { Text } from '../text';
import { Box } from '../box';
import { focusRing } from '../shared/focusRing';

const TextWrapper = styled(Text)`
  display: flex;
  align-items: center;
`;

const radioIconStyles = css`
  color: ${props => (props.error ? props.theme.colors.ui.error : props.theme.colors.action.pink)};

  font-size: ${props => props.theme.fontSizes[4]}px;

  &:hover {
    color: ${props =>
      props.error ? props.theme.colors.ui.error : props.theme.colors.action.pinkDark};
  }

  input:focus ~ & {
    color: ${props => (props.error ? props.theme.colors.ui.error : props.theme.colors.action.pink)};
    ${focusRing}
  }

  input:checked:disabled ~ &,
  input:disabled ~ & {
    color: ${props => props.theme.colors.base.grey200};
  }

  input:checked ~ & {
    color: ${props => (props.error ? props.theme.colors.ui.error : props.theme.colors.action.pink)};
  }
`;

const RadioUnchecked = styled(Icons.RadioUnchecked)`
  display: block;

  input:checked ~ & {
    display: none;
  }

  ${radioIconStyles}
`;

const RadioChecked = styled(Icons.RadioChecked)`
  display: none;

  input:checked ~ & {
    display: block;
  }

  ${radioIconStyles}
`;

const RadioIcon = props => (
  <>
    <RadioUnchecked {...props} />
    <RadioChecked {...props} />
  </>
);

export const Radio = forwardRef(
  (
    { children, label, fontSize, lineHeight, disabled, error, mb, mt, mx, my, ml, mr, ...props },
    ref
  ) => (
    <TextWrapper
      as="label"
      variant="body-2"
      fontSize={fontSize}
      lineHeight={lineHeight}
      color={disabled ? 'text.disabled' : 'text.secondary'}
      mx={mx}
      my={my}
      mb={mb}
      mt={mt}
      ml={ml}
      mr={mr}
    >
      <Box display="inline-block" minWidth="2.4rem" mr={1}>
        <Box
          ref={ref}
          as="input"
          type="radio"
          disabled={disabled}
          {...props}
          position="absolute"
          opacity={0}
          zIndex={-1}
          width={2}
          height={2}
          overflow="hidden"
          aria-invalid={error ? 'true' : 'false'}
        />
        <Box
          as={RadioIcon}
          display="inline-block"
          aria-hidden="true"
          disabled={disabled}
          error={error}
        />
      </Box>
      {label}
    </TextWrapper>
  )
);

Radio.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  label: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
  lineHeight: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
  mb: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  mt: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  mx: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  my: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  ml: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  mr: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};

Radio.defaultProps = {};
