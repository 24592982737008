import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMenuBarState, MenuBar } from 'reakit/Menu';
import { Trail, config } from 'react-spring/renderprops';
import { Box } from 'modules/ui/primitives/box';
import { Stack } from 'modules/ui/primitives/stack';
import { Link } from 'modules/ui/primitives/link';
import { useScrollToElement } from 'modules/home/hooks/scroll';
import { MenuItemStyled } from './styles/sidebarStyles';

export const PrivacySidebar = ({ items }) => {
  const [scrollToId] = useScrollToElement(50);

  const menuBar = useMenuBarState({
    orientation: 'vertical',
    loop: 'vertical'
  });

  const onMenuItemClick = useCallback(
    scrollTo => {
      scrollToId(scrollTo);
    },
    [scrollToId]
  );

  return (
    <Box as="aside" display={['none', 'none', 'flex']} px={[0, 4, 4, 0]}>
      <Stack axis="vertical" as={MenuBar} {...menuBar}>
        <Trail
          config={config.default}
          items={items}
          keys={item => item.href}
          delay={0}
          from={{ opacity: 0, transform: 'translate3d(0, 30px,0)' }}
          to={{ opacity: 1, transform: 'translate3d(0,0px,0)' }}
        >
          {(item, i) => props => (
            <MenuItemStyled
              id={`sidebar-item-${i}`}
              key={item.href}
              as={Link}
              variant="nav"
              fontSize="1"
              to={item.to}
              href={item.href}
              style={props}
              mt={i === items.length - 1 ? '3' : '0'}
              onClick={() => onMenuItemClick(item.to)}
            >
              {item.label}
            </MenuItemStyled>
          )}
        </Trail>
      </Stack>
    </Box>
  );
};

PrivacySidebar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  )
};
