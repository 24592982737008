import styled from 'styled-components';
import { animated } from 'react-spring';
import { Box } from 'modules/ui/primitives/box';
import { focusRingButton } from 'modules/ui/primitives/shared/focusRing';
import { OutboundLink } from 'modules/shared/components/analytics/outboundLink';

export const ActionBody = styled.div`
  overflow: auto;
`;

export const ActionCardAnimatedWrapper = styled(Box)`
  will-change: transform, opacity;
`;

export const NeedHelpLink = styled.a`
  outline: none;
  transition: 0.3s ease-out;
  padding: ${props => props.theme.space[2]}px;
  border-radius: ${props => props.theme.radii[3]}px;
  opacity: 1;
  text-decoration: none;
  font-size: ${props => props.theme.fontSizes[2]};
  color: ${props => props.theme.colors.action.pink};

  &:hover {
    opacity: 0.5;
    color: ${props => props.theme.colors.action.pinkDark};
  }

  &:focus {
    ${focusRingButton};
  }
`;

const BaseLink = styled(OutboundLink)`
  display: block;
  width: 100%;
  max-height: 4.4rem;
  margin-bottom: ${props => props.theme.space[2]}px;
  opacity: 1;
  transition: all 0.3s ease-out;
  text-decoration: none;
  padding-top: ${props => props.theme.space[1]}px;
  padding-bottom: ${props => props.theme.space[1]}px;
  display: block;
  text-align: center;
  border-radius: ${props => props.borderRadius || props.theme.space[0]}px;
  font-size: ${props => props.theme.fontSizes[3]};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    ${focusRingButton}
  }
`;

export const GoLink = styled(BaseLink)`
  padding-top: ${props => props.theme.space[1]}px;
  padding-bottom: ${props => props.theme.space[1]}px;
  line-height: ${props => props.theme.lineHeights[3]};
  width: 100%;
  margin-bottom: ${props => props.theme.space[1]}px;
  background: ${props => props.theme.colors.base.white};
  color: ${props => props.theme.colors.action.pink};
  font-weight: bold;

  &:hover {
    color: ${props => props.theme.colors.action.pinkDark};
  }
`;

export const GoLinkPrimary = styled(BaseLink)`
  background: ${props =>
    props.disabled ? props.theme.colors.base.grey200 : props.theme.colors.action.pink};
  color: ${props =>
    props.disabled ? props.theme.colors.text.secondary : props.theme.colors.base.white};
  margin-bottom: 0;
  margin-top: auto;
  font-weight: bold;
  line-height: 2.6rem; // fix this asap after deadline/deployment
  min-height: 44px; // fix this asap after deadline/deployment
  &:hover {
    color: ${props => props.theme.colors.base.white};
  }
`;

export const AnimatedDescription = styled(animated.p)`
  color: ${props => props.theme.colors.text.secondary};
  will-change: opacity, height;
  overflow: hidden;
  margin-bottom: ${props => props.theme.space[3]}px;
`;

export const BaseCard = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: transform, opacity;
  padding: ${props => props.theme.space[4]}px;
  border: 1px solid #b3b3b3;
  overflow: hidden;
  background: ${props => props.theme.colors.base.white};

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    padding: ${props => props.theme.space[5]}px;
  }
`;

export const Front = styled(BaseCard)`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.base.white};
  backface-visibility: ${props => (props.flipped ? 'hidden' : 'visible')};
`;

export const Back = styled(BaseCard)`
  background: ${props => props.theme.colors.action.pink};
  backface-visibility: ${props => (props.flipped ? 'hidden' : 'visible')};
`;
