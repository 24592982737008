import React, { useMemo } from 'react';
import { getInitials } from 'modules/core/utils';
import { Image } from 'modules/ui/primitives';
import PropTypes from 'prop-types';

const AvatarImage = ({ firstName, lastName, profileImage, ...imageProps }) => {
  const placeholderImage = useMemo(() => {
    const initials = firstName || lastName ? getInitials(`${firstName} ${lastName}`) : 'N/A';
    return `https://eu.ui-avatars.com/api/?background=F7F7F9&color=4A5259&size=120&name=${initials}`;
  }, [firstName, lastName]);

  const profileImageSrc = useMemo(() => profileImage || placeholderImage, [
    placeholderImage,
    profileImage
  ]);

  return (
    <Image
      src={profileImageSrc}
      variant="rounded"
      alt={`${firstName} ${lastName}`}
      {...imageProps}
    />
  );
};

export default AvatarImage;

AvatarImage.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  profileImage: PropTypes.string
};
