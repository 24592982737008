import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from 'modules/auth/useAuth';

const CanUserSee = ({ allowedRoles = [], children }) => {
  const { user } = useAuth();
  const [roles, setRoles] = useState(user.roles);

  useEffect(() => {
    setRoles(user.roles);
  }, [user.roles]);

  if (!roles) return null;

  let isAdmin = false;
  roles.forEach(role => {
    if (allowedRoles.find(r => r === Number(role.id)) !== undefined) {
      isAdmin = true;
    }
  });

  return isAdmin && <>{children}</>;
};
CanUserSee.propTypes = {
  allowedRoles: PropTypes.array.isRequired,
  children: PropTypes.any
};

export default CanUserSee;
