export default {
  fonts: {
    body: 'Inter, system-ui, sans-serif',
    bodyLora: 'Lora, system-ui, serif',
    heading: 'FS Blake, system-ui, serif',
    headingBold: 'FS Blake-Bold, system-ui, serif',
    headingHeavy: 'FS Blake-Heavy, system-ui, serif',
    monospace: 'Menlo, monospace'
  },
  fontSizes: [
    '1.2rem',
    '1.4rem',
    '1.6rem',
    '1.8rem',
    '2rem',
    '2.4rem',
    '3.2rem',
    '3.6rem',
    '4.8rem',
    '7.2rem',
    '9.2rem'
  ],
  fontWeights: {
    regular: 400,
    lightMedium: 500,
    medium: 600,
    bold: 700,
    bolder: 800
  }
};
