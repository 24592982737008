import styled from 'styled-components';

export const BackgroundHD = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-size: cover;
  opacity: ${props => (props.loaded ? 1 : 0)};
  transition: opacity 1s ease;
  transition-delay: 500ms;
`;

export const Placeholder = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-size: cover;
`;
