import React from 'react';
import styled from 'styled-components';

import { Text } from 'modules/ui/primitives';

import poweredByLogoSrc from '../assets/civicEngineLogo.png';

const PoweredByLogo = styled.img`
  max-width: 130px;
`;

export const Disclaimer = props => {
  return (
    <div {...props} style={{ textAlign: 'center', padding: 20 }}>
      <div
        style={{
          background: 'rgba(255, 78, 167, 0.05)',
          padding: '20px 40px',
          borderRadius: 8
        }}
      >
        <Text color="#4D4D4D">This tool is a voting guide and NOT an official ballot.</Text>
      </div>
      <Text mt="4" color="#B8C0C7">
        Powered by <PoweredByLogo src={poweredByLogoSrc} alt="BallotReady" />
      </Text>
    </div>
  );
};
