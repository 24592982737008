import React from 'react';
import { Spring } from 'react-spring/renderprops';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ProgressStyled = styled.progress`
  &[value] {
    /* Reset the default appearance */
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: ${props => props.theme.sizes[0]}px;
    border-radius: ${props => props.theme.radii[1]}px;
    border: none;

    &::-webkit-progress-bar {
      background-color: ${props => props.theme.colors.base.grey200};
      border-radius: ${props => props.theme.radii[1]}px;
      box-shadow: 0 0px px rgba(0, 0, 0, 0.25) inset;
    }

    &::-webkit-progress-value {
      background-color: ${props => props.theme.colors.action.pink};
      border-radius: ${props => props.theme.radii[1]}px;
      box-shadow: 0 0px px rgba(0, 0, 0, 0.25) inset;
    }

    &::-moz-progress-bar {
      border-radius: ${props => props.theme.radii[1]}px;
      background-color: ${props => props.theme.colors.action.pink};
    }
  }
`;

export const HorizontalProgress = ({ title, progress, pink, animated }) => {
  if (!animated) {
    return (
      <ProgressStyled id={title} max="100" value={progress} pink={pink}>
        {' '}
        {progress}%{' '}
      </ProgressStyled>
    );
  }

  return (
    <Spring from={{ percent: 0 }} to={{ percent: progress }}>
      {({ percent }) => (
        <ProgressStyled id={title} max="100" value={percent} pink={pink}>
          {' '}
          {percent}%{' '}
        </ProgressStyled>
      )}
    </Spring>
  );
};

HorizontalProgress.propTypes = {
  title: PropTypes.string.isRequired,
  progress: PropTypes.number,
  pink: PropTypes.bool,
  animated: PropTypes.bool
};

HorizontalProgress.defaultProps = {
  progress: 0,
  pink: false,
  animated: true
};
