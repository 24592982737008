import { css } from 'styled-components';

const datePickerTokens = {
  iconSize: 2, // icon size on font-size scale
  y: '9px', // padding y
  x: 2 // padding x
};

export const datePickerStyle = css`
  &.ant-picker {
    max-height: 48px;

    appearance: none;

    padding: ${datePickerTokens.y} ${props => props.theme.space[datePickerTokens.x]}px;

    color: ${props => props.theme.colors.text.primary};
    background: ${props => props.theme.colors.base.grey100};

    border-width: 2px;
    border-radius: ${props => props.theme.radii[0]}px;
    border-color: ${props =>
      // eslint-disable-next-line no-nested-ternary
      props.error
        ? props.theme.colors.ui.error
        : props.success
        ? props.theme.colors.ui.success
        : props.theme.colors.ui.transparent};

    .ant-picker-input > input {
      font-size: ${props => props.theme.fontSizes[3]};
      line-height: ${props => props.theme.lineHeights[3]};
      color: ${props => props.theme.colors.text.primary};
    }

    .ant-picker-input > input::placeholder {
      color: ${props => props.theme.colors.text.disabled};
    }
  }

  &.ant-picker:hover,
  &.ant-picker-focused {
    border-color: ${props => props.theme.colors.action.pink};
    border-width: 2px !important;
    box-shadow: none;
  }

  .ant-picker-content th,
  .ant-picker-content td {
    text-align: center;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid ${props => props.theme.colors.action.pink};
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: ${props => props.theme.colors.action.pink};
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: ${props => props.theme.colors.background.pink};
  }
  .ant-picker-active-bar {
    display: none;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    border-color: ${props => props.theme.colors.action.pink};
  }

  .ant-picker-suffix svg,
  .ant-picker-clear svg {
    width: ${props => props.theme.fontSizes[4]};
    height: ${props => props.theme.fontSizes[4]};
  }
`;
