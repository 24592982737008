import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'modules/ui/primitives';
import objectToURLParams from 'modules/core/utils';

const facebookShareURL = 'https://www.facebook.com/sharer/sharer.php';

export default function FacebookShareLink({ url, quote, hashtag, onClick, children }) {
  const to = `${facebookShareURL}${objectToURLParams({
    u: url,
    quote,
    hashtag
  })}`;

  return (
    <Link to={to} external onClick={onClick}>
      {children}
    </Link>
  );
}

FacebookShareLink.propTypes = {
  url: PropTypes.string.isRequired,
  quote: PropTypes.string,
  hashtag: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any
};
