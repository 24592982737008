import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography } from 'styled-system';
import { string } from 'prop-types';
import { uuid } from './uuid';

const Question = forwardRef(({ title, ...props }, svgRef) => {
  const [titleId] = useState(() => uuid());
  return (
    <svg
      width="1.1em"
      height="1.1em"
      viewBox="0 0 6 10"
      fill="none"
      stroke="currentColor"
      strokeWidth={0.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M1.995 7.098H3.36v-.09c.02-1.099.335-1.59 1.146-2.092.854-.515 1.38-1.24 1.38-2.315C5.885 1.051 4.71 0 2.958 0 1.351 0 .062.94 0 2.659h1.451c.057-1.012.778-1.456 1.508-1.456.812 0 1.47.54 1.47 1.384 0 .711-.443 1.213-1.011 1.566-.888.544-1.413 1.083-1.423 2.854v.09zM2.716 10c.52 0 .955-.425.955-.955a.96.96 0 00-.955-.95.956.956 0 00-.955.95c0 .53.43.955.955.955z"
        fill="#fff"
      />
    </svg>
  );
});

const QuestionIcon = styled(Question)(
  {
    flex: 'none',
    verticalAlign: 'middle'
  },
  compose(space, color, layout, typography)
);

Question.propTypes = {
  title: string
};

Question.defaultProps = {
  title: 'question mark icon'
};

export default QuestionIcon;
