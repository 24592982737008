import { variant } from 'styled-system';
import { defaultButtonStyles } from './defaultStyles';

export const buttonVariants = variant({
  variants: {
    primary: {
      ...defaultButtonStyles,
      bg: 'action.pink',
      color: 'base.white',
      fontWeight: 'bold',
      borderColor: 'transparent',
      ' svg': {
        color: 'base.white'
      },
      '&:hover': {
        backgroundColor: 'action.pinkDark',
        color: 'base.white'
      },
      '&:active, &:focus': {
        backgroundColor: 'action.pinkDark',
        color: 'base.white'
      },
      '&:disabled': {
        color: 'base.white',
        backgroundColor: 'action.pinkDark',
        borderColor: 'action.pinkDark',
        opacity: 0.7,
        cursor: 'not-allowed'
      }
    },
    secondary: {
      ...defaultButtonStyles,
      bg: 'base.white',
      fontWeight: 'bold',
      color: 'action.pink',
      borderWidth: '2px',
      borderColor: 'action.pink',
      ' svg': {
        color: 'action.pink'
      },

      '&:hover, &:active, &:focus': {
        bg: 'action.pinkLight'
      },
      '&:disabled': {
        color: 'text.secondary',
        backgroundColor: 'base.grey200',
        borderColor: 'transparent',
        opacity: 0.7,
        cursor: 'not-allowed'
      },
      '&:disabled svg': {
        color: 'text.secondary'
      }
    },
    outline: {
      ...defaultButtonStyles,
      bg: 'transparent',
      color: 'action.pink',
      borderWidth: 2,
      borderColor: 'action.pink',
      ' svg': {
        color: 'action.pink'
      },
      '&:hover': {
        color: 'base.white',
        bg: 'action.pink'
      },
      '&:active, &:focus': {
        bg: 'action.pinkDark',
        color: 'base.white'
      },
      '&:disabled': {
        color: 'text.secondary',
        backgroundColor: 'base.grey200',
        borderColor: 'transparent',
        opacity: 0.7,
        cursor: 'not-allowed'
      },
      '&:disabled svg': {
        color: 'text.secondary'
      }
    },
    hollow: {
      ...defaultButtonStyles,
      bg: 'transparent',
      color: 'action.pink',
      borderColor: 'transparent',
      ' svg': {
        color: 'action.pink'
      },
      '&:hover': {
        color: 'action.pinkDark'
      },
      '&:active, &:focus': {
        color: 'action.pinkDark'
      },
      '&:disabled': {
        color: 'text.secondary',
        backgroundColor: 'base.grey200',
        borderColor: 'transparent',
        opacity: 0.7,
        cursor: 'not-allowed'
      },
      '&:disabled svg': {
        color: 'text.secondary'
      }
    },
    inline: {
      ...defaultButtonStyles,
      fontSize: 2,
      minWidth: '5rem',
      borderTopLeftRadius: 'none',
      borderBottomLeftRadius: 'none',
      borderColor: 'transparent',
      bg: 'action.pink',
      color: 'base.white',
      ' svg': {
        color: 'base.white'
      },
      '&:hover': {
        backgroundColor: 'action.pinkDark'
      },
      '&:active, &:focus': {
        backgroundColor: 'action.pinkDark'
      },
      '&:disabled': {
        color: 'base.white',
        backgroundColor: 'action.pinkDark',
        borderColor: 'action.pinkDark',
        opacity: 0.7,
        cursor: 'not-allowed'
      }
    },
    inverse: {
      ...defaultButtonStyles,
      padding: 2,
      bg: 'base.white',
      color: 'action.pink',
      borderColor: 'transparent',
      opacity: 1,

      ' svg': {
        color: 'action.pink'
      },
      '&:hover': {
        opacity: 0.5,
        color: 'action.pinkDark'
      },
      '&:active, &:focus': {
        color: 'action.pinkDark'
      },
      '&:disabled': {
        color: 'text.secondary',
        backgroundColor: 'base.grey200',
        borderColor: 'transparent',
        opacity: 0.7,
        cursor: 'not-allowed'
      },
      '&:disabled svg': {
        color: 'text.secondary'
      }
    },
    ihollow: {
      ...defaultButtonStyles,
      padding: 1,
      bg: 'transparent',
      color: 'base.white',
      borderColor: 'transparent',
      ' svg': {
        color: 'action.pink'
      },
      '&:hover': {
        bg: 'action.pinkDark'
      },
      '&:disabled': {
        color: 'text.secondary',
        backgroundColor: 'base.grey200',
        borderColor: 'transparent',
        opacity: 0.7,
        cursor: 'not-allowed'
      },
      '&:disabled svg': {
        color: 'text.secondary'
      }
    },
    chip: {
      ...defaultButtonStyles,
      bg: 'action.pinkLight',
      px: 1,
      color: 'action.pink',
      borderColor: 'transparent',
      cursor: 'auto',
      ' svg': {
        color: 'action.pink',
        size: 1,
        ml: 1,
        cursor: 'pointer'
      },
      '&:disabled': {
        color: 'text.secondary',
        backgroundColor: 'base.grey200',
        borderColor: 'transparent',
        opacity: 0.7,
        cursor: 'not-allowed'
      },
      '&:disabled svg': {
        color: 'text.secondary'
      }
    },

    'chip-grey': {
      ...defaultButtonStyles,
      px: 1,
      color: 'text.primary',
      borderColor: 'base.grey200',
      backgroundColor: 'transparent',
      cursor: 'auto',
      ' svg': {
        color: 'action.pink',
        size: 1,
        ml: 1,
        cursor: 'pointer'
      },
      '&:active, &:focus': {
        borderColor: 'base.grey200'
      },
      '&:disabled': {
        color: 'text.secondary',
        backgroundColor: 'base.grey200',
        borderColor: 'transparent',
        opacity: 0.7,
        cursor: 'not-allowed'
      },
      '&:disabled svg': {
        color: 'text.secondary'
      }
    },
    'hollow-grey': {
      ...defaultButtonStyles,
      bg: 'transparent',
      color: 'text.primary',
      borderColor: 'base.grey200',
      ' svg': {
        color: 'action.pink'
      },
      '&:hover, &:active, &:focus': {
        color: 'text.secondary',
        backgroundColor: 'base.grey100'
      },
      '&:disabled': {
        color: 'text.secondary',
        backgroundColor: 'base.grey200',
        opacity: 0.7,
        cursor: 'not-allowed'
      },
      '&:disabled svg': {
        color: 'text.secondary'
      }
    },
    light: {
      ...defaultButtonStyles,
      bg: 'base.grey100',
      color: 'text.secondary',
      borderColor: 'transparent',
      fontSize: 1,
      px: '8px',
      py: '0px',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      cursor: 'default',
      ' svg': {
        color: 'action.pink',
        cursor: 'pointer'
      },
      '&:disabled': {
        color: 'text.secondary',
        backgroundColor: 'base.grey200',
        borderColor: 'transparent',
        opacity: 0.7,
        cursor: 'not-allowed'
      },
      '&:disabled svg': {
        color: 'text.secondary'
      }
    },
    'light-primary': {
      ...defaultButtonStyles,
      bg: 'base.grey100',
      color: 'text.primary',
      borderColor: 'transparent',
      fontSize: 2,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      '&:hover, &:active, &:focus': {
        color: 'base.white',
        backgroundColor: 'background.black'
      },
      '&:disabled': {
        color: 'text.secondary',
        backgroundColor: 'base.grey200',
        borderColor: 'transparent',
        opacity: 0.7,
        cursor: 'not-allowed'
      },
      '&:disabled svg': {
        color: 'text.secondary'
      }
    },
    'hollow-disabled': {
      ...defaultButtonStyles,
      bg: 'transparent',
      color: 'text.secondary',
      cursor: 'not-allowed',
      borderColor: 'transparent',
      ' svg': {
        color: 'action.pink'
      },
      '&:hover, &:active, &:focus': {
        color: 'text.secondary',
        bg: 'transparent',
        borderColor: 'transparent'
      }
    },
    link: {
      ...defaultButtonStyles,
      bg: 'transparent',
      color: 'action.pink',
      cursor: 'pointer',
      padding: 0,
      borderColor: 'transparent',
      ' svg': {
        color: 'action.pink'
      },
      '&:hover, &:active, &:focus': {
        color: 'action.pinkDark',
        bg: 'transparent',
        borderColor: 'transparent'
      }
    },
    'hollow-grey-border': {
      ...defaultButtonStyles,
      bg: 'transparent',
      color: 'text.secondary',
      fontWeight: 'bold',
      borderWidth: '1px',
      borderColor: 'text.secondary',
      ' svg': {
        color: 'text.secondary'
      },
      '&:hover': {
        backgroundColor: 'base.grey100'
      },
      '&:active, &:focus': {
        backgroundColor: 'base.grey100'
      },
      '&:disabled': {
        backgroundColor: 'base.grey200',
        opacity: 0.7,
        cursor: 'not-allowed'
      }
    }
  }
});
