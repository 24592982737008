import React from 'react';
import { Tooltip as TooltipBase, TooltipReference, useTooltipState } from 'reakit/Tooltip';
import PropTypes from 'prop-types';
import { Box } from 'modules/ui/primitives/box';
import { TooltipTrigger, TriggerBase, TooltipContent } from './shared';

const TOOLTIP_PLACEMENTS = [
  'auto-start',
  'auto',
  'auto-end',
  'top-start',
  'top',
  'top-end',
  'right-start',
  'right',
  'right-end',
  'bottom-end',
  'bottom',
  'bottom-start',
  'left-end',
  'left',
  'left-start'
];

export const Tooltip = ({ placement, title, children, trigger, indexOfTab, ...props }) => {
  const tooltip = useTooltipState({ placement, animated: 250 });
  const Trigger = trigger || TriggerBase;
  return (
    <Box display="inline-block">
      <TooltipTrigger as={TooltipReference} {...tooltip} tabIndex={indexOfTab || 0}>
        <Trigger />
      </TooltipTrigger>
      <TooltipBase {...tooltip} {...props}>
        <TooltipContent title={title}>{children}</TooltipContent>
      </TooltipBase>
    </Box>
  );
};

Tooltip.propTypes = {
  title: PropTypes.any,
  placement: PropTypes.oneOf(TOOLTIP_PLACEMENTS),
  children: PropTypes.node,
  trigger: PropTypes.any,
  tabIndex: PropTypes.any,
  indexOfTab: PropTypes.any
};

Tooltip.defaultProps = {
  placement: 'auto-start',
  trigger: undefined
};
