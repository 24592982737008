import React, { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useLocalize } from 'modules/core/language';
import { INFO_EMAIL } from 'modules/shared/constants';
import { Box, Flex, Text } from 'modules/ui/primitives';
import { Heading } from 'modules/ui/primitives/heading';
import { Tooltip } from 'modules/ui/components/tooltip';
import { Select } from 'modules/ui/primitives/select';
import { useSubmitLanguage } from 'modules/profile/api';
import { useToasts, toastPosition } from 'modules/shared/components/toast';

export const LanguageSelectSection = () => {
  const { langState, setLanguage } = useLocalize();
  const { t } = useTranslation();
  const [submitLanguage, { isSubmitting, error }] = useSubmitLanguage();
  const { showError, hideAllToasts } = useToasts();
  useEffect(() => {
    if (error) {
      showError(t('auth.errorDefault', 'Oops, something went wrong. Please, try again.'), {
        position: toastPosition.center
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    return () => {
      hideAllToasts();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSwitch = async e => {
    const val = e.target.value;
    await setLanguage(val);
    submitLanguage(val);
  };

  const email = INFO_EMAIL;

  return (
    <Box as="section" mb="6">
      <Heading variant="h3" mb="4">
        {t('profile.englishSpanish', 'English/Spanish')}
      </Heading>
      <Text mb="2" variant="caption">
        {t(
          'profile.selectLanguage',
          'Supermajority Home is offered in English and Spanish. Select your preferred language to update the language used across the Supermajority Home website and any related communications. '
        )}
      </Text>
      <Flex flexDirection={['column', 'row']} alignItems="baseline">
        <Flex width={['100%', 2 / 3]} alignItems="baseline" order={[2, 'initial']}>
          <Text mr="2" variant="caption">
            {t('profile.otherLanguages', 'What about other languages?')}
          </Text>
          <Tooltip
            id="tooltip-content-for-other"
            placement="top"
            title={
              <Trans i18nKey="profile.otherLanguagesTooltip" email={email}>
                We are looking into expanding our language capabilities. Email {{ email }} to
                provide feedback, request support, and suggest languages for future versions of the
                site.
              </Trans>
            }
          />
        </Flex>
        <Flex width={['100%', 1 / 2]} order={[1, 'initial']}>
          <Select
            value={langState.currentLangKey}
            name="language-choose"
            width="100%"
            disabled={isSubmitting}
            onChange={handleSwitch}
          >
            {langState.availableLanguages.map(item => (
              <option key={item.key} value={item.key}>
                {item.value}
              </option>
            ))}
          </Select>
        </Flex>
      </Flex>
    </Box>
  );
};
