import styled from 'styled-components';
import PropTypes from 'prop-types';
import { compose, variant, position, space, layout } from 'styled-system';

const imageVariants = variant({
  variants: {
    default: {},
    avatar: {
      width: 3,
      height: 3,
      borderRadius: '100%'
    },
    rounded: { borderRadius: '50%' }
  }
});

export const Image = styled.img(
  {
    maxWidth: '100%',
    height: 'auto'
  },
  compose(space, layout, position),
  imageVariants
);

Image.defaultProps = {
  variant: 'default'
};

Image.propTypes = {
  variant: PropTypes.oneOf(['default', 'avatar', 'rounded'])
};
