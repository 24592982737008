import styled from 'styled-components';
import { DisclosureContent } from 'reakit';

export const ToastStyled = styled(DisclosureContent)`
  display: flex;
  justify-content: space-between;
  background: ${props => props.theme.colors.base.white};
  box-shadow: 0px 5px 20px rgba(74, 82, 89, 0.2);
  border-radius: 10px;
  padding: 16px;
  margin-top: 0.5rem;
  opacity: 0;
  transition: all ${props => props.animated}ms ease-in-out;

  &[data-enter] {
    opacity: 1;
    transform: translateY(10px);
  }

  &[data-leave] {
    opacity: 0;
    transform: translateY(0px);
  }
`;
