import React from 'react';
import styled from 'styled-components';
import { Spinner } from 'modules/ui/primitives/spinner';

const SpinnerContainer = styled.div`
  margin-top: 180px;
  text-align: center;
  width: 100%;
`;

export const LoadingBox = () => (
  <SpinnerContainer>
    <Spinner color="action.pink" size={4} mb={8} />
  </SpinnerContainer>
);
