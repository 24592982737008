import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Container } from 'modules/ui/primitives';
import { Grid } from 'modules/ui/primitives/grid';
import { ProfileProvider } from 'modules/profile';
import { Navbar } from 'modules/shared/components';
import { Sidebar } from 'modules/shared/components/sidebar';
import { AppFooter } from 'modules/shared/components/footer';
// import { MobileDropdown } from 'modules/shared/components/dropdown';

export const withProfileLayout = props => WrappedComponent => rest => {
  const { t } = useTranslation();

  const ITEMS = [
    {
      to: '/profile',
      label: t('auth.memberProfileLabel', 'Profile Setup')
    },
    {
      to: '/profile/invite-a-friend',
      label: t('profile.inviteFriend.inviteFriend', 'inviteFriend')
    },
    {
      to: '/profile/setup',
      label: t('auth.profileSetup', 'Your Story')
    },
    {
      to: '/signout',
      label: t('auth.logout', 'Log Out')
    }
  ];

  const { showFooter } = props;

  return (
    <ProfileProvider>
      <Box bg={['base.white', 'base.grey100']} width="100%" height="auto" minHeight="100vh">
        <Navbar boxShadow="nav" mb="1px" location="account" />
        {/* <MobileDropdown items={ITEMS} /> */}
        <Grid
          as={Container}
          width="100%"
          height="100%"
          gridTemplateColumns={['1fr', '1fr 3fr']}
          gridColumnGap={[0, 2]}
          py={[1, 6]}
        >
          <Sidebar items={ITEMS} />
          <Box as="main">
            <WrappedComponent {...rest} />
          </Box>
        </Grid>
        {showFooter && <AppFooter />}
      </Box>
    </ProfileProvider>
  );
};

withProfileLayout.propTypes = {
  showFooter: PropTypes.bool
};

withProfileLayout.defaultProps = {
  showFooter: false
};
